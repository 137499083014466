import React, { useState } from 'react'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'

import { AuthType } from 'constants/constants'
import { protectedRoutes } from 'constants/routes'

import { Box, CssBaseline } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { Header, Sidebar } from 'components/parts'
import {
  AuthSetting,
  CompanyDetail,
  CompanyList,
  Dashboard,
  Faq,
  MasterItem, 
  ShipExpectedCenter,
  ShipExpectedItem,
  StockAdjust,
  StockCenter,
  Top,
  UploadMaster,
  UploadRecord,
  UserDetail,
  UserList,
  MasterCalendar,
  MasterCenter,
  MasterStockCenterItem,
  MasterSupplier,
} from 'pages'
import { DrawerHeader, Main } from 'style'



function AfterLogin() {
  const { loginUserInfo, user } = useAuthContext()

  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const userId = query.get('user_id')

  const [menuOpen, setMenuOpen] = useState(true)
  const handleDrawerOpen = () => {
    setMenuOpen(true)
  }
  const handleDrawerClose = () => {
    setMenuOpen(false)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header isLogin menuOpen={menuOpen} handleDrawerOpen={handleDrawerOpen} />
      <Sidebar menuOpen={menuOpen} handleDrawerClose={handleDrawerClose} />
      <Main open={menuOpen}>
        <DrawerHeader />
        <Routes>
          <Route path={protectedRoutes.Top.path} element={<Top />} />
          <Route path={protectedRoutes.Dashboard.path} element={<Dashboard />} />
          <Route
            path={protectedRoutes.UploadRecord.path}
            element={
              loginUserInfo?.authType === AuthType.ReadOnly ? (
                <Navigate to={protectedRoutes.Top.path} replace />
              ) : (
                <UploadRecord />
              )
            }
          />
          <Route path={protectedRoutes.StockCenter.path} element={<StockCenter />} />
          <Route path={protectedRoutes.StockAdjust.path} element={<StockAdjust />} />
          <Route path={protectedRoutes.ShipExpectedCenter.path} element={<ShipExpectedCenter />} />
          <Route path={protectedRoutes.ShipExpectedItem.path} element={<ShipExpectedItem />} />
          <Route
            path={protectedRoutes.UploadMaster.path}
            element={
              loginUserInfo?.authType === AuthType.ReadOnly ? (
                <Navigate to={protectedRoutes.Top.path} replace />
              ) : (
                <UploadMaster />
              )
            }
          />
          <Route path={protectedRoutes.Faq.path} element={<Faq />} />
          <Route path={protectedRoutes.AuthSetting.path} element={<AuthSetting />} />
          <Route
            path={protectedRoutes.UserList.path}
            element={
              loginUserInfo?.authType === AuthType.Admin || userId === loginUserInfo!.userId ? (
                <UserList />
              ) : (
                <Navigate to={protectedRoutes.Top.path} replace />
              )
            }
          />
          <Route
            path={protectedRoutes.UserDetail.path}
            element={
              loginUserInfo?.authType === AuthType.Admin || userId === loginUserInfo!.userId ? (
                <UserDetail />
              ) : (
                <Navigate to={protectedRoutes.Top.path} replace />
              )
            }
          />
          <Route
            path={protectedRoutes.CompanyList.path}
            element={user?.tenantId === null ? <CompanyList /> : <Navigate to={protectedRoutes.Top.path} replace />}
          />
          <Route
            path={protectedRoutes.CompanyDetail.path}
            element={user?.tenantId === null ? <CompanyDetail /> : <Navigate to={protectedRoutes.Top.path} replace />}
          />
          <Route path={protectedRoutes.MasterItem.path} element={<MasterItem />} />
          <Route
            path={protectedRoutes.CompanyDetail.path}
            element={user?.tenantId === null ? <CompanyDetail /> : <Navigate to={protectedRoutes.Top.path} replace />}
          />
          <Route path={protectedRoutes.MasterCalendar.path} element={<MasterCalendar />} />
          <Route path={protectedRoutes.MasterCenter.path} element={<MasterCenter />} />
          <Route path={protectedRoutes.MasterSupplier.path} element={<MasterSupplier />} />
          <Route path={protectedRoutes.MasterStockCenterItem.path} element={<MasterStockCenterItem />} />
        </Routes>

        <Outlet />
      </Main>
    </Box>
  )
}

export default AfterLogin
