import { useCallback, useEffect, useState, type default as React } from 'react';

import { Box, Typography } from '@mui/material';
import { useAuthContext } from 'AuthProvider';
import { MasterSearchFrom } from 'components/commonparts'
import { DataList } from 'components/parts/MasterCenter';
import { DownloadGrid } from 'components/parts/MasterCenter/DataList/DownloadGrid';
import { useCenter } from 'hooks/useCenter';
import _ from 'lodash';
import { useSearchParamStore } from 'store/searchParamStore';
import { initDataListStatus, type DataListStatus } from 'types/types';
import { getLocalSearchParam } from 'utils/getLocalSearchParam';
import { sortToRequestParam, sortToSortModel } from 'utils/sortToRequestParam';

import type { GetMstCentersCenterGetRequest, MstCenter, ResponseResult } from 'api-client';

function MasterCenter() {
  const { loginUserInfo } = useAuthContext()
  const trCompanyId = loginUserInfo?.companyId;
  const setSearchParamState = useSearchParamStore((state) => state.setSearchParamState)
  const searchParamState = useSearchParamStore((state) => state.searchParamState)

  const localSearchParam = getLocalSearchParam(searchParamState, loginUserInfo?.tenantId, loginUserInfo?.companyId)

  const localOrgSupplierId = localSearchParam?.item.orgSupplierId || ''

  // 自社のcompanyIdのみ検索条件をローカルストレージから復元
  const initSearchParam: GetMstCentersCenterGetRequest = {
    companyId: trCompanyId ?? loginUserInfo!.companyId,
  }

  const [hasGridEdited, setHasGridEdited] = useState<boolean>(false)
  const [downloadGridRows, setDownloadGridRows] = useState<MstCenter[]>([])
  const [downloadMode, setDownloadMode] = useState<string>('csv')

  const [resultMessage, setResultMessage] = useState<ResponseResult | undefined>(undefined)
  const [gridMessage, setGridMessage] = useState<ResponseResult | undefined>(undefined)

  const { result, isLoading, centerSearchResult, centerSearch ,fetchCenterSearch } = useCenter()
  const [searchParam, setSearchParam] = useState<GetMstCentersCenterGetRequest>(initSearchParam)
  const [listStatus, setListStatus] = useState<DataListStatus>(initDataListStatus)
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const [onReset, setOnReset] = useState<boolean>(false)
  const [isRegister, setRegister] = useState<boolean>(false)
  const [uploadData, setUploadData] = useState<any[]>([])
  const [searchTrigger, setSearchTrigger] = useState<boolean>(false);
  const [discardChangeDialogOpen, setDiscardChangeDialogOpen] = useState<boolean>(false)
  
  useEffect(() => {
    setResultMessage(centerSearchResult)
  }, [centerSearchResult])
  useEffect(() => {
    setResultMessage(result)
  }, [result])
  useEffect(() => {
    setResultMessage(gridMessage)
  }, [gridMessage])

  const doSearch = useCallback(async (requestParam: GetMstCentersCenterGetRequest) => {
    if (hasGridEdited) {
      setDiscardChangeDialogOpen(true)
      return
    }
    setSearchTrigger((prev) => !prev);
    try {
      fetchCenterSearch({
          ...requestParam,
        });
        // fetchSupplier({
        //   companyId: requestParam.companyId, 
        // });
        if (requestParam.companyId === loginUserInfo!.companyId && loginUserInfo?.tenantId && loginUserInfo?.companyId) {
          setSearchParamState<GetMstCentersCenterGetRequest>(
            loginUserInfo?.tenantId,
            loginUserInfo?.companyId,
            'Center',
            {
              ...requestParam,
              sort: sortToRequestParam(listStatus.sortModel),
            }
          );
        }
  
        setSearchParam(requestParam);
        setListStatus({
          ...listStatus,
          paginationModel: { page: 0, pageSize: 600 },
        });
        setOnReset(true); 
        setHasGridEdited(false)
      } catch (error) {
        console.error("Error fetching data:", error);
      } 
  }, [centerSearch]);

  const handleRegister = () => {
    if (!isRegister) { 
      setRegister(true);
    }
  }

  const handleUploadData = async (headers: any[], data: any[]) => {
    const mappedData = data.map((row) => {
      const obj: any = {};
      headers.forEach((header, index) => {
        obj[header] = row[index];
        // let value = row[index];
        
        // if (
        //   header === 'org_center_id' ||
        //   header === 'center_id' || 
        //   header === 'center_name' || 
        //   header === 'center_address' || 
        //   header === 'center_group_name' || 
        //   header === 'center_gln' 
          
        // ) {
        //   value = value?String(value).trim(): null ;
        // } 

        // obj[header] = value;
      });
      return obj;
    });
    setUploadData(mappedData); 
  };

  const onReload = () => {
    fetchCenterSearch({
      ...searchParam,
    })
    setHasGridEdited(false)
    setOnReset(true)
  }

  useEffect(() => {

    const firstTimeRender = async () => { 
      if (isFirstRender) {
  
        const itemsPromise = fetchCenterSearch({
          ...searchParam,
        });
  
        await Promise.all([itemsPromise]);
        setIsFirstRender(false)
  
      }
    }
    firstTimeRender()
  }, [])

  useEffect(() => {
    if (localOrgSupplierId) {
      fetchCenterSearch({
        ...searchParam,
      })

      setListStatus({
        ...listStatus,
      })
    }
  }, [])

  const generateDownloadData = (): MstCenter[] => {
    if (!centerSearch?.list || centerSearch.list.length === 0) {
      return [{} as MstCenter]
    }
    const downloadData: MstCenter[] = [...centerSearch.list]
    return downloadData
  }


  const handleFileDownload = () => {
    setDownloadGridRows(generateDownloadData())
  }

  return (
    <Box>
      <Typography component="div" variant="largeBold">
        自社センター管理
      </Typography>
      <MasterSearchFrom
          result={resultMessage}
          onDoUpdate={handleRegister}
          onReload={onReload}
          onImportData={handleUploadData}
          onFileDownLoad={handleFileDownload}
          discardChangeDialogOpen={discardChangeDialogOpen}
          setDownloadMode={setDownloadMode}
          hasDataEdited={hasGridEdited}
          importRequiredFields={["org_center_id"]}
          headerJp={["自社センターID"]}
          lastUpdateDatetime={null}
        />
      <DownloadGrid downloadData={downloadGridRows} downloadMode={downloadMode} />
      <DataList
          list={centerSearch}
          onReload={onReload}
          onReset={onReset}
          isLoading={isLoading}
          searchParam={initSearchParam}
          onSearch={doSearch}
          setOnReset={setOnReset}
          onTriggerAction={isRegister}
          onUploadData={uploadData}
          setOnTriggerAction={setRegister}
          setHasGridEdited={setHasGridEdited}
          setResultMessage={setGridMessage}
        />

    </Box>
          
  )
}

export default MasterCenter;
