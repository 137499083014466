import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { AgGridReact } from 'ag-grid-react'
import { format } from 'date-fns'
import * as agGridUtil from 'utils/agGridUtil'

import type { ColDef, ColGroupDef } from 'ag-grid-community'
import type { MstSupplier } from 'api-client'

interface DownloadGridProps {
  downloadData: MstSupplier[]
  downloadMode: string
}

export function DownloadGrid({ downloadData, downloadMode }: DownloadGridProps) {
  const fileName = "仕入先マスタ"
  const columnDefs = useMemo(
    (): (ColDef | ColGroupDef)[] => [
        { headerName: '仕入先企業ID', children: [{ headerName: 'org_supplier_id', field: 'orgSupplierId', cellClass: 'stringType' }] },
        { headerName: '仕入先プロダクト統一企業ID', children: [{ headerName: 'supplier_company_id', field: 'supplierCompanyId', cellClass: 'stringType' }] },
        { headerName: '仕入先企業名', children: [{ headerName: 'supplier_name', field: 'supplierName', cellClass: 'stringType' }] },
        { headerName: '会社法人等番号（登記所）', children: [{ headerName: 'supplier_hojin_no', field: 'supplierHojinNo', cellClass: 'stringType' }] },
        { headerName: '本社住所', children: [{ headerName: 'supplier_address', field: 'supplierAddress', cellClass: 'stringType' }] },
        { headerName: 'GS1事業者コード', children: [{ headerName: 'supplier_gln', field: 'supplierGln', cellClass: 'stringType' }] },
        { headerName: '1回あたり最低発注数', children: [{ headerName: 'min_order_quantity_once', field: 'minOrderQuantityOnce', cellClass: 'numberType' }] },
        { headerName: '発注リードタイム', children: [{ headerName: 'order_leadtime', field: 'orderLeadtime', cellClass: 'numberType' }] },
        { headerName: '締め時間', children: [{ headerName: 'order_limit_time', field: 'orderLimitTime', cellClass: 'stringType' }] },
        { headerName: '出荷可能フラグ（月曜日）', children: [{ headerName: 'enable_shipday_mon', field: 'enableShipdayMon', cellClass: 'booleanType' }] },
        { headerName: '出荷可能フラグ（火曜日）', children: [{ headerName: 'enable_shipday_tue', field: 'enableShipdayTue', cellClass: 'booleanType' }] },
        { headerName: '出荷可能フラグ（水曜日）', children: [{ headerName: 'enable_shipday_wed', field: 'enableShipdayWed', cellClass: 'booleanType' }] },
        { headerName: '出荷可能フラグ（木曜日）', children: [{ headerName: 'enable_shipday_thu', field: 'enableShipdayThu', cellClass: 'booleanType' }] },
        { headerName: '出荷可能フラグ（金曜日）', children: [{ headerName: 'enable_shipday_fri', field: 'enableShipdayFri', cellClass: 'booleanType' }] },
        { headerName: '出荷可能フラグ（土曜日）', children: [{ headerName: 'enable_shipday_sat', field: 'enableShipdaySat', cellClass: 'booleanType' }] },
        { headerName: '出荷可能フラグ（日曜日）', children: [{ headerName: 'enable_shipday_sun', field: 'enableShipdaySun', cellClass: 'booleanType' }] },
        { headerName: '発注業務可能フラグ（月曜日）', children: [{ headerName: 'enable_orderday_mon', field: 'enableOrderdayMon', cellClass: 'booleanType' }] },
        { headerName: '発注業務可能フラグ（火曜日）', children: [{ headerName: 'enable_orderday_tue', field: 'enableOrderdayTue', cellClass: 'booleanType' }] },
        { headerName: '発注業務可能フラグ（水曜日）', children: [{ headerName: 'enable_orderday_wed', field: 'enableOrderdayWed', cellClass: 'booleanType' }] },
        { headerName: '発注業務可能フラグ（木曜日）', children: [{ headerName: 'enable_orderday_thu', field: 'enableOrderdayThu', cellClass: 'booleanType' }] },
        { headerName: '発注業務可能フラグ（金曜日）', children: [{ headerName: 'enable_orderday_fri', field: 'enableOrderdayFri', cellClass: 'booleanType' }] },
        { headerName: '発注業務可能フラグ（土曜日）', children: [{ headerName: 'enable_orderday_sat', field: 'enableOrderdaySat', cellClass: 'booleanType' }] },
        { headerName: '発注業務可能フラグ（日曜日）', children: [{ headerName: 'enable_orderday_sun', field: 'enableOrderdaySun', cellClass: 'booleanType' }] },
        { headerName: '削除フラグ', children: [{ headerName: 'delete_flg', field: 'deleteFlg', cellClass: 'booleanType' }] },
    ],
    []
  )
  const [rowData, setRowData] = useState<MstSupplier[]>([])
  const gridRef = useRef<AgGridReact>(null)

  const onModelUpdated = () => {
    const gridApi = gridRef.current?.api
    if (gridApi && rowData.length > 0) {
      const dlfileName = `${fileName}_${format(new Date(), 'yyyyMMddHHmmss')}`
      if (downloadMode === 'csv') {
        gridApi.exportDataAsCsv({ fileName:dlfileName })
      } else if (downloadMode === 'xlsx') {
        gridApi.exportDataAsExcel({ fileName:dlfileName, sheetName: 'データ' })
      }
    }
    
  }

  useEffect(() => {
    setRowData(downloadData)
  }, [downloadData])

  return (
    <div>
      <AgGridReact
        ref={gridRef}
        columnDefs={columnDefs}
        rowData={rowData}
        onModelUpdated={onModelUpdated}
        excelStyles={agGridUtil.excelStyles}
      />
    </div>
  )
}
