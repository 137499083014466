import { isValid, parse } from 'date-fns';

export const convertFromDate = (date: Date | null | undefined) => {
  if (!date) return ''

  const lacaleDate = new Date(date).toLocaleDateString('en-CA')
  return lacaleDate === 'Invalid Date' ? '' : lacaleDate.replace(/-/g, '/')
}

export const convertFromTime = (date: Date | null | undefined) => {
  if (!date) return ''

  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false, 
  };

  const lacaleTime = new Date(date).toLocaleTimeString([], options)
  return lacaleTime === 'Invalid Date' ? '' : lacaleTime
}

export const convertFromDateTime = (date: Date | null | undefined) =>
  `${convertFromDate(date)} ${convertFromTime(date)}`

// 日付で指定されたフォーマット通りの日付の文字列を返す
export const dateToStr24HPad0DayOfWeek = (
  date: Date | null | undefined,
  format?: string,
  isMonthDateZeroPadding = true
) => {
  if (!date) return ''

  const weekday = ['日', '月', '火', '水', '木', '金', '土']
  let trFormat = format || 'YYYY/MM/DD(WW) hh:mm:ss'

  const newDate = new Date(date)

  trFormat = trFormat.replace(/YYYY/g, `${newDate.getFullYear()}`)
  trFormat = trFormat.replace(/MM/g, `${isMonthDateZeroPadding ? '0' : ''}${newDate.getMonth() + 1}`.slice(-2))
  trFormat = trFormat.replace(/DD/g, `${isMonthDateZeroPadding ? '0' : ''}${newDate.getDate()}`.slice(-2))
  trFormat = trFormat.replace(/WW/g, weekday[newDate.getDay()])
  trFormat = trFormat.replace(/hh/g, `0${newDate.getHours()}`.slice(-2))
  trFormat = trFormat.replace(/mm/g, `0${newDate.getMinutes()}`.slice(-2))
  trFormat = trFormat.replace(/ss/g, `0${newDate.getSeconds()}`.slice(-2))
  return trFormat
}

export function excelSerialToDate(serial: number): Date | null {
  if (typeof serial !== 'number') {
    return null;
  }

  const epoch = new Date(Date.UTC(1899, 11, 30));

  const utcDate = new Date(epoch.getTime() + serial * 86400000);

  const timeZoneOffset = utcDate.getTimezoneOffset() * 60000; 
  const localDate = new Date(utcDate.getTime() - timeZoneOffset);

  if (Number.isNaN(localDate.getTime())) {
    return null;
  }
  return localDate;
}

export function convertExcelTimeToHHMM(excelTime: number): string | null {
  if (typeof excelTime === 'number') {
    const hours = Math.floor(excelTime * 24); 
    const minutes = Math.round((excelTime * 24 * 60) % 60);

    if (hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60) {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    }
  }

  return null;
}

export function normalizeDate(value: any){
  if (!value) return null;

  if (!/^[\d/-]+$/.test(value)) {
    return null; 
  }

  const formats = ['dd/MM/yyyy', 'yyyy-MM-dd', 'yyyy/MM/dd'];

  const matchedFormat = formats.find((dateFormat) => {
    const tempDate = parse(value, dateFormat, new Date());
    return isValid(tempDate);
  });

  return matchedFormat ? parse(value, matchedFormat, new Date()) : null;
}

export function valueToDateStr(value: any): string | null {
  // 入力値がdateに変換できる場合、yyyy-mm-ddの文字列にして返す
  const date = value instanceof Date ? value : normalizeDate(value);
  if (!date || Number.isNaN(date.getTime())) {
    return null; // 無効な日付の場合は null を返す
  }  
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2); // 月は0から始まるため+1, 2桁にするために0埋め
  const day = `0${date.getDate() + 1}`.slice(-2); // 2桁にするために0埋め
  return `${year}-${month}-${day}`;
}

export function isEqualDate(value1: any, value2: any): boolean {
  // 2つの日付が等しいかを確認する
  // ロケール設定を無視して、日付のみを比較する

  // 入力値が Date オブジェクトに変換できるかを確認
  const date1 = value1 instanceof Date ? value1 : normalizeDate(value1);
  const date2 = value2 instanceof Date ? value2 : normalizeDate(value2);
  // 両方NULLならtrue
  if (!date1 && !date2) return true;
  if (!date1 || !date2) return false;

  // いずれかの入力値が無効な日付の場合、false を返す
  if (Number.isNaN(date1.getTime()) || Number.isNaN(date2.getTime())) {
    return false;
  }

  // 年, 月, 日が等しいかを確認
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}