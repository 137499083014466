import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { AgGridReact } from 'ag-grid-react'
import { format } from 'date-fns'
import * as agGridUtil from 'utils/agGridUtil'

import type { ColDef, ColGroupDef } from 'ag-grid-community'
import type { MstItem } from 'api-client'

interface DownloadGridProps {
  downloadData: MstItem[]
  downloadMode: string
}

export function DownloadGrid({ downloadData, downloadMode }: DownloadGridProps) {
  const fileName = "商品マスタ"
  const columnDefs = useMemo(
    (): (ColDef | ColGroupDef)[] => [
        { headerName: 'プロダクト統一企業ID', children: [{ headerName: 'company_id', field: 'companyId' }] },
        { headerName: 'アイテムコード', children: [{ headerName: 'item_code', field: 'itemCode', cellClass: 'stringType' }] },
        { headerName: '社内商品コード', children: [{ headerName: 'org_item_code', field: 'orgItemCode', cellClass: 'stringType' }] },
        { headerName: '商品名称', children: [{ headerName: 'item_name', field: 'itemName', cellClass: 'stringType' }] },
        { headerName: '発売日', children: [{ headerName: 'sales_startdate', field: 'salesStartdate', valueFormatter: agGridUtil.dateFormatter}] },
        { headerName: '終了日', children: [{ headerName: 'sales_enddate', field: 'salesEnddate', valueFormatter: agGridUtil.dateFormatter }] },
        { headerName: 'ケースサイズ 長手（mm）', children: [{ headerName: 'longside', field: 'longside', cellClass: 'decimalType' }] },
        { headerName: 'ケースサイズ 短手（mm）', children: [{ headerName: 'shortside', field: 'shortside', cellClass: 'decimalType' }] },
        { headerName: 'ケースサイズ 高さ（mm）', children: [{ headerName: 'height', field: 'height', cellClass: 'decimalType' }] },
        { headerName: 'ケースサイズ 重量（g）', children: [{ headerName: 'weight', field: 'weight', cellClass: 'decimalType' }] },
        { headerName: '規格', children: [{ headerName: 'spec', field: 'spec', cellClass: 'stringType' }] },
        { headerName: 'パレット最大積付数', children: [{ headerName: 'max_pallet_quantity', field: 'maxPalletQuantity', cellClass: 'numberType' }] },
        { headerName: 'パレット積載数（面）', children: [{ headerName: 'pallet_quantity_face', field: 'palletQuantityFace', cellClass: 'numberType' }] },
        { headerName: 'パレット積載数（段）', children: [{ headerName: 'pallet_quantity_tier', field: 'palletQuantityTier', cellClass: 'numberType' }] },
        { headerName: 'JANコード（バラ）', children: [{ headerName: 'jan', field: 'jan', cellClass: 'stringType' }] },
        { headerName: 'JANコード（ケース）', children: [{ headerName: 'jan_case', field: 'janCase', cellClass: 'stringType' }] },
        { headerName: 'GTINコード（バラ）', children: [{ headerName: 'gtin_13', field: 'gtin13', cellClass: 'stringType' }] },
        { headerName: 'GTINコード（ケース）', children: [{ headerName: 'gtin_14', field: 'gtin14', cellClass: 'stringType' }] },
        { headerName: 'ITFコード', children: [{ headerName: 'itf', field: 'itf', cellClass: 'stringType' }] },
        { headerName: 'SDPコード', children: [{ headerName: 'sdp', field: 'sdp', cellClass: 'stringType' }] },
        { headerName: '後継社内商品コード', children: [{ headerName: 'replace_org_item_code', field: 'replaceOrgItemCode', cellClass: 'stringType' }] },
        { headerName: 'メーカーGS1事業者コード', children: [{ headerName: 'maker_gln', field: 'makerGln', cellClass: 'stringType' }] },
        { headerName: 'メーカー名', children: [{ headerName: 'maker_name', field: 'makerName', cellClass: 'stringType' }] },
        { headerName: '仕入先ID', children: [{ headerName: 'org_supplier_id', field: 'orgSupplierId', cellClass: 'stringType' }] },
        { headerName: '仕入先名', children: [{ headerName: 'supplier_name', field: 'supplierName', cellClass: 'stringType' }] },
        { headerName: '発注単位', children: [{ headerName: 'order_unit_type', field: 'orderUnitType', cellClass: 'stringType' }] },
        { headerName: 'ケースロット数', children: [{ headerName: 'case_lot', field: 'caseLot', cellClass: 'numberType' }] },
        { headerName: '最低発注量', children: [{ headerName: 'min_order_quantity', field: 'minOrderQuantity', cellClass: 'numberType' }] },
        { headerName: '削除フラグ', children: [{ headerName: 'delete_flg', field: 'deleteFlg', cellClass: 'booleanType' }] },
    ],
    []
  )

  const [rowData, setRowData] = useState<MstItem[]>([])
  const gridRef = useRef<AgGridReact>(null)

  const onModelUpdated = () => {
    const gridApi = gridRef.current?.api
    if (gridApi && rowData.length > 0) {
      // onApply(gridApi)

      const dlfileName = `${fileName}_${format(new Date(), 'yyyyMMddHHmmss')}`
      if (downloadMode === 'csv') {
        gridApi.exportDataAsCsv({ fileName:dlfileName })
      } else if (downloadMode === 'xlsx') {
        gridApi.exportDataAsExcel({ fileName:dlfileName, sheetName: 'データ' })
      }

    }
  }

  useEffect(() => {
    setRowData(downloadData)
  }, [downloadData])

  return (
    <div>
      <AgGridReact
        ref={gridRef}
        columnDefs={columnDefs}
        rowData={rowData}
        onModelUpdated={onModelUpdated}
        excelStyles={agGridUtil.excelStyles}
      />
    </div>
  )
}
