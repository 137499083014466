import React from 'react';

import { type ValueSetterParams, type ICellRendererParams } from 'ag-grid-community';

export function TimeCellRenderer( params: ICellRendererParams){
    const {value} = params

    if(value) {
        return value
    }

    return ( 
    <span style = {{ color: '#ccc'}}>
        hh:mm
    </span>
    )
}


export function isValidTime(hours: string, minutes: string): boolean {
    const h = parseInt(hours, 10);
    const m = parseInt(minutes, 10);
    return h >= 0 && h < 24 && m >= 0 && m < 60;
}
