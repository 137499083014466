/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MstCalendarUpdateBase
 */
export interface MstCalendarUpdateBase {
    /**
     * 
     * @type {boolean}
     * @memberof MstCalendarUpdateBase
     */
    deleteFlg?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCalendarUpdateBase
     */
    insertDatetime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCalendarUpdateBase
     */
    insertUser?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCalendarUpdateBase
     */
    updateDatetime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCalendarUpdateBase
     */
    updateUser?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCalendarUpdateBase
     */
    deleteDatetime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCalendarUpdateBase
     */
    deleteUser?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MstCalendarUpdateBase
     */
    companyId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstCalendarUpdateBase
     */
    orgCenterId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCalendarUpdateBase
     */
    calendarDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCalendarUpdateBase
     */
    enableShip?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCalendarUpdateBase
     */
    enableArrival?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCalendarUpdateBase
     */
    enableOrder?: boolean | null;
}

/**
 * Check if a given object implements the MstCalendarUpdateBase interface.
 */
export function instanceOfMstCalendarUpdateBase(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MstCalendarUpdateBaseFromJSON(json: any): MstCalendarUpdateBase {
    return MstCalendarUpdateBaseFromJSONTyped(json, false);
}

export function MstCalendarUpdateBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MstCalendarUpdateBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deleteFlg': !exists(json, 'delete_flg') ? undefined : json['delete_flg'],
        'insertDatetime': !exists(json, 'insert_datetime') ? undefined : (json['insert_datetime'] === null ? null : new Date(json['insert_datetime'])),
        'insertUser': !exists(json, 'insert_user') ? undefined : json['insert_user'],
        'updateDatetime': !exists(json, 'update_datetime') ? undefined : (json['update_datetime'] === null ? null : new Date(json['update_datetime'])),
        'updateUser': !exists(json, 'update_user') ? undefined : json['update_user'],
        'deleteDatetime': !exists(json, 'delete_datetime') ? undefined : (json['delete_datetime'] === null ? null : new Date(json['delete_datetime'])),
        'deleteUser': !exists(json, 'delete_user') ? undefined : json['delete_user'],
        'companyId': !exists(json, 'company_id') ? undefined : json['company_id'],
        'orgCenterId': !exists(json, 'org_center_id') ? undefined : json['org_center_id'],
        'calendarDate': !exists(json, 'calendar_date') ? undefined : (json['calendar_date'] === null ? null : new Date(json['calendar_date'])),
        'enableShip': !exists(json, 'enable_ship') ? undefined : json['enable_ship'],
        'enableArrival': !exists(json, 'enable_arrival') ? undefined : json['enable_arrival'],
        'enableOrder': !exists(json, 'enable_order') ? undefined : json['enable_order'],
    };
}

export function MstCalendarUpdateBaseToJSON(value?: MstCalendarUpdateBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delete_flg': value.deleteFlg,
        'insert_datetime': value.insertDatetime === undefined ? undefined : (value.insertDatetime === null ? null : value.insertDatetime.toISOString()),
        'insert_user': value.insertUser,
        'update_datetime': value.updateDatetime === undefined ? undefined : (value.updateDatetime === null ? null : value.updateDatetime.toISOString()),
        'update_user': value.updateUser,
        'delete_datetime': value.deleteDatetime === undefined ? undefined : (value.deleteDatetime === null ? null : value.deleteDatetime.toISOString()),
        'delete_user': value.deleteUser,
        'company_id': value.companyId,
        'org_center_id': value.orgCenterId,
        'calendar_date': value.calendarDate === undefined ? undefined : (value.calendarDate === null ? null : value.calendarDate.toISOString()),
        'enable_ship': value.enableShip,
        'enable_arrival': value.enableArrival,
        'enable_order': value.enableOrder,
    };
}

