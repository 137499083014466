import React, { useCallback, useEffect, useMemo, useRef, useState, type Dispatch, type SetStateAction } from 'react';

import { useAuthContext } from 'AuthProvider';
import { type CellValueChangedEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react';
import AppSnackbar from 'components/commonparts/AppSnackbar/AppSnackbar';
import GridTooltip from 'components/commonparts/GridTooltip/GridTooltip';
import LoadingOverlay from 'components/commonparts/LoadingOverlay/LoadingOverlay';
import { useCenterStockItem } from 'hooks/useCenterStockItem';
import { AG_GRID_LOCALE } from 'utils/agGridLocale';
import * as agGridUtil from 'utils/agGridUtil';
import { convertDateToRequestParam } from 'utils/convertDateToRequestParam';
import { isEqualDate } from 'utils/convertFromLocalDate'
import { validateRequired } from 'utils/validation';

import type {
  ColDef,
  ColGroupDef,
  ColumnMenuTab,
  GetContextMenuItemsParams,
  IRichCellEditorParams,
  IMenuActionParams,
  MenuItemDef,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
  ValueFormatterParams,
  ValueParserParams,
} from 'ag-grid-enterprise'
import 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import type { MstCenterStockItem, CenterStockItem, GetMstCenterStockItemCenterStockItemGetRequest, MstItem, MstSupplier, Groups, MstCenter, ResponseResult, Codes } from 'api-client';
import 'utils/mst.scss'

const columnMapping: Record<string, string> = {
  "org_center_id": "orgCenterId",
  "org_item_code": "orgItemCode",
  "start_date": "startDate",
  "stock_days_setting": "stockDaysSetting",
  "safety_stock_setting": "safetyStockSetting",
  "stock_days_rank": "stockDaysRank",
  "safety_stock_rank": "safetyStockRank",
  "min_stock_quantity": "minStockQuantity",
  "stop_ship": "stopShip",
  "stop_arrival": "stopArrival",
  "org_supplier_id": "orgSupplierId",
  "order_unit_type": "orderUnitType",
  "case_lot": "caseLot",
  "min_order_quantity": "minOrderQuantity",
  "delete_flg": "deleteFlg",
};

interface DataListProps {
  list: CenterStockItem;
  items: MstItem[];
  supplier: MstSupplier[];
  systype: Groups[];
  center: MstCenter[];
  isLoading: boolean;
  onReload: () => void;
  onUploadData: any[];
  onReset: boolean;
  searchParam: GetMstCenterStockItemCenterStockItemGetRequest
  onSearch: (requestParam: GetMstCenterStockItemCenterStockItemGetRequest) => void;
  setOnReset: (value: boolean) => void;
  onTriggerAction: boolean;
  setOnTriggerAction: (value: boolean) => void,
  setHasGridEdited: (value: boolean) => void,
  setResultMessage: Dispatch<SetStateAction<ResponseResult | undefined>>
}

interface MstCenterStockItemWithId extends MstCenterStockItem, agGridUtil.RecordEditEx { }

function DataList({
  list,
  isLoading,
  onReset,
  items,
  supplier,
  center,
  systype,
  searchParam,
  onSearch,
  setOnReset,
  onReload,
  onTriggerAction,
  setOnTriggerAction,
  onUploadData,
  setHasGridEdited,
  setResultMessage,
}: DataListProps) {
  const { loginUserInfo } = useAuthContext()
  const { result, fetchCenterStockItemUpdate, centerstockitemUpdateIsLoading, centerstockitemUpdateResult, centerstockitemSearchResult } = useCenterStockItem()
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarSeverity, setSnackBarSeverity] = useState<'error' | 'warning' | 'info' | 'success'>('success')
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [editedRows, setEditedRows] = useState<MstCenterStockItemWithId[]>([]);
  const gridRef = useRef<AgGridReact<MstCenterStockItemWithId>>(null)
  const updatedRowsRef = useRef<any[]>([]);

  const localeText = useMemo<{
    [key: string]: string
  }>(() => AG_GRID_LOCALE, [])

  const autoSizeStrategy = useMemo<
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy
  >(() => ({ type: "fitCellContents" }), []);
  const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 300px)' }), [])
  const gridStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 300px)' }), [])
  const [editorDataReady, setEditorDataReady] = useState(false);
  const [row, setRow] = useState<MstCenterStockItemWithId[]>(list.list.map(item => ({ ...item, ...agGridUtil.initRecordEditEx() })))
  const pendingChanges = useRef<{ node: any; updatedRow: MstCenterStockItemWithId }[]>([]);
  const isPasting = useRef(false)
  const [supplierSelect, setSupplierSelect] = useState<(string | null)[]>([])
  const [itemSelect, setItemSelect] = useState<(string | null)[]>([])
  const [centerSelect, setCenterSelect] = useState<(string | null)[]>([])
  const [orderSysTypeCodeLists, setOrderSysTypeCodeLists] = useState<{ [key: string]: Codes[] }>({});
  const [orderSysTypeCodeSelects, setOrderSysTypeCodeSelects] = useState<{ [key: string]: (string | null)[] }>({});
  const orderUnitTypeGroups = ['stock_days_rank', 'safety_stock_rank', 'order_unit_type'];

  const tableFieldToGroup: { [key: string]: string } = {
    orderUnitType: 'order_unit_type',
    stockDaysRank: 'stock_days_rank',
    safetyStockRank: 'safety_stock_rank',
  };

  useEffect(() => {
    setResultMessage(result)
  }, [result])

  useEffect(() => {
    const allSelected = orderUnitTypeGroups.every(group => orderSysTypeCodeSelects[group]?.length > 0);
    if (supplierSelect.length > 0 && itemSelect.length > 0 && centerSelect.length > 0 && allSelected) {
      setEditorDataReady(true);
    }
  }, [supplierSelect, itemSelect, centerSelect, orderSysTypeCodeSelects]);

  useEffect(() => {
    const values = supplier?.map((r) => r.orgSupplierId) || []
    setSupplierSelect(values)
  }, [supplier]);
  useEffect(() => {
    const values = center?.map((r) => r.orgCenterId) || []
    setCenterSelect(values)
  }, [center]);
  useEffect(() => {
    const newLists: { [key: string]: Codes[] } = {};
    const newSelects: { [key: string]: (string | null)[] } = {};

    orderUnitTypeGroups.forEach(group => {
      const codes = systype.find(r => r.typeGroup === group)?.codes || [];
      newLists[group] = codes;
      newSelects[group] = codes.map(r => r.typeCode);
    });

    setOrderSysTypeCodeLists(newLists);
    setOrderSysTypeCodeSelects(newSelects);
  }, [systype]);
  useEffect(() => {
    // console.log("items", items)
    setEditorDataReady(false)
    const values = items?.map((r) => r.orgItemCode) || []
    setItemSelect([...values])
  }, [items]);

  useEffect(() => {
    if (onReset) {
      setEditedRows([]);
      setOnReset(false);
    }
  }, [onReset, setOnReset]);


  const isDuplicate = (
    rowD: MstCenterStockItemWithId,
    allRows: MstCenterStockItemWithId[],
    currentRowId: string | null
  ): boolean => allRows.some(
    (r) =>

      r.orgCenterId === rowD.orgCenterId
      && r.orgItemCode === rowD.orgItemCode
      && r.id !== currentRowId

  );
  const batchUpdateRef = useRef<{[key: string]: MstCenterStockItemWithId}>({});

  const onPasteStart = useCallback(() => {
    isPasting.current = true;
    pendingChanges.current = [];
    batchUpdateRef.current = {};
  }, []);

  const setMstItemCol= (pRow: MstCenterStockItemWithId, orgItemCode: string | null | undefined) => { 
    // if (pRow && orgItemCode && items.some(item => item.orgItemCode === orgItemCode)) {
    
    if (pRow) {
      const selectedItem = { ...items.find(orgId => orgId.orgItemCode === orgItemCode)};
      // console.log("setMstItemCol", pRow, orgItemCode, items, selectedItem)
      Object.assign(pRow, {
        mstItemItemName : selectedItem ? selectedItem.itemName : null,
        mstItemSalesStartdate : selectedItem ? selectedItem.salesStartdate : null,
        mstItemSalesEnddate : selectedItem ? selectedItem.salesEnddate : null,
        mstItemLongside : selectedItem ? selectedItem.longside : null,
        mstItemShortside : selectedItem ? selectedItem.shortside : null,
        mstItemHeight : selectedItem ? selectedItem.height : null,
        mstItemWeight : selectedItem ? selectedItem.weight : null,
        mstItemSpec : selectedItem ? selectedItem.spec : null,
        mstItemMaxPalletQuantity : selectedItem ? selectedItem.maxPalletQuantity : null,
        mstItemPalletQuantityFace : selectedItem ? selectedItem.palletQuantityFace : null,
        mstItemPalletQuantityTier : selectedItem ? selectedItem.palletQuantityTier : null,
        mstItemJan : selectedItem ? selectedItem.jan : null,
        mstItemJanCase : selectedItem ? selectedItem.janCase : null,
        mstItemGtin13 : selectedItem ? selectedItem.gtin13 : null,
        mstItemGtin14 : selectedItem ? selectedItem.gtin14 : null,
        mstItemItf : selectedItem ? selectedItem.itf : null,
        mstItemSdp : selectedItem ? selectedItem.sdp : null,
        mstItemReplaceOrgItemCode : selectedItem ? selectedItem.replaceOrgItemCode : null,
        mstItemMakerGln : selectedItem ? selectedItem.makerGln : null,
        mstItemMakerName : selectedItem ? selectedItem.makerName : null,
        mstItemOrgSupplierId : selectedItem ? selectedItem.supplierName : null,
        mstItemMinOrderQuantity : selectedItem ? selectedItem.minOrderQuantity : null,
        mstItemOrderUnitType : selectedItem ? selectedItem.orderUnitType : null,
        mstItemCaseLot : selectedItem ? selectedItem.caseLot : null,     
      })
    }
  }

  const colDataValidate = (pRow: MstCenterStockItemWithId, field: string | undefined, newValue: any): boolean => {
    if (!field) return false;
    let hasErrors = false
    const trRow = pRow
    const checkAndSetError = (f: string, error: string | null): boolean => {
      if (error) {
        trRow.errors[f] = [error];
        trRow.editedFields.add(f);
        hasErrors = true;
      } else if (trRow.errors && trRow.errors[f]) {
        delete trRow.errors[f]
      }
      return hasErrors
    };

    checkAndSetError(field, "");
    switch (field) {
      case "orgItemCode":
      case "orgCenterId":
        checkAndSetError(field, validateRequired(newValue));

        if (!hasErrors) {
          const isDuplicateError = isDuplicate(trRow, row, trRow.id);
          if (isDuplicateError) {
            checkAndSetError("orgItemCode", '入力値はすでに登録されています');
            checkAndSetError("orgCenterId", '入力値はすでに登録されています');
          } else {
            checkAndSetError("orgItemCode", null);
            checkAndSetError("orgCenterId", null);
          }
        }
        break;
      default:
        return false;
    }
    return hasErrors

  };

  // 行に含まれる項目に入力チェックを実施
  const rowDataValidate = (pRow: MstCenterStockItemWithId): boolean => {
    // console.debug("***rowDataValidate ", pRow)
    if (!pRow) return false;
    const trRow = pRow
    let hasErrors = false
    trRow.errors = {};
    Object.keys(columnMapping).forEach((key) => {
      const fieldValue = trRow[columnMapping[key] as keyof typeof trRow];
      colDataValidate(trRow, columnMapping[key], fieldValue)
    });

    if (trRow.errors && Object.keys(trRow.errors).length > 0) {
      // console.debug("***rowDataValidate hasErrors", trRow)
      hasErrors = true;
    }
    return hasErrors
  }

  const onCellValueChanged = useCallback((event: CellValueChangedEvent<MstCenterStockItemWithId>) => {
    setResultMessage(undefined)
    const { data, colDef, newValue, oldValue, node } = event;
    const { field } = colDef;

    const updatedRow = { ...data }

    // 入力チェック
    const haserror = colDataValidate(updatedRow, field, newValue)

    if (newValue !== oldValue) {
      updatedRow.isEdited = true;
      updatedRow.editedFields.add(field as string);
    }
    colDataValidate(updatedRow, field, newValue)

    if (field === 'orgItemCode') {
      setMstItemCol(updatedRow, newValue)
    }

    if (newValue !== oldValue) {
      updatedRow.isEdited = true;
      updatedRow.editedFields.add(field as string);
      // console.log("onCellValueChanged change")
    }
    // 値の変更時に、APIのエラーを解消
    switch (field) {
      case "orgCenterId":
      case "orgItemCode":
        colDataValidate(updatedRow, "orgItemCode", updatedRow.orgItemCode);
        break;
      default:
    }

    if (isPasting.current) {
      batchUpdateRef.current[updatedRow.id ?? ''] = updatedRow;
      let pendingRow = pendingChanges.current.find((change) => change.updatedRow.id === data.id);
      // console.log("isPasting.current", pendingRow)
      if (!pendingRow) {
        pendingRow = {
          node,
          updatedRow: { ...updatedRow },
        };
        pendingChanges.current.push(pendingRow);
        node.setData(pendingRow.updatedRow);
      } else {
        const trRow = pendingRow.updatedRow as MstCenterStockItemWithId
        trRow[field as keyof MstCenterStockItem] = newValue;
        trRow.isEdited = true;
        trRow.editedFields.add(field as string);
        colDataValidate(trRow, field, newValue)
      }

      node.setData(pendingRow.updatedRow);
    } else {

      setEditedRows((prevEditedRows) => {
        const rowIndex = prevEditedRows.findIndex((rowa) => rowa.id === updatedRow.id);
        if (rowIndex !== -1) {
          const newEditedRows = [...prevEditedRows];
          newEditedRows[rowIndex] = updatedRow;
          return newEditedRows;
        }
        return [...prevEditedRows, updatedRow];
      });

      node.setData(updatedRow);
    }

  }, [row, items]);

  const onPasteEnd = useCallback(() => {
    isPasting.current = false;
    
    const gridApi = gridRef.current?.api;
    if (gridApi) {
      Object.values(batchUpdateRef.current).forEach(updatedRow => {
        if (updatedRow.orgItemCode) {
          setMstItemCol(updatedRow, updatedRow.orgItemCode)
        }
        
        const node = gridApi.getRowNode(updatedRow.id ?? '');
        if (node) {
          node.setData(updatedRow);
        }
      });
    }

    setEditedRows(prev => [...prev, ...Object.values(batchUpdateRef.current)]);
    batchUpdateRef.current = {};
  }, [items]);

  const updateMstCenterStockItem = useCallback(async () => {
    setResultMessage(undefined)
    const baseData = editedRows as unknown as Array<MstCenterStockItemWithId>
    let hasErrors = false;

    const updatedRows = baseData.map((item) => {
      // 変更時にエラーチェックが行われているが、登録前に再度チェックを実施する
      const updatedRow = { ...item };
      hasErrors = rowDataValidate(updatedRow) || hasErrors
      const gridApi = gridRef.current?.api;
      const rowNode = gridApi?.getRowNode(item.id ?? '');
      if (rowNode) {
        rowNode.setData(updatedRow);
      }
      gridApi?.refreshCells({ force: true });
      return updatedRow
    });

    if (hasErrors) {
      setResultMessage({
        status: 'error',
        message: '入力エラーが1あります',
        systemDate: null,
        pageNo: 0,
        systemInfo: null,
      })
      setOnTriggerAction(false);
      return;
    }
    updatedRowsRef.current = updatedRows;

    const gridApi = gridRef.current?.api;
    if (gridApi) {
      gridApi.applyTransaction({ update: updatedRows });
      gridApi.redrawRows();
    }

    if (baseData.length === 0) {
      setResultMessage({
        status: 'warning',
        message: '更新対象のデータがありません',
        systemDate: null,
        pageNo: 0,
        systemInfo: null,
      })
      setOnTriggerAction(false);
      return;
    }
    const datalist = baseData.map(({ isEdited, editedFields, startDate, errors, ...rest }) => ({
      ...rest,
      startDate: startDate ? convertDateToRequestParam(new Date(startDate)) : null
    }));

    try {
      await fetchCenterStockItemUpdate({
        mstCenterStockItemUpdate: { body: datalist }
      })

      setSnackBarMessage('正常に更新されました');
      setSnackBarSeverity('success');
      setEditedRows([]);
      onReload()
    } catch (err: any) {
      if (err?.errors && err.result?.status === "error") {
        if (gridApi) {
          err.errors.forEach((errrow: any) => {

            const { rowdata } = errrow
            const orgCenterId = (rowdata as { org_center_id: string | null }).org_center_id;

            const orgItemCode = (rowdata as { org_item_code: string | null }).org_item_code;
            const editedRowIndex = editedRows.find((rowdatas) => 
              rowdatas.orgCenterId === orgCenterId && 
              rowdatas.orgItemCode === orgItemCode
            );
            if (editedRowIndex) {
              // UpdateAPIのErrorは、社内商品コードをエラー表記して、サーバーから取得したエラーを表示する
              const updatedRow = {
                ...editedRowIndex,
                errors: {
                  orgCenterId: [errrow.errorMessage],
                },
              };

              setEditedRows((prev) =>
                prev.map((editedRow) =>
                  editedRow.orgCenterId === orgCenterId ? updatedRow : editedRow
                )
              );
              const rowNode = gridApi.getRowNode(updatedRow.id || '');
              if (rowNode) {
                rowNode.setData(updatedRow);
              }
            }
          });
          gridApi.redrawRows();
        }
        setSnackBarMessage("");
        setSnackBarSeverity("error");
        setSnackBarOpen(true);
        return;
      }

    } finally {
      setSnackBarOpen(true);
      setOnTriggerAction(false)
    }
  }, [fetchCenterStockItemUpdate, onSearch, editedRows, searchParam]);

  const getCellClassRules = () => ({
    'error-cell': (params: any) => {
      const rowData = params.data as MstCenterStockItemWithId;
      const { field } = params.colDef;
      const hasError = Array.isArray(rowData.errors[field]) && rowData.errors[field]?.length > 0;
      return hasError || false;
    },
    'current-updated-cell': (params: any) => {
      const rowData = params.data as MstCenterStockItemWithId;
      const { field } = params.colDef;
      return rowData.isEdited && (!rowData.errors?.[field] || rowData.errors[field].length === 0) && rowData.editedFields?.has(field);
    },
    'editable-cell': (params: any) => {
      const { editable } = params.colDef;
      let iseditable = false
      if (typeof editable === 'function') {
        iseditable = editable(params)
      } else {
        iseditable = editable
      }
      return iseditable
    }
  });

  useEffect(() => {
    const gridApi = gridRef.current?.api;
    if (!gridApi) return;

    if (isLoading || centerstockitemUpdateIsLoading) {
      gridApi.showLoadingOverlay();
    } else {
      gridApi.hideOverlay();
    }
  }, [isLoading, centerstockitemUpdateIsLoading, gridRef.current]);

  /**
   * カラム定義
   * @returns
   */
  const updateColumnDefs = (): (ColDef | ColGroupDef)[] =>
    [
      {
        headerName: '自社センター',
        field: 'orgCenterId',
        rowDrag: true,
        editable: (params) => params.data && params.data.insertDatetime === null,
        cellClassRules: {
          ...getCellClassRules(),
          'editable-cell': (params) => params.data && params.data.insertDatetime === null,
        },
        width: 300,
        suppressPaste: false,
        cellEditor: 'agRichSelectCellEditor',
        valueFormatter: (params: ValueFormatterParams) => agGridUtil.selectValueFormatter(params.value, center, "orgCenterId", "centerName"),
        valueParser: (params: ValueParserParams) => agGridUtil.selectValueParser(params, center, "orgCenterId"),
        cellEditorParams: editorDataReady ? {
          values: [null, ...centerSelect],
          formatValue: (value: string) => agGridUtil.selectValueFormatter(value, center, "orgCenterId", "centerName"),
          searchType: "matchAny",
          allowTyping: true,
          filterList: true,
          highlightMatch: true,
        } as IRichCellEditorParams : { values: ['-'] },
      },
      {
        headerName: '社内商品コード',
        field: 'orgItemCode',
        editable: (params) => params.data && params.data.insertDatetime === null,
        cellClassRules: {
          ...getCellClassRules(),
          'editable-cell': (params) => params.data && params.data.insertDatetime === null,
        },
        width: 300,
        suppressPaste: false,
        cellEditor: 'agRichSelectCellEditor',
        valueFormatter: (params: ValueFormatterParams) => agGridUtil.selectValueFormatter(params.value, items, "orgItemCode", "itemName"),
        valueParser: (params: ValueParserParams) => agGridUtil.selectValueParser(params, items, "orgItemCode"),
        cellEditorParams: editorDataReady ? {
          values: [null, ...itemSelect],
          formatValue: (value: string) => agGridUtil.selectValueFormatter(value, items, "orgItemCode", "itemName"),
          searchType: "matchAny",
          allowTyping: true,
          filterList: true,
          highlightMatch: true,
        } as IRichCellEditorParams : { values: ['-'] },
      },
      {
        headerName: '商品マスタ',
        children: [
          {
            headerName: '商品名称',
            field: 'mstItemItemName',
          },
          {
            headerName: '発売日',
            field: 'mstItemSalesStartdate', // 発売日
            width: 200,
            minWidth: 200,
            maxWidth: 250,
            valueFormatter: agGridUtil.dateFormatter,
          },
          {
            headerName: '終了日',
            field: 'mstItemSalesEnddate', // 終了日
            width: 200,
            minWidth: 200,
            maxWidth: 250,
            columnGroupShow: 'open',
            valueFormatter: agGridUtil.dateFormatter,
          },
          {
            headerName: 'ケースサイズ 長手（mm）',
            field: 'mstItemLongside', // ケースサイズ 長手（mm）
            columnGroupShow: 'open',
            cellClass: 'number-cell',
            valueFormatter: (params) => agGridUtil.formatDecimalValue(params, 2),
          },
          {
            headerName: 'ケースサイズ 短手（mm）',
            field: 'mstItemShortside', // ケースサイズ 短手（mm）
            columnGroupShow: 'open',
            cellClass: 'number-cell',
            valueFormatter: (params) => agGridUtil.formatDecimalValue(params, 2),
          },
          {
            headerName: 'ケースサイズ 高さ（mm）',
            field: 'mstItemHeight', // ケースサイズ 高さ（mm）
            columnGroupShow: 'open',
            cellClass: 'number-cell',
            valueFormatter: (params) => agGridUtil.formatDecimalValue(params, 2),
          },
          {
            headerName: 'ケースサイズ 重量（g）',
            field: 'mstItemWeight', // ケースサイズ 重量（g）
            columnGroupShow: 'open',
            cellClass: 'number-cell',
            valueFormatter: (params) => agGridUtil.formatDecimalValue(params, 2),
          },
          {
            headerName: '規格',
            field: 'mstItemSpec',
            columnGroupShow: 'open',
          },
          {
            headerName: 'パレット最大積付数',
            field: 'mstItemMaxPalletQuantity', // パレット最大積付数
            columnGroupShow: 'open',
            cellClass: 'number-cell',
            valueFormatter: (params) => agGridUtil.formatDecimalValue(params, 2),
          },
          {
            headerName: 'パレット積載数（面）',
            field: 'mstItemPalletQuantityFace', // パレット積載数（面）
            columnGroupShow: 'open',
            cellClass: 'number-cell',
            valueFormatter: agGridUtil.numberValueFormatter,
          },
          {
            headerName: 'パレット積載数（段）',
            field: 'mstItemPalletQuantityTier', // パレット積載数（段）
            columnGroupShow: 'open',
            cellClass: 'number-cell',
            valueFormatter: agGridUtil.numberValueFormatter,
          },
          {
            headerName: 'JANコード（バラ）',
            field: 'mstItemJan', // JANコード（バラ）
            columnGroupShow: 'open',
          },
          {
            headerName: 'JANコード（ケース）',
            field: 'mstItemJanCase', // JANコード（ケース）
          },
          {
            headerName: 'GTINコード（バラ）',
            field: 'mstItemGtin13', // GTINコード（バラ）
            columnGroupShow: 'open',
          },
          {
            headerName: 'GTINコード（ケース）',
            field: 'mstItemGtin14', // GTINコード（ケース）
            columnGroupShow: 'open',
          },
          {
            headerName: 'ITFコード',
            field: 'mstItemItf', // ITFコード
            columnGroupShow: 'open',
          },
          {
            headerName: 'SDPコード',
            field: 'mstItemSdp', // SDPコード
            columnGroupShow: 'open',
          },
          {
            headerName: '後継社内商品',
            field: 'mstItemReplaceOrgItemCode', // 後継社内商品
            columnGroupShow: 'open',
            valueFormatter: (params: ValueFormatterParams) => agGridUtil.selectValueFormatter(params.value, items, "mstItemReplaceOrgItemCode", "itemName"),
          },
          {
            headerName: 'メーカーGS1事業者コード',
            field: 'mstItemMakerGln', // メーカーGS1事業者コード
            columnGroupShow: 'open',
          },
          {
            headerName: 'メーカー名',
            field: 'mstItemMakerName', // メーカー名
            columnGroupShow: 'open',
          },
          {
            headerName: '仕入先',
            field: 'mstItemOrgSupplierId', // 仕入先
            width: 220,
            // valueFormatter: (params: ValueFormatterParams) => agGridUtil.selectValueFormatter(params.value, supplier, "mstItemOrgSupplierId", "supplierName"),
          },
          {
            headerName: '発注単位',
            field: 'mstItemOrderUnitType', // 発注単位
            valueFormatter: (params: ValueFormatterParams) => agGridUtil.typecodeFormatter(params.value, orderSysTypeCodeLists['order_unit_type'!]),
          },
          {
            headerName: 'ケースロット数',
            field: 'mstItemCaseLot', // ケースロット数
            cellClass: 'number-cell',
            valueFormatter: agGridUtil.numberValueFormatter,
          },
          {
            headerName: '最低発注量',
            field: 'mstItemMinOrderQuantity', // 最低発注量
            cellClass: 'number-cell',
            valueFormatter: agGridUtil.numberValueFormatter,
          },
        ],
      },
      {
        headerName: 'センター別発注設定',
        children: [
          {
            headerName: '発注単位',
            field: 'orderUnitType',
            editable: true,
            suppressPaste: false,
            cellEditor: 'agRichSelectCellEditor',
            valueFormatter: (params: ValueFormatterParams) => agGridUtil.typecodeFormatter(params.value, orderSysTypeCodeLists['order_unit_type'!]),
            valueParser: (params: ValueParserParams) => agGridUtil.selectTypecodeValueParser(params, orderSysTypeCodeLists['order_unit_type'!]),
            cellEditorParams: editorDataReady ? {
              values: [null, ...orderSysTypeCodeSelects['order_unit_type'!]],
              formatValue: (value: string) => agGridUtil.selectValueFormatter(value, orderSysTypeCodeLists['order_unit_type'!], "typeCode", "typeName")
            } as IRichCellEditorParams : { values: ['-'] },
          },
          {
            headerName: 'ケースロット数',
            field: 'caseLot',
            editable: true,
            cellClass: 'number-cell',
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
              step: 1,
              min: 0,
            },
            valueParser: agGridUtil.numberValueParser,
            valueFormatter: agGridUtil.numberValueFormatter,
          },
          {
            headerName: '最低発注量',
            field: 'minOrderQuantity',
            editable: true,
            cellClass: 'number-cell',
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
              step: 1,
              min: 0,
            },
            valueParser: agGridUtil.numberValueParser,
            valueFormatter: agGridUtil.numberValueFormatter,
          },
          {
            headerName: '出荷停止',
            field: 'stopShip',
            editable: true,
            cellRenderer: 'agCheckboxCellRenderer',
            cellEditor: 'agCheckboxCellEditor',
            valueFormatter: agGridUtil.formatBooleanValue,
            valueParser: agGridUtil.parseBooleanValue,
          },
          {
            headerName: '入荷停止',
            field: 'stopArrival',
            editable: true,
            cellRenderer: 'agCheckboxCellRenderer',
            cellEditor: 'agCheckboxCellEditor',
            valueFormatter: agGridUtil.formatBooleanValue,
            valueParser: agGridUtil.parseBooleanValue,
          },
          {
            headerName: '仕入先企業',
            field: 'orgSupplierId',
            editable: true,
            width: 220,
            suppressPaste: false,
            cellEditor: 'agRichSelectCellEditor',
            valueFormatter: (params: ValueFormatterParams) => agGridUtil.selectValueFormatter(params.value, supplier, "orgSupplierId", "supplierName"),
            valueParser: (params: ValueParserParams) => agGridUtil.selectValueParser(params, supplier, "orgSupplierId"),
            cellEditorParams: editorDataReady ? {
              values: [null, ...supplierSelect],
              formatValue: (value: string) => agGridUtil.selectValueFormatter(value, supplier, "orgSupplierId", "supplierName"),
              searchType: "match",
              allowTyping: true,
              filterList: true,
              highlightMatch: true,
            } as IRichCellEditorParams : { values: ['-'] },
          },
        ]
      },
      {
        headerName: '在庫設定',
        children: [
          {
            headerName: '適用開始日',
            field: 'startDate',
            editable: true,
            width: 200,
            minWidth: 200,
            maxWidth: 250,
            cellEditor: 'agDateCellEditor',
            valueFormatter: agGridUtil.dateFormatter,
            valueParser: agGridUtil.parseDateValue,
          },
          {
            headerName: '在庫日数',
            field: 'stockDaysSetting',
            editable: true,
            cellClass: 'number-cell',
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
              step: 1,
              min: 0,
            },
            valueParser: agGridUtil.numberValueParser,
            valueFormatter: agGridUtil.numberValueFormatter,
          },
          {
            headerName: '安全在庫',
            field: 'safetyStockSetting',
            editable: true,
            cellClass: 'number-cell',
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
              step: 1e-18,
              min: 0,
            },
            valueFormatter: (params) => agGridUtil.formatDecimalValue(params, 3),
            valueParser: (params) => agGridUtil.parseDecimalValue(params, 3)
          },
          {
            headerName: '在庫日数ランク',
            field: 'stockDaysRank',
            editable: true,
            cellClass: 'number-cell',
            suppressPaste: false,
            cellEditor: 'agRichSelectCellEditor',
            valueFormatter: (params: ValueFormatterParams) => agGridUtil.typecodeFormatter(params.value, orderSysTypeCodeLists['stock_days_rank'!]),
            valueParser: (params: ValueParserParams) => agGridUtil.selectTypecodeValueParser(params, orderSysTypeCodeLists['stock_days_rank'!]),
            cellEditorParams: editorDataReady ? {
              values: [null, ...orderSysTypeCodeSelects['stock_days_rank'!]],
              formatValue: (value: string) => agGridUtil.selectValueFormatter(value, orderSysTypeCodeLists['stock_days_rank'!], "typeCode", "typeName")
            } as IRichCellEditorParams : { values: ['-'] },
          },
          {
            headerName: '安全在庫ランク',
            field: 'safetyStockRank',
            editable: true,
            cellClass: 'number-cell',
            suppressPaste: false,
            cellEditor: 'agRichSelectCellEditor',
            valueFormatter: (params: ValueFormatterParams) => agGridUtil.typecodeFormatter(params.value, orderSysTypeCodeLists['safety_stock_rank'!]),
            valueParser: (params: ValueParserParams) => agGridUtil.selectTypecodeValueParser(params, orderSysTypeCodeLists['safety_stock_rank'!]),
            cellEditorParams: editorDataReady ? {
              values: [null, ...orderSysTypeCodeSelects['safety_stock_rank'!]],
              formatValue: (value: string) => agGridUtil.selectValueFormatter(value, orderSysTypeCodeLists['safety_stock_rank'!], "typeCode", "typeName")
            } as IRichCellEditorParams : { values: ['-'] },
          },
          {
            headerName: '最低在庫数',
            field: 'minStockQuantity',
            editable: true,
            cellClass: 'number-cell',
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
              step: 1,
              min: 0,
            },
            valueParser: agGridUtil.numberValueParser,
            valueFormatter: agGridUtil.numberValueFormatter,
          },
        ]
      },
      {
        headerName: '削除フラグ',
        children: [
          {
            headerName: '削除フラグ',
            field: 'deleteFlg', // 削除フラグ
            editable: true,
            cellRenderer: 'agCheckboxCellRenderer',
            cellEditor: 'agCheckboxCellEditor',
            valueFormatter: agGridUtil.formatBooleanValue,
            valueParser: agGridUtil.parseBooleanValue,
          },
        ]
      },
      {
        headerName: 'PSI計算項目',
        children: [
          {
            headerName: '商品コード',
            field: 'itemCode',
          },
          {
            headerName: '最新商品フラグ',
            field: 'recencyFlg',
            cellRenderer: 'agCheckboxCellRenderer',
          },
        ]
      }
    ];

  const onFilterChanged = useCallback(() => {
    const gridApi = gridRef.current?.api;

    if (gridApi) {
      const allColumns = gridApi.getColumns();
      if (allColumns) {
        allColumns.forEach((column) => {
          gridApi.getColumnFilterInstance(column.getColId())
            .then((filterInstance) => {
              if (filterInstance && 'refreshFilterValues' in filterInstance) {
                (filterInstance as any).refreshFilterValues();
              }
            });
        });
      }
      gridApi.redrawRows();
    }
  }, []);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      suppressHeaderMenuButton: false,
      sortable: true,
      filter: 'agMultiColumnFilter',
      filterParams: {
        filters: [
          {
            filter: 'agTextColumnFilter',
          },
          {
            filter: 'agSetColumnFilter',
          },
        ],
      },
      menuTabs: ['filterMenuTab', 'generalMenuTab'] as ColumnMenuTab[],
      mainMenuItems: agGridUtil.customMainMenuItems,
      tooltipValueGetter: agGridUtil.tooltipValue,
      tooltipComponent: GridTooltip,
      cellClassRules: getCellClassRules(),
    }), [])

  const columnDefs = useMemo<(ColDef)[]>(updateColumnDefs, [row, editorDataReady])

  const createBlankRow = useCallback((): MstCenterStockItemWithId => {
    const searchCenterDefault = center.find(rowCenter => rowCenter.centerId === searchParam.centerId);
    // const searchItemDefault = items.find(mstItemData => mstItemData.orgItemCode === searchParam.orgItemCode);
    const blankRow: Partial<MstCenterStockItemWithId> = {
      companyId: loginUserInfo?.companyId || null,
      orgItemCode: searchParam.orgItemCode || null,
      orgCenterId: searchCenterDefault?.orgCenterId || null,
      centerId: searchParam.centerId || null,
      supplierCompanyId: null,
      startDate: null,
      stockDaysRank: null,
      safetyStockRank: null,
      stockDaysSetting: null,
      safetyStockSetting: null,
      minStockQuantity: null,
      orderUnitType: null,
      caseLot: null,
      minOrderQuantity: null,
      stopShip: false,
      stopArrival: false,
      orgSupplierId: null,
      itemCode: null,
      recencyFlg: false,
      deleteDatetime: null,
      updateUser: null,
      deleteUser: null,
      insertDatetime: null,
      insertUser: loginUserInfo?.userId || null,
      updateDatetime: null,
      deleteFlg: false,
      ...agGridUtil.initRecordEditEx()
    };
    setMstItemCol(blankRow as MstCenterStockItemWithId, searchParam.orgItemCode);
    return blankRow as MstCenterStockItemWithId;

  }, [searchParam, center, items, loginUserInfo?.companyId, loginUserInfo?.userId]);

  const addRowAction = (actionParams: IMenuActionParams) => {
    // 右クリックの新規行追加選択時
    // console.log("addRowAction", actionParams)
    const { node } = actionParams;
    if (!node) return;
    const { data, rowIndex } = node;

    const gridApi = gridRef.current?.api;
    if (gridApi && data && rowIndex !== null) {
      const newRow: MstCenterStockItemWithId = createBlankRow();
      // console.log("addRowAction", newRow)
      const displayedRowIndex = node.rowIndex;
      if (displayedRowIndex !== null) {
        const addIndex = displayedRowIndex + 1;

        setRow(prevRows => {
          const newRows = [...prevRows];
          newRows.splice(addIndex, 0, newRow);
          return newRows;
        });
        setEditedRows(prev => [
          ...prev,
          {
            ...newRow,
            isEdited: true,
            editedFields: new Set([]),
            errors: {}
          }
        ]);
        gridApi.applyTransaction({
          add: [newRow],
          addIndex
        });
        gridApi.redrawRows();
      }
    }
  }
  const deleteRowAction = (actionParams: IMenuActionParams) => {
    // 右クリックの行削除選択時
    // console.log("deleteRowAction", actionParams)
    const { node } = actionParams;
    if (!node) return;
    const { data } = node;
    setRow(prevRows => prevRows.filter(rowDelete => rowDelete.id !== data.id));
    setEditedRows(prev => prev.filter(rowDelete => rowDelete.id !== data.id));

  }
  const getContextMenuItems = useCallback((params: GetContextMenuItemsParams): (string | MenuItemDef)[] =>
    // 右クリックメニュー
    agGridUtil.getContextMenuItems(params,
      addRowAction,
      deleteRowAction
    )
    , []);

  const handleImportExcel = async (data: any[]) => {
    const dateFields: (keyof MstCenterStockItemWithId)[] = ['startDate'];
    let hasError = false;
    const mappedData = data.map((excelRow: any) => {
      const mappedRow: Partial<MstCenterStockItemWithId> = {};

      Object.keys(columnMapping).forEach((key) => {
        if (key in excelRow) {
          const tableField = columnMapping[key as keyof typeof columnMapping];
          const importvalue = excelRow[key];
          let setvalue = importvalue || null;
          if (['deleteFlg','stopShip','stopArrival'].indexOf(tableField) >= 0) {
            setvalue = agGridUtil.parseBoolean(excelRow[key])
          } else if (['orderUnitType','stockDaysRank','safetyStockRank'].indexOf(tableField) >= 0) {
            const group = tableFieldToGroup[tableField];
            const typeList = orderSysTypeCodeLists[group];

            const codevalue = agGridUtil.typeCodeParser(importvalue, typeList,)
            setvalue = agGridUtil.typeCodeParser(codevalue, typeList,)
            if (!codevalue && importvalue) hasError = true;
          } else if (tableField === 'startDate') {
            setvalue = agGridUtil.parseDate(importvalue) as unknown as undefined;;
          } else if (['caseLot','minOrderQuantity','stockDaysSetting','minStockQuantity'].indexOf(tableField) >= 0) {
            setvalue = agGridUtil.parsePositiveInteger(importvalue);
          } else if (['safetyStockSetting'].indexOf(tableField) >= 0) {
            setvalue = agGridUtil.parseNumeric(importvalue);
          } else {
            setvalue = importvalue ? String(importvalue).trim() : null;;
          }
          // パース出来ないときはスキップ（＝NULL）し、警告メッセージを表示
          if (!agGridUtil.isNonInputValue(importvalue) && setvalue === null) hasError = true;
          mappedRow[tableField as keyof MstCenterStockItemWithId] = setvalue as unknown as undefined
        }
      });

      const { orgItemCode, orgCenterId } = mappedRow;
      setMstItemCol(mappedRow as MstCenterStockItemWithId, orgItemCode);

      return mappedRow as MstCenterStockItemWithId;
    });

    const rowMap = new Map(row.map((existingRow) => [
      `${existingRow.orgCenterId}-${existingRow.orgItemCode}` || `new-${existingRow.id}`,
      existingRow
    ]));

    const mappedRowMap = new Map(mappedData.map((importedRow) => [
        `${importedRow.orgCenterId}-${importedRow.orgItemCode}` || `imported-${Math.random().toString(32).substring(2)}`,
        importedRow
    ]));

    const newRows: MstCenterStockItemWithId[] = [];
    const editedRowsTemp: MstCenterStockItemWithId[] = [];

    if (hasError) {
      setResultMessage({
        status: 'warning',
        message: '取り込めない値はスキップされました',
        systemDate: null,
        pageNo: 0,
        systemInfo: null,
      })
    }

    mappedRowMap.forEach((importedRow, rowId) => {
      const existingRow = rowMap.get(rowId);

      if (existingRow) {
        // update existing row
        let editCount=0
        Object.keys(importedRow).forEach((key) => {
          const typedKey = key as keyof MstCenterStockItemWithId
          const importedValue = importedRow[key as keyof MstCenterStockItemWithId] ?? null;
          const existingValue = existingRow[key as keyof MstCenterStockItemWithId] ?? null;

          if (!Object.values(columnMapping).includes(key)) {
            // skip
          } else if (dateFields.includes(typedKey)) {
            if (!isEqualDate(importedValue, existingValue)){
              existingRow.editedFields.add(key);
              editCount += 1
            }

          } else if (String(importedValue) !== String(existingValue)) {
            existingRow.editedFields.add(key);
            editCount += 1
          }
        });

        if (editCount > 0) {
          const updatedRow = {
            ...existingRow,
            ...importedRow,
            isEdited: true,
            errors: {},
          };
          // 入力チェック
          rowDataValidate(updatedRow)
          editedRowsTemp.push(updatedRow);
          rowMap.set(rowId, updatedRow);
        }
      } else {
        // add new row
        const editedFields = new Set<string>();

        Object.keys(importedRow).forEach((key) => {
          const value = importedRow[key as keyof MstCenterStockItemWithId];
          if (value !== null && value !== undefined && value !== '' && Object.values(columnMapping).includes(key)) {
            editedFields.add(key);
          }
        });

        const newRow = {
          ...createBlankRow(),
          ...importedRow,
          isEdited: true, 
          errors: {},
          editedFields,
        };
        newRows.push(newRow);
        rowMap.set(rowId, newRow);
      }
    });

    const updatedRowData = Array.from(rowMap.values());
    setRow(updatedRowData);
    setEditedRows((prevEditedRows) => {
      const filteredEditedRows = prevEditedRows.filter(
        (prevRow) => !editedRowsTemp.some((editedRow) => editedRow.orgItemCode === prevRow.orgItemCode && editedRow.orgCenterId === prevRow.orgCenterId)
      );
      const newEditedRows = newRows.filter(
        (newRow) => !prevEditedRows.some((prevRow) => prevRow.orgItemCode === newRow.orgItemCode && prevRow.orgCenterId === newRow.orgCenterId)
      );
      return [...newEditedRows, ...filteredEditedRows, ...editedRowsTemp];
    });

    if (gridRef.current?.api) {
      gridRef.current.api.applyTransaction({ add: newRows, update: editedRowsTemp });
      gridRef.current.api.refreshCells();
    }
  };
  useEffect(() => {
    if (editedRows && gridRef.current?.api) {
      gridRef.current.api.applyTransaction({ update: editedRows });
      gridRef.current.api.refreshCells();
    }
  }, [editedRows]);

  useEffect(() => {
    if (onTriggerAction) {
      updateMstCenterStockItem();
    }
  }, [onTriggerAction]);

  useEffect(() => {
    if (onUploadData) {
      handleImportExcel(onUploadData);
    }
  }, [onUploadData]);

  useEffect(() => {
    if (editedRows.length > 0) {
      setHasGridEdited(true);
    }
  }, [editedRows, setHasGridEdited]);

  const onSnackbarClose = () => {
    setSnackBarOpen(false)
  }

  useEffect(() => {
    if (list.list.length === 0) {
      const blankRow = createBlankRow();
      setRow([blankRow]);
    } else {
      const datalist = list.list.map(item => ({ ...item, ...agGridUtil.initRecordEditEx() }));
      datalist.forEach((item) => {
        setMstItemCol(item as MstCenterStockItemWithId, item.orgItemCode);
      })
      setRow(datalist);
    }
  }, [list.list]);

  const originalConsoleWarn = console.error;

  console.error = (...args) => {
    if (typeof args[0] === 'string' && args[0].includes('AG Grid: could not find row id')) {
      return;
    }
    originalConsoleWarn(...args);
  };

  return (
    <>
      <div style={{ ...containerStyle, display: 'flex', flexDirection: 'column', height: '69vh' }}>
        <div style={{ flexGrow: 1, boxSizing: 'border-box' }}>
          <div style={gridStyle} className="ag-theme-quartz">
            <AgGridReact
              key={`${row[0]?.companyId}-${editorDataReady}`}
              rowData={row}
              ref={gridRef}
              getRowId={(params) => params.data.id}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              localeText={localeText}
              enableRangeSelection
              fillHandleDirection="xy"
              animateRows
              tooltipShowDelay={0}
              tooltipHideDelay={3000}
              onCellValueChanged={onCellValueChanged}
              rowSelection="single"
              autoSizeStrategy={autoSizeStrategy}
              rowDragManaged
              rowHeight={36}
              allowContextMenuWithControlKey
              getContextMenuItems={getContextMenuItems}
              loadingOverlayComponent={LoadingOverlay}
              overlayNoRowsTemplate={'<span aria-live="polite" aria-atomic="true";pos>条件に合致する商品が見つかりません</span>'}
              suppressLastEmptyLineOnPaste
              onPasteStart={onPasteStart}
              onPasteEnd={onPasteEnd}
              reactiveCustomComponents
              onFilterChanged={onFilterChanged}
            />
          </div>
        </div>
      </div>


      <AppSnackbar
        message={snackBarMessage}
        open={snackBarOpen}
        onClose={onSnackbarClose}
        severity={snackBarSeverity}
      />
    </>
  );
}

export default DataList;
