import React, { useCallback, useEffect, useMemo, useRef, useState, type Dispatch, type SetStateAction } from 'react';

import { useAuthContext } from 'AuthProvider';
import {type CellValueChangedEvent} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react';
import AppSnackbar from 'components/commonparts/AppSnackbar/AppSnackbar';
import GridTooltip from 'components/commonparts/GridTooltip/GridTooltip';
import {LoadingOverlay} from 'components/commonparts/index';
import { useCalendar } from 'hooks/useCalendar';
import { AG_GRID_LOCALE } from 'utils/agGridLocale';
import * as agGridUtil from 'utils/agGridUtil';
import { convertDateToRequestParam } from 'utils/convertDateToRequestParam';
import { valueToDateStr, isEqualDate } from 'utils/convertFromLocalDate'
import { validateRequired } from 'utils/validation';

import type {
    ColDef,
    ColumnMenuTab,
    GetContextMenuItemsParams,
    IMenuActionParams,
    IRichCellEditorParams,
    MenuItemDef,
    SizeColumnsToContentStrategy,
    SizeColumnsToFitGridStrategy,
    SizeColumnsToFitProvidedWidthStrategy,
    ValueFormatterParams,
    ValueParserParams,
  } from 'ag-grid-enterprise'
  import 'ag-grid-enterprise'
  import 'ag-grid-community/styles/ag-grid.css'
  import 'ag-grid-community/styles/ag-theme-balham.css'
import type { Calendar, MstCalendar,  GetMstCalendarCalendarGetRequest, MstCenter, ResponseResult } from 'api-client';
import 'utils/mst.scss'

const columnMapping: Record<string, string> = {
  "org_center_id": "orgCenterId",
  "calendar_date" : "calendarDate",
  "enable_ship" : "enableShip",
  "enable_order" : "enableOrder",
  "enable_arrival" : "enableArrival",
  "delete_flg": "deleteFlg"
};


interface DataListProps {
  list: Calendar;
  availableItems: MstCenter[] ;
  isLoading: boolean;
  onReload: () => void;
  onUploadData: any[];
  onReset: boolean;
  searchParam: GetMstCalendarCalendarGetRequest
  onSearch: (requestParam: GetMstCalendarCalendarGetRequest) => void;
  setOnReset: (value: boolean) => void;
  onTriggerAction: boolean;
  setOnTriggerAction : (value: boolean) => void,
  setHasGridEdited: (value: boolean) => void,
  setResultMessage: Dispatch<SetStateAction<ResponseResult | undefined>>
}

interface MstCalendarWithId extends MstCalendar , agGridUtil.RecordEditEx {}

function DataList({ 
  list, 
  isLoading, 
  onReset, 
  searchParam, 
  onSearch, 
  availableItems,
  setOnReset,
  onReload, 
  onTriggerAction, 
  setOnTriggerAction, 
  onUploadData,
  setHasGridEdited,
  setResultMessage,
}: DataListProps) {
  const { loginUserInfo } = useAuthContext()
  const {fetchCalendarUpdate, result, isUpdating} = useCalendar()
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarSeverity, setSnackBarSeverity] = useState<'error' | 'warning' | 'info' | 'success'>('success')
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [editedRows, setEditedRows] = useState<MstCalendarWithId[]>([]);
  const gridRef = useRef<AgGridReact<MstCalendarWithId>>(null)
  const updatedRowsRef = useRef<any[]>([]);

  const localeText = useMemo<{
    [key: string]: string
  }>(() => AG_GRID_LOCALE, [])

  const autoSizeStrategy = useMemo<
  | SizeColumnsToFitGridStrategy
  | SizeColumnsToFitProvidedWidthStrategy
  | SizeColumnsToContentStrategy
    >(() => ({ type: "fitGridWidth" }), []);

  const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 300px)' }), [])
  const gridStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 300px)' }), [])
  const [row, setRow] = useState<MstCalendarWithId[]>(list.list.map(item => ({ ...item, ...agGridUtil.initRecordEditEx()})))  
  const [editorDataReady, setEditorDataReady] = useState(false);
  const pendingChanges = useRef<{ node: any; updatedRow: MstCalendarWithId }[]>([]);
  const isPasting = useRef(false)
  const [centerSelect, setCenterSelect] = useState<(string | null)[]>([])
  useEffect(() => {
    setResultMessage(result)
  }, [result])

  useEffect(() => {
    if (row.length > 0 && availableItems) {
      setEditorDataReady(true);
    }
  }, [row, availableItems]);
    useEffect(() => {
      // console.debug("useEffect row", row)
      setEditorDataReady(false)
      const values = availableItems?.map((r) => r.orgCenterId) || []
      setCenterSelect([...values])
    }, [availableItems]);
      
  useEffect(() => {
    if (onReset) {
      setEditedRows([]); 
      setOnReset(false);
    }
  }, [onReset, setOnReset]);

  const onPasteStart = useCallback(() => {
    isPasting.current = true;
    pendingChanges.current = []; 
  }, []);

  const colDataValidate = (pRow : MstCalendarWithId, field: string | undefined, newValue:any):boolean => {
    if (!field) return false;
    let hasErrors = false
    const trRow = pRow
    const checkAndSetError = (f: string, error: string | null) :boolean => {
      if (error) {
        trRow.errors[f] = [error];
        trRow.editedFields.add(f);
        hasErrors = true;
      } else if (trRow.errors && trRow.errors[f]) {
        delete trRow.errors[f]
      }
      return hasErrors
    };

    checkAndSetError(field, "");
    switch (field){
      case "orgCenterId":
      case "calendarDate":
          checkAndSetError(field, validateRequired(newValue));
        break;
      case "enableShip":
      case "enableArrival":
      case "enableOrder":
          checkAndSetError(field, validateRequired(newValue));
          break;

      default:
        return false;
    }
    return hasErrors
  
  };
  
  // 行に含まれる項目に入力チェックを実施
  const rowDataValidate = (pRow:MstCalendarWithId):boolean => {
    if (!pRow) return false;
    const trRow = pRow
    let hasErrors = false
    trRow.errors = {};
    Object.keys(columnMapping).forEach((key) => {
      const fieldValue = trRow[columnMapping[key] as keyof typeof trRow];
      colDataValidate(trRow, columnMapping[key], fieldValue)
    });

    if (trRow.errors && Object.keys(trRow.errors).length > 0) {
      hasErrors = true;
    }
    return hasErrors
  }  
  const onCellValueChanged = useCallback((event: CellValueChangedEvent<MstCalendarWithId>) => {
    setResultMessage(undefined)
    const { data, colDef, newValue, oldValue, node } = event;
    const { field } = colDef;

    const updatedRow = data  
    const haserror = colDataValidate(updatedRow, field, newValue)
    
    if (newValue !== oldValue){
      updatedRow.isEdited = true;
      updatedRow.editedFields.add(field as string);
      // console.log("onCellValueChanged change")
    }
    // 値の変更時に、APIのエラーを解消
    switch (field){
      case "orgCenterId":
        colDataValidate(updatedRow, "orgCenterId", updatedRow.orgCenterId);
        break;
      default:
    }
    if (isPasting.current) {
      let pendingRow = pendingChanges.current.find((change) => change.updatedRow.id === data.id);
  
      if (!pendingRow) {
        pendingRow = {
          node,
          updatedRow: { ...updatedRow },
        };
        pendingChanges.current.push(pendingRow);
      } else {
        const trRow = pendingRow.updatedRow as MstCalendarWithId
        trRow[field as keyof MstCalendar] = newValue;
        trRow.isEdited = true;
        trRow.editedFields.add(field as string);
        colDataValidate(trRow, field, newValue)
      }
  
      node.setData(pendingRow.updatedRow);
    } else {

      setEditedRows((prevEditedRows) => {
        const rowIndex = prevEditedRows.findIndex((rowa) => rowa.id === updatedRow.id);
        if (rowIndex !== -1) {
          const newEditedRows = [...prevEditedRows];
          newEditedRows[rowIndex] = updatedRow;
          return newEditedRows;
        }
        return [...prevEditedRows, updatedRow];
      });
  
      node.setData(updatedRow);
    }

  }, [row]);
  
  
  const onPasteEnd = useCallback(() => {
    isPasting.current = false; 
  
    setEditedRows((prevEditedRows) => {
      const newEditedRows = [...prevEditedRows];
      pendingChanges.current.forEach(({ node, updatedRow }) => {
        const rowIndex = newEditedRows.findIndex((rowb) => rowb.id === updatedRow.id);
        if (rowIndex !== -1) {
          newEditedRows[rowIndex] = updatedRow;
        } else {
          newEditedRows.push(updatedRow);
        }
        node.setData(updatedRow);
      });
      return newEditedRows;
    });
  
    pendingChanges.current = [];
  }, []);

  const updateMstCalendar = useCallback( async () => {
    setResultMessage(undefined)
    const baseData = editedRows as unknown as Array<MstCalendarWithId>
    let hasErrors = false;
    
    const updatedRows = baseData.map((item) => {
      // 変更時にエラーチェックが行われているが、登録前に再度チェックを実施する
      const updatedRow = { ...item };
      hasErrors = rowDataValidate(updatedRow) || hasErrors
      const gridApi = gridRef.current?.api;
      const rowNode = gridApi?.getRowNode(item.id ?? '');
      if (rowNode) {
        rowNode.setData(updatedRow);
      }
      gridApi?.refreshCells({ force: true });
      return updatedRow
    });

    if (hasErrors) {
      // console.log("hasErrors", updatedRows)
      setResultMessage({
        status: 'error',
        message: '入力エラーがあります',
        systemDate: null,
        pageNo: 0,
        systemInfo: null,
      })           
      setOnTriggerAction(false);
      return;
    }

    updatedRowsRef.current = updatedRows;
    
    const gridApi = gridRef.current?.api;
    if (gridApi) {
      gridApi.applyTransaction({ update: updatedRows });
      gridApi.redrawRows();
    }

    if (baseData.length === 0 ) {
      setResultMessage({
        status: 'warning',
        message: '更新対象のデータがありません',
        systemDate: null,
        pageNo: 0,
        systemInfo: null,
      })           
      setOnTriggerAction(false);
      return;
    }

    const datalist = baseData.map(({ isEdited, orgCenterId, calendarDate, editedFields, errors, ...rest }) => ({
      ...rest,
      orgCenterId: orgCenterId ??  '',
      calendarDate: calendarDate ? convertDateToRequestParam(new Date(calendarDate)) : null,
    }));

    try {
      await fetchCalendarUpdate({
        mstCalendarUpdate: { body: datalist }
      })

      setSnackBarMessage('正常に更新されました');
      setSnackBarSeverity('success'); 
      setEditedRows([]);     
      onReload()
    } catch (err: any) {
      if (err?.errors && err.result?.status === "error") {
        if (gridApi) {
          err.errors.forEach((errrow: any) => {

            const {rowdata}=errrow
            const orgCenterId = (rowdata as { org_center_id: string | null }).org_center_id;
            const calendarDate = (rowdata as { calendar_date: string }).calendar_date;
            const editedRowIndex = editedRows.find((rowdatas) => rowdatas.orgCenterId === orgCenterId && isEqualDate(rowdatas.calendarDate,calendarDate)); 
            // console.log("***error", orgCenterId, calendarDate, editedRowIndex, editedRows, datalist)
            if (editedRowIndex) {
              // UpdateAPIのErrorは、社内商品コードをエラー表記して、サーバーから取得したエラーを表示する
              const updatedRow = {
                ...editedRowIndex,
                errors: {
                  orgCenterId: [errrow.errorMessage], 
                  calendarDate: [errrow.errorMessage], 
                },
              };
                          
              setEditedRows((prev) =>
                prev.map((editedRow) =>
                  editedRow.orgCenterId === orgCenterId ? updatedRow : editedRow
                )
              );
              const rowNode = gridApi.getRowNode(updatedRow.id || '');
              if (rowNode) {
                rowNode.setData(updatedRow);
              }
            }
          });
          gridApi.redrawRows();
        }
        setSnackBarMessage("");
        setSnackBarSeverity("error");
        setSnackBarOpen(true);
        return;
      }

    } finally {
      setSnackBarOpen(true);
      setOnTriggerAction(false)
    }
  }, [fetchCalendarUpdate, onSearch, editedRows, searchParam]);
  const getCellClassRules = () => ({
    'error-cell': (params: any) => {
      const rowData = params.data as MstCalendarWithId;
      const { field } = params.colDef;
      const hasError = Array.isArray(rowData.errors[field]) && rowData.errors[field]?.length > 0;
      return hasError || false; 
    },
    'current-updated-cell': (params: any) => {
      const rowData = params.data as MstCalendarWithId;
      const { field } = params.colDef;
      return rowData.isEdited && (!rowData.errors?.[field] || rowData.errors[field].length === 0) && rowData.editedFields?.has(field);
    },
    'editable-cell': (params: any) => {
      const { editable } = params.colDef;
      let iseditable = false
      if (typeof editable === 'function'){
        iseditable = editable(params)
      } else {
        iseditable = editable
      }
      return iseditable
    }
  });


  useEffect(() => {
    const gridApi = gridRef.current?.api;
    if (!gridApi) return;
  
    if (isLoading || isUpdating ) {
      gridApi.showLoadingOverlay();
    } else {
      gridApi.hideOverlay();
    }
  }, [isLoading, isUpdating, gridRef.current]);

  /**
   * カラム定義
   * @returns
   */
  const updateColumnDefs = (): (ColDef)[] => 
  [
    {
      headerName: '自社センターID',
      field: 'orgCenterId', // 自社センターID
      editable: (params) => params.data && params.data.insertDatetime === null,
      cellClassRules: {
        ...getCellClassRules(),
        'editable-cell': (params) => params.data && params.data.insertDatetime === null, 
      },
      width: 350,
      flex:3,
      rowDrag: true,
      cellEditor: 'agRichSelectCellEditor',
      valueFormatter: (params: ValueFormatterParams) => agGridUtil.selectValueFormatter(params.value, availableItems, "orgCenterId", "centerName"),
      valueParser: (params: ValueParserParams) => agGridUtil.selectValueParser(params, availableItems, "orgCenterId"),
      cellEditorParams: editorDataReady ? {
        values : [null, ...centerSelect],
        formatValue: (value: string) => agGridUtil.selectValueFormatter(value, availableItems, "orgCenterId", "centerName"),
        searchType: "match",
        allowTyping: true,
        filterList: true,
        highlightMatch: true,
      } as IRichCellEditorParams : {values: ['-']},
    },           
    {
      headerName: '日付',
      field: 'calendarDate', // 日付
      editable: (params) => params.data && params.data.insertDatetime === null,
      cellClassRules: {
        ...getCellClassRules(),
        'editable-cell': (params) => params.data && params.data.insertDatetime === null, 
      },
      width: 300,
      flex:2,
      cellEditor: 'agDateCellEditor',
      cellEditorParams: {
        minYear: 1900, 
        maxYear: 2050,
      },
      valueFormatter: agGridUtil.dateFormatter, 
      valueParser: agGridUtil.parseDateValue,
    },
    {
      headerName: '出荷可能フラグ',
      field: 'enableShip', // 出荷可能フラグ
      editable: true,
      flex:1,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
      cellClassRules: getCellClassRules(),
      valueFormatter: agGridUtil.formatBooleanValue,
      valueParser: agGridUtil.parseBooleanValue, 
    },
    {
      headerName: '受入可能フラグ',
      field: 'enableArrival', // 受入可能フラグ
      editable: true,
      flex:1,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
      valueFormatter: agGridUtil.formatBooleanValue,
      valueParser: agGridUtil.parseBooleanValue, 
    },
    {
      headerName: '発注業務可能フラグ',
      field: 'enableOrder', // 発注業務可能フラグ
      editable: true,
      flex:1,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
      valueFormatter: agGridUtil.formatBooleanValue,
      valueParser: agGridUtil.parseBooleanValue, 
    },
    {
      headerName: '削除フラグ',
      field: 'deleteFlg', // 削除フラグ
      editable: true,
      flex:1,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
      valueFormatter: agGridUtil.formatBooleanValue,
      valueParser: agGridUtil.parseBooleanValue, 
    },
  ];

  const onFilterChanged = useCallback(() => {
    const gridApi = gridRef.current?.api;
  
    if (gridApi) {
      const allColumns = gridApi.getColumns();
      if (allColumns) {
        allColumns.forEach((column) => {
          gridApi.getColumnFilterInstance(column.getColId())
            .then((filterInstance) => {
              if (filterInstance && 'refreshFilterValues' in filterInstance) {
                (filterInstance as any).refreshFilterValues();
              }
            });
        });
      }
      gridApi.redrawRows();
    }
  }, []);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      suppressHeaderMenuButton: false,
      sortable: true,
      filter: 'agMultiColumnFilter',
      filterParams: {
        filters: [
          {
            filter: 'agTextColumnFilter',
          },
          {
            filter: 'agSetColumnFilter',
          },
        ],
      },
      menuTabs: ['filterMenuTab', 'generalMenuTab'] as ColumnMenuTab[],
      mainMenuItems: agGridUtil.customMainMenuItems,
      tooltipValueGetter: agGridUtil.tooltipValue,
      tooltipComponent: GridTooltip,
      cellClassRules: getCellClassRules(),
    }),[])


  const columnDefs = useMemo<(ColDef)[]>(updateColumnDefs, [row, editorDataReady])
  
  const createBlankRow = useCallback((): MstCalendarWithId => {
    const currentCenter = availableItems?.find(centerExist => centerExist.orgCenterId === searchParam.orgCenterId);
    const currentOrgCenterId = currentCenter?.orgCenterId || null;
  
    return {
      companyId: loginUserInfo?.companyId || null,
      orgCenterId: currentOrgCenterId,
      calendarDate: null,
      enableArrival: false,
      enableOrder: false,
      enableShip: false,
      deleteDatetime: null,
      updateUser: null,
      deleteUser: null,
      insertDatetime: null,
      insertUser: loginUserInfo?.userId || null,
      updateDatetime: null,
      deleteFlg: false,
      ...agGridUtil.initRecordEditEx()
    };
  }, [searchParam, availableItems, loginUserInfo?.companyId, loginUserInfo?.userId]);
  
  const addRowAction = (actionParams : IMenuActionParams) => {
      // 右クリックの新規行追加選択時
      // console.log("addRowAction", actionParams)
      const { node } = actionParams;
      if (!node)  return;
      const { data, rowIndex} = node;
  
      const gridApi = gridRef.current?.api;
      if (gridApi && data && rowIndex !== null) {
        const newRow: MstCalendarWithId = createBlankRow();
  
        const displayedRowIndex = node.rowIndex;
        if (displayedRowIndex !== null) {
          const addIndex = displayedRowIndex + 1;
          
          setRow(prevRows => {
            const newRows = [...prevRows];
            newRows.splice(addIndex, 0, newRow);
            return newRows;
          });
          setEditedRows(prev => [
            ...prev, 
            {
              ...newRow,
              isEdited: true,
              editedFields: new Set([]),
              errors: {}
            }
          ]);
          gridApi.applyTransaction({
            add: [newRow],
            addIndex
          });
          gridApi.redrawRows();
        }
      }    
    }
    const deleteRowAction = (actionParams : IMenuActionParams) => {
      // 右クリックの行削除選択時
      // console.log("deleteRowAction", actionParams)
      const { node } = actionParams;
      if (!node)  return;
      const { data } = node;    
      setRow(prevRows => prevRows.filter(rowDelete => rowDelete.id !== data.id));
      setEditedRows(prev => prev.filter(rowDelete => rowDelete.id !== data.id));
  
    }  
    const getContextMenuItems = useCallback((params: GetContextMenuItemsParams): (string | MenuItemDef)[] => 
      // 右クリックメニュー
      agGridUtil.getContextMenuItems(params,
        addRowAction,
        deleteRowAction
      )
    , []);


  const handleImportExcel = async (data: any[]) => {
    const dateFields: (keyof MstCalendarWithId)[] = ['calendarDate'];
    // const skipList: (keyof MstCalendarWithId)[] = ['id'];
    let hasError = false;
    const mappedData = data.map((excelRow: any) => {
      const mappedRow: Partial<MstCalendarWithId> = {};
  
      Object.keys(columnMapping).forEach((key) => {
        if (key in excelRow) {
          const tableField = columnMapping[key as keyof typeof columnMapping];
          const importvalue = excelRow[key];
          let setvalue = importvalue || null;
          if (['deleteFlg', 'enableShip', 'enableArrival', 'enableOrder'].indexOf(tableField) >= 0) {
            setvalue = agGridUtil.parseBoolean(importvalue)
          } else if (tableField === 'calendarDate') {
            setvalue = agGridUtil.parseDate(importvalue) as unknown as undefined;;
          } else {
            setvalue = importvalue ? String(importvalue).trim() : null;;
          }
          // パース出来ないときはスキップ（＝NULL）し、警告メッセージを表示
          if (!agGridUtil.isNonInputValue(importvalue) && setvalue === null) hasError = true;
          mappedRow[tableField as keyof MstCalendarWithId] = setvalue as unknown as undefined
        }
      });
      return mappedRow as MstCalendarWithId;
    });
  
    const rowMap = new Map(row.map((existingRow) => [
      `${existingRow.orgCenterId}-${valueToDateStr(existingRow.calendarDate) || 'Invalid Date'}`, 
      existingRow]));
    const mappedRowMap = new Map(
      mappedData.map((existingRow) => [
        `${existingRow.orgCenterId}-${valueToDateStr(existingRow.calendarDate) || 'Invalid Date'}`,
        existingRow,
      ])
    );
    const newRows: MstCalendarWithId[] = [];
    const editedRowsTemp: MstCalendarWithId[] = [];
  
    if( hasError ) {
      setResultMessage({
        status: 'warning',
        message: '取り込めない値はスキップされました',
        systemDate: null,
        pageNo: 0,
        systemInfo: null,
      })           
    }

    mappedRowMap.forEach((importedRow, rowId) => {
      const existingRow = rowMap.get(rowId);
  
      if (existingRow) {
        let editCount=0
        Object.keys(importedRow).forEach((key) => {
          const typedKey = key as keyof MstCalendarWithId
          const importedValue = importedRow[key as keyof MstCalendarWithId] ?? null;
          const existingValue = existingRow[key as keyof MstCalendarWithId] ?? null;
          // if (key == 'id') {
          // } else 
          if (dateFields.includes(typedKey)) {
            if (!isEqualDate(importedValue, existingValue)){
              existingRow.editedFields.add(key);
              editCount += 1
            }

          } else if (String(importedValue) !== String(existingValue)) {
            existingRow.editedFields.add(key);
            editCount += 1
          }
        });

        if (editCount > 0) {
          const updatedRow = {
            ...existingRow,
            ...importedRow,
            isEdited: true,
            errors: {},
          };
          rowDataValidate(updatedRow)
          editedRowsTemp.push(updatedRow);
          rowMap.set(rowId, updatedRow);
        }
      } else {
        const editedFields = new Set<string>();

        Object.keys(importedRow).forEach((key) => {
          const value = importedRow[key as keyof MstCalendarWithId];
          if (value !== null && value !== undefined && value !== '') {
            editedFields.add(key);
          }
        });
  
        const newRow = {
          ...createBlankRow(),
          ...importedRow,
          isEdited: true, 
          errors: {},
          editedFields,
        };
        rowDataValidate(newRow)
        newRows.push(newRow);
        rowMap.set(rowId, newRow);
      }
    });

    const updatedRowData = Array.from(rowMap.values());
    setRow(updatedRowData);
    setEditedRows((prevEditedRows) => {
      const filteredEditedRows = prevEditedRows.filter(
        // (prevRow) => !editedRowsTemp.some((editedRow) => editedRow.orgCenterId === prevRow.orgCenterId && editedRow.calendarDate === prevRow.calendarDate)
        (prevRow) => !editedRowsTemp.some((editedRow) => editedRow.orgCenterId === prevRow.orgCenterId && isEqualDate(editedRow.calendarDate,prevRow.calendarDate))
      );
      const newEditedRows = newRows.filter(
        // (newRow) => !prevEditedRows.some((prevRow) => prevRow.orgCenterId === newRow.orgCenterId && newRow.calendarDate === prevRow.calendarDate)
        (newRow) => !prevEditedRows.some((prevRow) => prevRow.orgCenterId === newRow.orgCenterId && isEqualDate(newRow.calendarDate,prevRow.calendarDate))
      );
      return [...newEditedRows, ...filteredEditedRows, ...editedRowsTemp];
    });
  
    if (gridRef.current?.api) {
      gridRef.current.api.applyTransaction({ add: newRows, update: editedRowsTemp });
      gridRef.current.api.refreshCells({ force: true });
    }
  };
  useEffect(() => {
    if (editedRows && gridRef.current?.api) {
      gridRef.current.api.applyTransaction({ update: editedRows });
      gridRef.current.api.refreshCells({ force: true });
    }
  }, [editedRows]);

  useEffect(() => {
    if (onTriggerAction) {
      updateMstCalendar();
    }
  }, [onTriggerAction]);

  useEffect(() => {
    if (onUploadData) {
      handleImportExcel(onUploadData);
    }
  }, [onUploadData]);

  useEffect(() => {
    if (editedRows.length > 0) {
      setHasGridEdited(true);
    } 
  }, [editedRows, setHasGridEdited]);

  const onSnackbarClose = () => {
    setSnackBarOpen(false)
  }

  useEffect(() => {
    if (list.list.length === 0) {
      const blankRow = createBlankRow();
      setRow([blankRow]);
    } else {
      setRow(list.list.map(item => ({ ...item, ...agGridUtil.initRecordEditEx()})))
    }
  }, [list.list, createBlankRow]);

  const originalConsoleWarn = console.error;

  console.error = (...args) => {
    if (typeof args[0] === 'string' && args[0].includes('AG Grid: could not find row id')) {
      return; 
    }
    originalConsoleWarn(...args); 
  };

  return (
    <>
      <div style={{ ...containerStyle, display: 'flex', flexDirection: 'column', height: '69vh' }}>
        <div style={{ flexGrow: 1, boxSizing: 'border-box' }}>
          <div style={gridStyle} className="ag-theme-quartz">
            <AgGridReact
              key={`${row[0]?.companyId}-${editorDataReady}`}
              rowData={row}
              ref={gridRef}
              getRowId={(params) => params.data.id}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              localeText={localeText}
              enableRangeSelection
              fillHandleDirection="xy"
              animateRows
              tooltipShowDelay={0}
              tooltipHideDelay={3000}
              onCellValueChanged={onCellValueChanged}
              rowSelection="single"
              autoSizeStrategy={autoSizeStrategy}
              rowDragManaged
              rowHeight={36}
              allowContextMenuWithControlKey
              getContextMenuItems={getContextMenuItems}
              loadingOverlayComponent={LoadingOverlay}
              overlayNoRowsTemplate={'<span aria-live="polite" aria-atomic="true";pos>条件に合致する商品が見つかりません</span>'}
              suppressLastEmptyLineOnPaste
              onPasteStart={onPasteStart}
              onPasteEnd={onPasteEnd}
              reactiveCustomComponents
              onFilterChanged={onFilterChanged}
            />
          </div>
        </div>
      </div>

      
      <AppSnackbar
        message={snackBarMessage}
        open={snackBarOpen}
        onClose={onSnackbarClose}
        severity={snackBarSeverity}
      />
    </>
  );
}

export default DataList;
