import type { GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import type { GridRowId } from '@mui/x-data-grid-premium'
import type { Daylist, LoginUser, MstItem, Stock, StockUpdateBase } from 'api-client'
import type { User } from 'firebase/auth'

export interface DataListStatus {
  listCenterId?: number | null
  sortModel: GridSortModel
  paginationModel: GridPaginationModel
}
export const initDataListStatus = (): DataListStatus => ({
  sortModel: [],
  paginationModel: {
    pageSize: 100,
    page: 0,
  },
})

export interface ErrorResponse {
  response: Response
}

export type LoginUserWithMfa = LoginUser & {
  isMfaEnabled: boolean
  mfaTel: string | null
}

export type MfaUser = User & {
  reloadUserInfo?: ReloadUserInfo
}

export type ReloadUserInfo = {
  mfaInfo?: MfaInfo[]
}

export type MfaInfo = {
  phoneInfo: string
}

export interface DataListStockUpdate extends StockUpdateBase {
  id: GridRowId
  oldArrivalQuantityPlan?: number
  oldShipQuantityPlan?: number
}

export interface DataListItemUpdate extends MstItem {
  id: string
}
