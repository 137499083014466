import React, { useCallback, useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { useAuthContext } from 'AuthProvider';
import { MasterSearchFrom } from 'components/commonparts';
import { SearchCondition, DataList } from 'components/parts/MasterStockCenterItem';
import { DownloadGrid } from 'components/parts/MasterStockCenterItem/DataList/DownloadGrid';
import { useCenter } from 'hooks/useCenter';
import { useCenterStockItem } from 'hooks/useCenterStockItem';
import { useItem } from 'hooks/useItems';
import { useSupplier } from 'hooks/useSupplier';
import { useTypecode } from 'hooks/useTypecode';
import { useSearchParamStore } from 'store/searchParamStore';
import { initDataListStatus, type DataListStatus } from 'types/types';
import { getLocalSearchParam } from 'utils/getLocalSearchParam';
import { sortToRequestParam } from 'utils/sortToRequestParam';

import type { GetMstCenterStockItemCenterStockItemGetRequest, MstCenterStockItem, ResponseResult } from 'api-client';

function MasterStockCenterItem() {
  const { loginUserInfo } = useAuthContext()
  const trCompanyId = loginUserInfo?.companyId;
  const setSearchParamState = useSearchParamStore((state) => state.setSearchParamState)
  const searchParamState = useSearchParamStore((state) => state.searchParamState)

  const localSearchParam = getLocalSearchParam(searchParamState, loginUserInfo?.tenantId, loginUserInfo?.companyId)

  const localCenterId = localSearchParam?.centerStockItem.centerId || undefined
  const localOrgItemCode = localSearchParam?.centerStockItem.orgItemCode || undefined

  // 自社のcompanyIdのみ検索条件をローカルストレージから復元
  const initSearchParam: GetMstCenterStockItemCenterStockItemGetRequest = {
    companyId: trCompanyId ?? loginUserInfo!.companyId,
    centerId: localCenterId,
    orgItemCode: localOrgItemCode,
  }

  const [hasGridEdited, setHasGridEdited] = useState<boolean>(false)
  const [downloadGridRows, setDownloadGridRows] = useState<MstCenterStockItem[]>([])
  const [downloadMode, setDownloadMode] = useState<string>('csv')

  const [resultMessage, setResultMessage] = useState<ResponseResult | undefined>(undefined)
  const [gridMessage, setGridMessage] = useState<ResponseResult | undefined>(undefined)

  const { fetchCenterStockItemSearch, centerstockitemSearchIsLoading, centerstockitemSearch, centerstockitemSearchResult } = useCenterStockItem()
  const { fetchCenterSearch, centerSearch } = useCenter()
  const { fetchItems, items } = useItem()
  const { fetchSupplierSearch, supplierSearch } = useSupplier()
  const { fetchTypeCode, typeCode } = useTypecode()
  const [searchParam, setSearchParam] = useState<GetMstCenterStockItemCenterStockItemGetRequest>(initSearchParam)
  const [listStatus, setListStatus] = useState<DataListStatus>(initDataListStatus)
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const [onReset, setOnReset] = useState<boolean>(false)
  const [isRegister, setRegister] = useState<boolean>(false)
  const [uploadData, setUploadData] = useState<any[]>([])
  const [searchTrigger, setSearchTrigger] = useState<boolean>(false);
  const [discardChangeDialogOpen, setDiscardChangeDialogOpen] = useState<boolean>(false)


  useEffect(() => {
    setResultMessage(centerstockitemSearchResult)
  }, [centerstockitemSearchResult])

  useEffect(() => {
    setResultMessage(gridMessage)
  }, [gridMessage])

  useEffect(() => {
    setSearchParam(searchParam)
  }, [searchParam])

  const doSearch = useCallback(async (requestParam: GetMstCenterStockItemCenterStockItemGetRequest) => {
    try {
        fetchCenterStockItemSearch({
          ...requestParam,
          pageNo: 0,
          sort: sortToRequestParam(listStatus.sortModel),
        });
        if (requestParam.companyId === loginUserInfo!.companyId && loginUserInfo?.tenantId && loginUserInfo?.companyId) {
          setSearchParamState<GetMstCenterStockItemCenterStockItemGetRequest>(
            loginUserInfo?.tenantId,
            loginUserInfo?.companyId,
            'CenterStockItem',
            { 
              ...requestParam,
              sort: sortToRequestParam(listStatus.sortModel),
            }
          );
        }
  
        setSearchParam(requestParam);
        setListStatus({
          ...listStatus,
        });
        setOnReset(true); 
        setHasGridEdited(false)
      } catch (error) {
        console.error("Error fetching data:", error);
      } 
  }, [
    hasGridEdited,
      setDiscardChangeDialogOpen,
      fetchCenterStockItemSearch,
      listStatus.sortModel,
      loginUserInfo,
      setSearchParamState,
      setSearchParam,
      setListStatus,
      setOnReset,
      setHasGridEdited
  ]);

  const handleRegister = () => {
    if (!isRegister) { 
      setRegister(true);
    }
  }

  const handleUploadData = async (headers: any[], data: any[]) => {
    // エクセルファイルからデータをインポートし、ヘッダーとデータ行に分けて渡される

    const mappedData = data.map((row) => {
      const obj: any = {};

      headers.forEach((header, index) => {
        obj[header] = row[index];
        // let value = row[index];

        // if (header === 'start_date') {
        //   if(typeof value === 'number') {
        //     value = excelSerialToDate(value)?.toString();
        //   } else {
        //     const parsedDate = normalizeDate(value);
        //     value = parsedDate ? parsedDate.toString() : null;
        //   }
        // }

        // if (
        //   header === 'org_center_id' ||
        //   header === 'center_id' ||
        //   header === 'item_code' || 
        //   header === 'org_item_code'||
        //   header === 'stock_days_rank' || 
        //   header === 'safety_stock_rank'||
        //   header === 'org_supplier_id'||
        //   header === 'order_unit_type'
        // ) {
        //   value = value ? String(value).trim() : null;
        // } 

        // if ( header === 'safety_stock_setting' ) {
        //   if (typeof value !== 'number') {
        //     value = null;
        //   } else if (Number.isInteger(value) && value < 0) {
        //     value = null;
        //   } else if (!Number.isInteger(value)) {
        //     value = Math.round(value * 1000) / 1000;
        //   }
        // } 

        // if (
        //   header === 'stock_days_setting' ||
        //   header === 'min_order_quantity' || 
        //   header === 'case_lot'           ||
        //   header === 'min_stock_quantity'
        // ) {
        //   if (typeof value !== 'number') {
        //     value = null;
        //   } else if (!Number.isInteger(value)) {
        //     value = Math.floor(value);
        //   }
        // }

        // if (header === 'deleteFlg') {
        //   value = value != null ? value : false;
        // }
        
        // obj[header] = value;
      });
      return obj;
    });
    setUploadData(mappedData); 
  };

  const onReload = () => {
    // 検索処理の実行を行うトリガー
    setSearchTrigger((prev) => !prev);
  }

  useEffect(() => {
    const firstTimeRender = async () => { 
      if (isFirstRender) {

        const itemsCenterStockPromise = fetchCenterStockItemSearch({
          ...searchParam,
        });
        const itemsPromise = fetchItems({
          companyId: searchParam.companyId, 
        });
        const centerPromise = fetchCenterSearch({
          companyId: searchParam.companyId, 
        });
        const supplierPromise = fetchSupplierSearch({
          companyId: searchParam.companyId, 
        });
        const typePromise = fetchTypeCode();
        await Promise.all([itemsCenterStockPromise, itemsPromise, centerPromise, supplierPromise, typePromise]);
        setIsFirstRender(false)
      }
    }
    firstTimeRender()
  }, [])


  const generateDownloadData = (): MstCenterStockItem[] => {
    if (!centerstockitemSearch?.list && centerstockitemSearch.list.length === 0) {
      return [{} as MstCenterStockItem]
    }
    const downloadData: MstCenterStockItem[] = [...centerstockitemSearch.list]
    return downloadData
  }

  const handleFileDownload = () => {
    setDownloadGridRows(generateDownloadData())
  }

  return (
    <Box>
      <Typography component="div" variant="largeBold">
        センター在庫管理
      </Typography>
      <MasterSearchFrom
        result={resultMessage}
        onDoUpdate={handleRegister}
        onReload={onReload}
        onImportData={handleUploadData}
        onFileDownLoad={handleFileDownload}
        discardChangeDialogOpen={discardChangeDialogOpen}
        setDownloadMode={setDownloadMode}
        hasDataEdited={hasGridEdited}
        importRequiredFields={["org_center_id"]}
        headerJp={["自社センター"]}
        lastUpdateDatetime={null}
      >
        <SearchCondition
            hasDataEdited={hasGridEdited}
            searchParam={initSearchParam}
            onSearch={doSearch}
            setDiscardChangeDialogOpen={setDiscardChangeDialogOpen}
            searchTrigger={searchTrigger}
        />
      </MasterSearchFrom>
      <DownloadGrid downloadData={downloadGridRows} downloadMode={downloadMode} />
      <DataList
          list={centerstockitemSearch}
          items={items.list}
          center={centerSearch.list}
          supplier={supplierSearch.list}
          systype={typeCode}
          onReload={onReload}
          onReset={onReset}
          isLoading={centerstockitemSearchIsLoading}
          searchParam={searchParam}
          onSearch={doSearch}
          setOnReset={setOnReset}
          onTriggerAction={isRegister}
          onUploadData={uploadData}
          setOnTriggerAction={setRegister}
          setHasGridEdited={setHasGridEdited}
          setResultMessage={setGridMessage}
        />
    </Box>
          
  )
}

export default MasterStockCenterItem;
