import { useEffect, useState, useCallback, type Dispatch, type SetStateAction, type PropsWithChildren, type default as React } from 'react';

import { AuthType } from 'constants/constants';

import { Box, Grid, Button, Paper,Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,Typography } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { ActionButton, CanselButton,FileImportData } from 'components/commonparts';
import { convertFromDateTime } from 'utils/convertFromLocalDate'

import DownloadCsvButton from '../Buttons/DownloadCsvButton'
import DownloadExcelButton from '../Buttons/DownloadExcelButton'
import MessageInfo from '../MessageInfo/MessageInfo'

import type { ResponseResult } from 'api-client'

type MasterSearchFromProps = {
  onFileDownLoad?: () => void  // ファイルダウンロード（CSV・Excel）ボタン押下
  onDoUpdate?: () => void  // 更新ボタン押下
  onReload?: () => void  // 初期化ボタン押下（編集中の場合の確認後、OKならイベントが発火するので、画面側ではチェック不要）
  onImportData: (header: any[], data: any[]) => Promise<void>;   // ファイルインポート後、引数にヘッダーとデータが来る
  isDownload?: boolean  // ダウンロードボタンを出すか出さないか。デフォルトで出すにしてあるので画面側では設定しなくてＯＫ
  result?: ResponseResult | undefined // 検索エリアのメッセージ表示エリアの設定
  discardChangeDialogOpen?:boolean  // 編集中に検索やリセットをするか確認するダイアログを表示する
  forbiddenDownloadDialogOpen?:boolean
  hasDataEdited: boolean  // 編集中のデータがあるか？
  setDownloadMode: Dispatch<SetStateAction<string>> // ＣＳＶかExcelか、どちらのダウンロードボタンを押したのか
  importRequiredFields: string[]; // Excelファイルインポートの際に、プライマリキーの必須項目チェックをする
  headerJp?: string[];
  lastUpdateDatetime: Date | null // 検索データの最新更新時間
}

function MasterSearchFrom({
  onFileDownLoad,
  onDoUpdate,
  onReload,
  onImportData,
  isDownload = true,
  result,
  children,
  discardChangeDialogOpen = false,
  forbiddenDownloadDialogOpen = false,
  hasDataEdited = false,
  setDownloadMode,
  importRequiredFields=[],
  headerJp=[],
  lastUpdateDatetime,
}: PropsWithChildren<MasterSearchFromProps>) {
  const { loginUserInfo } = useAuthContext()

  const [shouldResetUploadButton, setShouldResetUploadButton] = useState<boolean>(false);
  const [showDiscardChangeDialog, setShowDiscardChangeDialog] = useState<boolean>(false)
  const [showForbiddenDownloadDialog, setForbiddenDownloadDialog] = useState<boolean>(false)
  const [resultMessage, setResultMessage] = useState<ResponseResult | undefined>(undefined)

  useEffect(() => {
    setResultMessage(result)
  }, [result])

  useEffect(() => {
    if (hasDataEdited)
      setShowDiscardChangeDialog(true)
  }, [discardChangeDialogOpen])

  useEffect(() => {
    setForbiddenDownloadDialog(forbiddenDownloadDialogOpen)
  }, [forbiddenDownloadDialogOpen])

  useEffect(() => {
  }, [showDiscardChangeDialog])
  

  const handleReload = () => {
    setShowDiscardChangeDialog(false)
    setShouldResetUploadButton((prev) => !prev); 
    onReload?.();
  };
  const handleCanselButtonClick = () => {
    if (hasDataEdited) {
      setShowDiscardChangeDialog(true)
      return
    } 
    handleReload();
  };

  const handleCsvDownLoad = () => {
    if (hasDataEdited) {
      setForbiddenDownloadDialog(true)
      return
    } 
    setResultMessage(undefined)
    setDownloadMode('csv')
    onFileDownLoad?.();
    setShouldResetUploadButton((prev) => !prev); 
  };

  const handleExcelDownLoad = () => {
    if (hasDataEdited) {
      setForbiddenDownloadDialog(true)
      return
    }
    setDownloadMode('xlsx')
    setResultMessage(undefined)

    onFileDownLoad?.();
    setShouldResetUploadButton((prev) => !prev); 
  };
  

  const resetUploadButton = () => {
    setShouldResetUploadButton((prev) => !prev);
  };

  const handleDiscardChangeDialogClose = useCallback(() => {
    setShowDiscardChangeDialog(false)
  }, [])

  const handleForbiddenDownloadDialogClose = useCallback(() => {
    setForbiddenDownloadDialog(false)
  }, [])

  return (
    <Paper variant="outlined">
      <Grid item container>
        <Grid item xs={12} container>
          {children}
        </Grid>
        <Grid item xs={12} justifyContent="space-between" alignItems="center" container sx={{ marginTop: '0.5rem' }}>
          <Grid item>
            <MessageInfo result={resultMessage} />
          </Grid>

          <Grid item>
            <Box display="flex" alignItems="center" gap={0.5}>
              {loginUserInfo && (loginUserInfo?.authType !== AuthType.ReadOnly) && (
                <>
                  <ActionButton 
                    onClick={() => { 
                      onDoUpdate?.(); 
                    }} 
                    disabled={false} 
                  />
                  <CanselButton
                    onClick={handleCanselButtonClick}
                    caption="初期化"
                    disabled={false}
                  />
                </>
              )}
              
              {isDownload && (
                <>
                  <DownloadCsvButton onClick={handleCsvDownLoad} />
                  <DownloadExcelButton onClick={handleExcelDownLoad} />
                </>
              )}
                        
              <FileImportData 
                onImportData={onImportData} 
                shouldReset={shouldResetUploadButton}
                resetCallback={resetUploadButton}
                requiredFields={importRequiredFields}
                headerJp={headerJp}
                inputWidth='170px' 
                placeholder='ファイルから登録'
                setResultMessage={setResultMessage}
              />

              <Typography variant="medium" justifyContent="flex-end" display="flex" color="royalblue" marginRight='10px'>
                { 
                lastUpdateDatetime !=null && `最終更新日: ${convertFromDateTime(lastUpdateDatetime)}`
                }
              </Typography>
              
            </Box>

          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={showDiscardChangeDialog}
        onClose={handleDiscardChangeDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">変更破棄の確認</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            編集中の内容があります。変更を破棄して検索しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscardChangeDialogClose}>キャンセル</Button>
          <Button variant="contained" onClick={handleReload} autoFocus>
            破棄して検索
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={showForbiddenDownloadDialog}
        onClose={handleForbiddenDownloadDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ダウンロード</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            データを編集中はダウンロードできません。内容をクリアするか反映してから実行してください
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleForbiddenDownloadDialogClose}>OK</Button>
        </DialogActions>
      </Dialog>

    </Paper>
  )
}

export default MasterSearchFrom
