import { useEffect, useCallback, useState, type default as React } from 'react';

import { Box, Typography } from '@mui/material';
import { useAuthContext } from 'AuthProvider';
import { MasterSearchFrom } from 'components/commonparts'
import { SearchCondition, DataList } from 'components/parts/MasterItem';
import { DownloadGrid } from 'components/parts/MasterItem/DataList/DownloadGrid';
import { useItem } from 'hooks/useItems';
import { useSupplier } from 'hooks/useSupplier';
import { useTypecode } from 'hooks/useTypecode';
import { useSearchParamStore } from 'store/searchParamStore';
import { initDataListStatus, type DataListStatus } from 'types/types';
import { getLocalSearchParam } from 'utils/getLocalSearchParam';
import { sortToRequestParam } from 'utils/sortToRequestParam';

import type { GetMstItemsItemGetRequest, MstItem, ResponseResult } from 'api-client';

function MasterItem() {
  const { loginUserInfo } = useAuthContext()
  const trCompanyId = loginUserInfo?.companyId;
  const setSearchParamState = useSearchParamStore((state) => state.setSearchParamState)
  const searchParamState = useSearchParamStore((state) => state.searchParamState)

  const localSearchParam = getLocalSearchParam(searchParamState, loginUserInfo?.tenantId, loginUserInfo?.companyId)

  const localOrgSupplierId = localSearchParam?.item.orgSupplierId || ''

  // 自社のcompanyIdのみ検索条件をローカルストレージから復元
  const initSearchParam: GetMstItemsItemGetRequest = {
    companyId: trCompanyId ?? loginUserInfo!.companyId,
    orgSupplierId: localOrgSupplierId,
  }

  const [hasGridEdited, setHasGridEdited] = useState<boolean>(false)
  const [downloadGridRows, setDownloadGridRows] = useState<MstItem[]>([])
  const [downloadMode, setDownloadMode] = useState<string>('csv')
  const [resultMessage, setResultMessage] = useState<ResponseResult | undefined>(undefined)
  const [gridMessage, setGridMessage] = useState<ResponseResult | undefined>(undefined)

  const { result, items, fetchItems, isLoading, } = useItem()
  const { supplierSearch, fetchSupplierSearch } = useSupplier()
  const { typeCode, fetchTypeCode } = useTypecode()
  const [searchParam, setSearchParam] = useState<GetMstItemsItemGetRequest>(initSearchParam)
  const [listStatus, setListStatus] = useState<DataListStatus>(initDataListStatus)
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const [onReset, setOnReset] = useState<boolean>(false)
  const [isRegister, setRegister] = useState<boolean>(false)
  const [uploadData, setUploadData] = useState<any[]>([])
  const [searchTrigger, setSearchTrigger] = useState<boolean>(false);
  const [discardChangeDialogOpen, setDiscardChangeDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    setResultMessage(result)
  }, [result])

  useEffect(() => {
    setResultMessage(gridMessage)
  }, [gridMessage])

  const doSearch = useCallback(async (requestParam: GetMstItemsItemGetRequest) => {

    try {
        fetchItems({
          ...requestParam,
          pageNo: 0,
        });
        if (requestParam.companyId === loginUserInfo!.companyId && loginUserInfo?.tenantId && loginUserInfo?.companyId) {
          setSearchParamState<GetMstItemsItemGetRequest>(
            loginUserInfo?.tenantId,
            loginUserInfo?.companyId,
            'Item',
            {
              ...requestParam,
              sort: sortToRequestParam(listStatus.sortModel),
            }
          );
        }
  
        setSearchParam(requestParam);
        setListStatus({
          ...listStatus,
        });
        setOnReset(true); 
        setHasGridEdited(false)


      } catch (error) {
        console.error("Error fetching data:", error);
      } 
  }, [items]);

  const handleRegister = () => {
    if (!isRegister) { 
      setRegister(true);
    }
  }

  const handleUploadData = async (headers: any[], data: any[]) => {
    // エクセルファイルからデータをインポートし、ヘッダーとデータ行に分けて渡される

    const mappedData = data.map((row) => {
      const obj: any = {};
      // todo:後でどうにかする
      headers.forEach((header, index) => {
        obj[header] = row[index];
        // let value = row[index];

        // if (header === 'sales_startdate' || header === 'sales_enddate') {
        //   if(typeof value === 'number') {
        //     value = excelSerialToDate(value)?.toString();
        //   } else {
        //     const parsedDate = normalizeDate(value);
        //     value = parsedDate ? parsedDate.toString() : null;
        //   }
        // }

        // if (
        //   header === 'item_name' ||
        //   header === 'item_code' || 
        //   header === 'org_item_code'||
        //   header === 'spec' || 
        //   header === 'jan'||
        //   header === 'jan_case' || 
        //   header === 'gtin_13'||
        //   header === 'gtin_14' || 
        //   header === 'itf'||
        //   header === 'sdp' || 
        //   header === 'replace_org_item_code'||
        //   header === 'maker_gln' || 
        //   header === 'maker_name'||
        //   header === 'org_supplier_id'||
        //   header === 'order_unit_type'
        // ) {
        //   value = value ? String(value).trim() : null;
        // } 

        // if (
        //   header === 'case_lot' ||
        //   header === 'min_order_quantity' ||
        //   header === 'max_pallet_quantity' ||
        //   header === 'pallet_quantity_face' || 
        //   header === 'pallet_quantity_tier'
        // ) {
        //   if (typeof value !== 'number') {
        //     value = null;
        //   } else if (!Number.isInteger(value)) {
        //     value = Math.floor(value);
        //   }
        //   if (value < 0) {
        //     value = null;
        //   }
        // }

        // if (
        //   header === 'longside' ||
        //   header === 'shortside' || 
        //   header === 'weight' ||
        //   header === 'height'
        // ) {
        //   if (typeof value !== 'number') {
        //     value = null;
        //   } else if (Number.isInteger(value) && value < 0) {
        //     value = null;
        //   } else if (!Number.isInteger(value)) {
        //     value = Math.round(value * 100) / 100;
        //   }
        // }

        // if (header === 'deleteFlg') {
        //   value = value != null ? value : false;
        // }
         
        // obj[header] = value;
      });
      return obj;
    });
    
    
    setUploadData(mappedData); 
  };

  const onReload = () => {
    // 検索処理の実行を行うトリガー
    setSearchTrigger((prev) => !prev);
  }

  const firstTimeRender = async () => { 
    if (isFirstRender) {
      setIsFirstRender(false)
      const itemsPromise = fetchItems({
        ...searchParam,
      });

      const supplierPromise = fetchSupplierSearch({
        companyId: searchParam.companyId, 
      });
      const typeCodePromise = fetchTypeCode();
      await Promise.all([itemsPromise, supplierPromise, typeCodePromise]);

    }
}

  useEffect(() => {
    firstTimeRender()
  }, [])

  const generateDownloadData = (): MstItem[] => {
    if (!items?.list || items.list.length === 0) {
      return [{} as MstItem]
    }
    const downloadData: MstItem[] = [...items.list]
    return downloadData
  }

  const handleFileDownload = () => {
    setDownloadGridRows(generateDownloadData())
  }


  return (
    <Box>
      <Typography component="div" variant="largeBold">
        商品マスタ
      </Typography>
      <MasterSearchFrom
        result={resultMessage}
        onDoUpdate={handleRegister}
        onReload={onReload}
        onImportData={handleUploadData}
        onFileDownLoad={handleFileDownload}
        discardChangeDialogOpen={discardChangeDialogOpen}
        setDownloadMode={setDownloadMode}
        hasDataEdited={hasGridEdited}
        importRequiredFields={["org_item_code"]}
        headerJp={["社内商品コード"]}
        lastUpdateDatetime={null}
      >
        <SearchCondition
            hasDataEdited={hasGridEdited}
            searchParam={initSearchParam}
            onSearch={doSearch}
            setDiscardChangeDialogOpen={setDiscardChangeDialogOpen}
            searchTrigger={searchTrigger}
        />
      </MasterSearchFrom>

      <DownloadGrid downloadData={downloadGridRows} downloadMode={downloadMode} />
      <DataList
          list={items}
          onReload={onReload}
          onReset={onReset}
          supplier={supplierSearch.list}
          systype={typeCode}
          isLoading={isLoading}
          searchParam={initSearchParam}
          onSearch={doSearch}
          setOnReset={setOnReset}
          onTriggerAction={isRegister}
          onUploadData={uploadData}
          setOnTriggerAction={setRegister}
          setHasGridEdited={setHasGridEdited}
          setResultMessage={setGridMessage}
        />
    </Box>
          
  )
}

export default MasterItem;
