import React, { useCallback, useEffect, useMemo, useRef, useState, type Dispatch, type SetStateAction } from 'react';

import { useAuthContext } from 'AuthProvider';
import { type CellValueChangedEvent, type ValueParserParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react';
import AppSnackbar from 'components/commonparts/AppSnackbar/AppSnackbar';
import GridTooltip from 'components/commonparts/GridTooltip/GridTooltip';
import LoadingOverlay from 'components/commonparts/LoadingOverlay/LoadingOverlay';
import { useItem } from 'hooks/useItems';
import { AG_GRID_LOCALE } from 'utils/agGridLocale';
import * as agGridUtil from 'utils/agGridUtil';
import { convertDateToRequestParam } from 'utils/convertDateToRequestParam';
import { isEqualDate } from 'utils/convertFromLocalDate'
import { validate4to13Length, validate4to14Length, validate6to10Length, validateRequired } from 'utils/validation';

import type {
    ColDef,
    ColumnMenuTab,
    GetContextMenuItemsParams,
    IMenuActionParams,
    IRichCellEditorParams,
    MenuItemDef,
    SizeColumnsToContentStrategy,
    SizeColumnsToFitGridStrategy,
    SizeColumnsToFitProvidedWidthStrategy,
    ValueFormatterParams,
  } from 'ag-grid-enterprise'
  import 'ag-grid-enterprise'
  import 'ag-grid-community/styles/ag-grid.css'
  import 'ag-grid-community/styles/ag-theme-quartz.css'
import type { Item, MstItem, MstSupplier, Groups, GetMstItemsItemGetRequest, ResponseResult,Codes } from 'api-client';
import 'utils/mst.scss'

const columnMapping: Record<string, string> = {
    "item_code": "itemCode",
    "org_item_code": "orgItemCode",
    "item_name": "itemName",
    "sales_startdate": "salesStartdate",
    "sales_enddate": "salesEnddate",
    "longside": "longside",
    "shortside": "shortside",
    "height": "height",
    "weight": "weight",
    "spec": "spec",
    "max_pallet_quantity": "maxPalletQuantity",
    "pallet_quantity_face": "palletQuantityFace",
    "pallet_quantity_tier": "palletQuantityTier",
    "jan": "jan",
    "jan_case": "janCase",
    "gtin_13": "gtin13",
    "gtin_14": "gtin14",
    "itf": "itf",
    "sdp": "sdp",
    "replace_org_item_code": "replaceOrgItemCode",
    "maker_gln": "makerGln",
    "maker_name": "makerName",
    "org_supplier_id": "orgSupplierId",
    "order_unit_type": "orderUnitType",
    "case_lot": "caseLot",
    "min_order_quantity": "minOrderQuantity",
    "delete_flg": "deleteFlg"
};

interface DataListProps {
  list: Item;
  isLoading: boolean;
  supplier: MstSupplier[];
  systype: Groups[];
  onReload: () => void;
  onUploadData: any[];
  onReset: boolean;
  searchParam: GetMstItemsItemGetRequest
  onSearch: (requestParam: GetMstItemsItemGetRequest) => void;
  setOnReset: (value: boolean) => void;
  onTriggerAction: boolean;
  setOnTriggerAction : (value: boolean) => void,
  setHasGridEdited: (value: boolean) => void,
  setResultMessage: Dispatch<SetStateAction<ResponseResult | undefined>>
}

interface MstItemWithId extends MstItem, agGridUtil.RecordEditEx {}


function DataList({ 
  list, 
  isLoading, 
  supplier, 
  systype, 
  onReset, 
  searchParam, 
  onSearch, 
  setOnReset,
  onReload, 
  onTriggerAction, 
  setOnTriggerAction, 
  onUploadData,
  setHasGridEdited,
  setResultMessage,
}: DataListProps) {
  const { loginUserInfo } = useAuthContext()
  const {result, fetchUpdateItem, isUpdating} = useItem()
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarSeverity, setSnackBarSeverity] = useState<'error' | 'warning' | 'info' | 'success'>('success')
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [editedRows, setEditedRows] = useState<MstItemWithId[]>([]);
  const gridRef = useRef<AgGridReact<MstItemWithId>>(null)
  const updatedRowsRef = useRef<any[]>([]);

  const localeText = useMemo<{
    [key: string]: string
  }>(() => AG_GRID_LOCALE, [])

  const autoSizeStrategy = useMemo<
  | SizeColumnsToFitGridStrategy
  | SizeColumnsToFitProvidedWidthStrategy
  | SizeColumnsToContentStrategy
    >(() => ({ type: "fitCellContents" }), []);
  const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 300px)' }), [])
  const gridStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 300px)' }), [])
  const [row, setRow] = useState<MstItemWithId[]>(list.list.map(item => ({ ...item, ...agGridUtil.initRecordEditEx()})))
  const [editorDataReady, setEditorDataReady] = useState(false);
  const pendingChanges = useRef<{ node: any; updatedRow: MstItemWithId }[]>([]);
  const isPasting = useRef(false)
  const [supplierSelect, setSupplierSelect] = useState<(string | null)[]>([])
  const [itemSelect, setItemSelect] = useState<(string | null)[]>([])
  const [orderUnitTypeSelect, setOrderUnitTypeSelect] = useState<(string | null)[]>([])
  const [orderUnitTypeList, setOrderUnitTypeList] = useState<Codes[]>([])
  
  useEffect(() => {
    setResultMessage(result)
  }, [result])
  useEffect(() => {
    if (itemSelect.length > 0 && orderUnitTypeSelect.length > 0 && supplierSelect.length > 0) {
      setEditorDataReady(true);
    }
  }, [itemSelect, orderUnitTypeSelect, supplierSelect]);

  useEffect(() => {
    const values = supplier?.map((r) => r.orgSupplierId) || []
    setSupplierSelect(values)
  }, [supplier]);
  useEffect(() => {
    const codelist = systype.find(r => r.typeGroup === 'order_unit_type')?.codes
    if (codelist){
      setOrderUnitTypeList(codelist)
      const values = codelist.map((r) => r.typeCode) || []
      setOrderUnitTypeSelect(values)    
    }
  }, [systype]);  
  useEffect(() => {
    // console.debug("useEffect row", row)
    setEditorDataReady(false)
    const values = row?.map((r) => r.orgItemCode) || []
    setItemSelect([...values])
  }, [row]);

  useEffect(() => {
    if (onReset) {
      setEditedRows([]); 
      setOnReset(false);
    }
  }, [onReset, setOnReset]);

  const onPasteStart = useCallback(() => {
    isPasting.current = true;
    pendingChanges.current = []; 
  }, []);
  
  const colDataValidate = (pRow : MstItemWithId
    , field: string | undefined, newValue:any):boolean => {
    if (!field) return false;
    let hasErrors = false
    const trRow = pRow
    // console.log("colDataValidate", field, trRow)
    const checkAndSetError = (f: string, error: string | null) :boolean => {
      if (error) {
        trRow.errors[f] = [error];
        trRow.editedFields.add(f);
        hasErrors = true;
      } else if (trRow.errors && trRow.errors[f]) {
        delete trRow.errors[f]
      }
      return hasErrors
    };

    checkAndSetError(field, "");
    switch (field){
      case "itemName":
      case "orgSupplierId":
        checkAndSetError(field, validateRequired(newValue));
        break;

      case "orgItemCode":{
        const err = checkAndSetError(field, validateRequired(newValue));

        // 重複チェック
        if (!err){
          const gridApi = gridRef.current?.api;
          gridApi?.forEachNode((rowNode) => {
            if (rowNode?.data && rowNode.data?.orgItemCode === newValue && rowNode.data?.id !== trRow.id) {
              checkAndSetError(field, '入力値はすでに登録されています')
            }
          })
        }
        break;
      }

      case "salesStartdate":
      case "salesEnddate":{
        const startDate = field === 'salesStartdate' ? agGridUtil.parseDate(newValue) : trRow.salesStartdate || null;
        const endDate = field === 'salesEnddate' ? agGridUtil.parseDate(newValue) : trRow.salesEnddate || null;
        // console.log("startDate err", field, startDate, endDate)
        if (startDate && endDate && startDate > endDate) {
          checkAndSetError(field, "は発売日より後の日付を指定してください。");
        } else {
          checkAndSetError("salesStartdate", "");
          checkAndSetError("salesEnddate", "");
        }
        break;
      }

      case "janCase": {
        const err = checkAndSetError(field, validateRequired(newValue)) || 
        checkAndSetError(field, validate4to14Length(newValue).join(','))
        break;
      }
      case "gtin13":
      case "gtin14":
      case "itf":
      case "sdp":
        checkAndSetError(field, validate4to14Length(newValue).join(','))
        break;
      case "makerGln":
        checkAndSetError(field, validate6to10Length(newValue).join(','))
        break;
      case "jan":
        checkAndSetError(field, validate4to13Length(newValue).join(','))
        break;
      default:
        return false;
    }
    // console.debug("***colDataValidate ", hasErrors, field, newValue)
    return hasErrors

  };

  // 行に含まれる項目に入力チェックを実施
  const rowDataValidate = (pRow:MstItemWithId):boolean => {
    // console.debug("***rowDataValidate ", pRow)
    if (!pRow) return false;
    const trRow = pRow
    let hasErrors = false
    trRow.errors = {};
    Object.keys(columnMapping).forEach((key) => {
      const fieldValue = trRow[columnMapping[key] as keyof typeof trRow];
      colDataValidate(trRow, columnMapping[key], fieldValue)
    });

    if (trRow.errors && Object.keys(trRow.errors).length > 0) {
      // console.debug("***rowDataValidate hasErrors", trRow)
      hasErrors = true;
    }
    return hasErrors
  }  

  const onCellValueChanged = useCallback((event: CellValueChangedEvent<MstItemWithId>) => {
    setResultMessage(undefined)
    const { data, colDef, newValue, oldValue, node } = event;
    const { field } = colDef;
    // console.debug("onCellValueChanged", field)

    const updatedRow = data

    // console.log("onCellValueChanged ", updatedRow)
    // 入力チェック
    const haserror = colDataValidate(updatedRow, field, newValue)
    
    if (newValue !== oldValue){
      updatedRow.isEdited = true;
      updatedRow.editedFields.add(field as string);
      // console.log("onCellValueChanged change")
    }
    // 値の変更時に、APIのエラーを解消
    switch (field){
      case "replaceOrgItemCode":
      case "orgSupplierId":
      case "orgItemCode":
        colDataValidate(updatedRow, "orgItemCode", updatedRow.orgItemCode);
        break;
      default:
    }

    if (isPasting.current) {
      let pendingRow = pendingChanges.current.find((change) => change.updatedRow.id === data.id);
      // console.log("isPasting.current", pendingRow)
      if (!pendingRow) {
        pendingRow = {
          node,
          updatedRow: { ...updatedRow },
        };
        pendingChanges.current.push(pendingRow);
      } else {
        const trRow = pendingRow.updatedRow as MstItemWithId
        trRow[field as keyof MstItem] = newValue;
        trRow.isEdited = true;
        trRow.editedFields.add(field as string);
        colDataValidate(trRow, field, newValue)
      }
  
      node.setData(pendingRow.updatedRow);
    } else {

      setEditedRows((prevEditedRows) => {
        const rowIndex = prevEditedRows.findIndex((rowa) => rowa.id === updatedRow.id);
        if (rowIndex !== -1) {
          const newEditedRows = [...prevEditedRows];
          newEditedRows[rowIndex] = updatedRow;
          return newEditedRows;
        }
        return [...prevEditedRows, updatedRow];
      });
  
      node.setData(updatedRow);
    }

  }, [row]);

  const onPasteEnd = useCallback(() => {
    isPasting.current = false; 
  
    setEditedRows((prevEditedRows) => {
      const newEditedRows = [...prevEditedRows];
      pendingChanges.current.forEach(({ node, updatedRow }) => {
        const rowIndex = newEditedRows.findIndex((rowb) => rowb.id === updatedRow.id);
        if (rowIndex !== -1) {
          newEditedRows[rowIndex] = updatedRow;
        } else {
          newEditedRows.push(updatedRow);
        }
        node.setData(updatedRow);
      });
      return newEditedRows;
    });
  
    pendingChanges.current = [];
  }, []);

  const updateMstItem = useCallback( async () => {
      setResultMessage(undefined)
      const baseData = editedRows as unknown as Array<MstItemWithId>
      let hasErrors = false;
      
      const updatedRows = baseData.map((item) => {
        // 変更時にエラーチェックが行われているが、登録前に再度チェックを実施する
        const updatedRow = { ...item };
        hasErrors = rowDataValidate(updatedRow) || hasErrors
        const gridApi = gridRef.current?.api;
        const rowNode = gridApi?.getRowNode(item.id ?? '');
        if (rowNode) {
          rowNode.setData(updatedRow);
        }
        gridApi?.refreshCells({ force: true });
        return updatedRow
      });

      if (hasErrors) {
        setResultMessage({
          status: 'error',
          message: '入力エラーがあります',
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })           
        setOnTriggerAction(false);
        return;
      }
  
      updatedRowsRef.current = updatedRows;
      
      const gridApi = gridRef.current?.api;
      if (gridApi) {
        gridApi.applyTransaction({ update: updatedRows });
        gridApi.redrawRows();
      }

      if (baseData.length === 0 ) {
        setResultMessage({
          status: 'warning',
          message: '更新対象のデータがありません',
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })           
        setOnTriggerAction(false);
        return;
      }
  
      const datalist = baseData.map(({ isEdited, editedFields, salesStartdate, salesEnddate, errors, ...rest }) => ({
        ...rest,
        salesStartdate: salesStartdate ? convertDateToRequestParam(new Date(salesStartdate)) : null,
        salesEnddate: salesEnddate ? convertDateToRequestParam(new Date(salesEnddate)) : null,
      }));
      
      try {
        await fetchUpdateItem({
          masterItemUpdate: { body: datalist }
        })

        setSnackBarMessage('正常に更新されました');
        setSnackBarSeverity('success'); 
        setEditedRows([]);     
        onReload()
      } catch (err: any) {
        if (err?.errors && err.result?.status === "error") {
          if (gridApi) {
            err.errors.forEach((errrow: any) => {

              const {rowdata}=errrow
              const orgItemCode = (rowdata as { org_item_code: string | null }).org_item_code;

              const editedRowIndex = editedRows.find((rowdatas) => rowdatas.orgItemCode === orgItemCode); 
              if (editedRowIndex) {
                // UpdateAPIのErrorは、社内商品コードをエラー表記して、サーバーから取得したエラーを表示する
                const updatedRow = {
                  ...editedRowIndex,
                  errors: {
                    orgItemCode: [errrow.errorMessage], 
                  },
                };
                            
                setEditedRows((prev) =>
                  prev.map((editedRow) =>
                    editedRow.orgItemCode === orgItemCode ? updatedRow : editedRow
                  )
                );
                const rowNode = gridApi.getRowNode(updatedRow.id || '');
                if (rowNode) {
                  rowNode.setData(updatedRow);
                }
              }
            });
            gridApi.redrawRows();
          }
          setSnackBarMessage("");
          setSnackBarSeverity("error");
          setSnackBarOpen(true);
          return;
        }

      } finally {
        setSnackBarOpen(true);
        setOnTriggerAction(false)
      }
    }, [fetchUpdateItem, onSearch, editedRows, searchParam]);

  const getCellClassRules = () => ({
    'error-cell': (params: any) => {
      const rowData = params.data as MstItemWithId;
      const { field } = params.colDef;
      const hasError = Array.isArray(rowData.errors[field]) && rowData.errors[field]?.length > 0;
      return hasError || false; 
    },
    'current-updated-cell': (params: any) => {
      const rowData = params.data as MstItemWithId;
      const { field } = params.colDef;
      return rowData.isEdited && (!rowData.errors?.[field] || rowData.errors[field].length === 0) && rowData.editedFields?.has(field);
    },
    'editable-cell': (params: any) => {
      const { editable } = params.colDef;
      let iseditable = false
      if (typeof editable === 'function'){
        iseditable = editable(params)
      } else {
        iseditable = editable
      }
      return iseditable
    }
  });

  useEffect(() => {
    const gridApi = gridRef.current?.api;
    if (!gridApi) return;
  
    if (isLoading || isUpdating ) {
      gridApi.showLoadingOverlay();
    } else {
      gridApi.hideOverlay();
    }
  }, [isLoading, isUpdating, gridRef.current]);

  /**
   * カラム定義
   * @returns
   */
  const updateColumnDefs = (): (ColDef)[] => 
  [
    {
      headerName: '社内商品コード',
      field: 'orgItemCode', // 社内商品コード
      editable: (params) => {
        if (params.colDef.field) {
          const { insertDatetime } = params.data || {};
          return insertDatetime === null;
        }
        return false;
      },
      rowDrag: true,
      valueParser: agGridUtil.parseStringValue,
    },
    {
      headerName: '商品名称',
      field: 'itemName', // 商品名称
      editable: true,
      width: 350,
      valueParser: agGridUtil.parseStringValue,
    },
    {
      headerName: '発売日',
      field: 'salesStartdate', // 発売日
      editable: true,
      width: 200,
      minWidth: 200,
      maxWidth: 250,
      cellEditor: 'agDateCellEditor',
      cellEditorParams: {
        minYear: 1900, 
        maxYear: 2050,
      },
      valueFormatter: agGridUtil.dateFormatter,
      valueParser: agGridUtil.parseDateValue,
    },
    {
      headerName: '終了日',
      field: 'salesEnddate', // 終了日
      editable: true,
      width: 200,
      minWidth: 200,
      maxWidth: 250,
      cellEditor: 'agDateCellEditor',
      cellEditorParams: {
        minYear: 1900, 
        maxYear: 2050,
      },
      valueFormatter: agGridUtil.dateFormatter,
      valueParser: agGridUtil.parseDateValue,
    },
    {
      headerName: 'ケースサイズ 長手（mm）',
      field: 'longside',
      editable: true,
      cellClass: 'number-cell',
      valueFormatter: (params) => agGridUtil.formatDecimalValue(params, 2),
      cellEditor:'agNumberCellEditor',
      valueParser: (params) => agGridUtil.parseDecimalValue(params, 2)
    },
    {
      headerName: 'ケースサイズ 短手（mm）',
      field: 'shortside', // ケースサイズ 短手（mm）
      editable: true,
      cellClass:'number-cell',
      valueFormatter: (params) => agGridUtil.formatDecimalValue(params, 2),
      cellEditor:'agNumberCellEditor',
      valueParser: (params) => agGridUtil.parseDecimalValue(params, 2)
    },
    {
      headerName: 'ケースサイズ 高さ（mm）',
      field: 'height', // ケースサイズ 高さ（mm）
      editable: true,
      cellClass:'number-cell',
      valueFormatter: (params) => agGridUtil.formatDecimalValue(params, 2),
      cellEditor:'agNumberCellEditor',
      valueParser: (params) => agGridUtil.parseDecimalValue(params, 2)
    },
    {
      headerName: 'ケースサイズ 重量（g）',
      field: 'weight', // ケースサイズ 重量（g）
      editable: true,
      cellClass:'number-cell',
      valueFormatter: (params) => agGridUtil.formatDecimalValue(params, 2),
      cellEditor:'agNumberCellEditor',
      valueParser: (params) => agGridUtil.parseDecimalValue(params, 2)
    },
    {
      headerName: '規格',
      field: 'spec', // 規格
      editable: true,
      valueParser: agGridUtil.parseStringValue,
    },
    {
      headerName: 'パレット最大積付数',
      field: 'maxPalletQuantity', // パレット最大積付数
      editable: true,
      cellClass:'number-cell',
      valueFormatter: agGridUtil.numberValueFormatter,
      cellEditor:'agNumberCellEditor',
      valueParser: agGridUtil.numberValueParser,
    },
    {
      headerName: 'パレット積載数（面）',
      field: 'palletQuantityFace', // パレット積載数（面）
      editable: true,
      cellClass:'number-cell',
      valueFormatter: agGridUtil.numberValueFormatter,
      cellEditor:'agNumberCellEditor',
      valueParser: agGridUtil.numberValueParser,
    },
    {
      headerName: 'パレット積載数（段）',
      field: 'palletQuantityTier', // パレット積載数（段）
      editable: true,
      cellClass:'number-cell',
      valueFormatter: agGridUtil.numberValueFormatter,
      cellEditor:'agNumberCellEditor',
      valueParser: agGridUtil.numberValueParser,
    },
    {
      headerName: 'JANコード（バラ）',
      field: 'jan', // JANコード（バラ）
      editable: true,
      valueParser: agGridUtil.parseStringValue,
    },
    {
      headerName: 'JANコード（ケース）',
      field: 'janCase', // JANコード（ケース）
      editable: true,
      valueParser: agGridUtil.parseStringValue,
    },
    {
      headerName: 'GTINコード（バラ）',
      field: 'gtin13', // GTINコード（バラ）
      editable: true,
      valueParser: agGridUtil.parseStringValue,
    },
    {
      headerName: 'GTINコード（ケース）',
      field: 'gtin14', // GTINコード（ケース）
      editable: true,
      valueParser: agGridUtil.parseStringValue,
    },
    {
      headerName: 'ITFコード',
      field: 'itf', // ITFコード
      editable: true,
      valueParser: agGridUtil.parseStringValue,
    },
    {
      headerName: 'SDPコード',
      field: 'sdp', // SDPコード
      editable: true,
      valueParser: agGridUtil.parseStringValue,
    },
    {
      headerName: '後継社内商品コード',
      field: 'replaceOrgItemCode', // 後継社内商品コード
      editable: true,
      width: 350,
      cellEditor: 'agRichSelectCellEditor',
      valueFormatter: (params: ValueFormatterParams) => agGridUtil.selectValueFormatter(params.value, row, "orgItemCode", "itemName"),
      valueParser: (params: ValueParserParams) => agGridUtil.selectValueParser(params, row, "orgItemCode"),
      cellEditorParams: editorDataReady ? {
        values : [null, ...itemSelect],
        formatValue: (value: string) => agGridUtil.selectValueFormatter(value, row, "orgItemCode", "itemName"),
        searchType: "matchAny",
        allowTyping: true,
        filterList: true,
        highlightMatch: true,
      } as IRichCellEditorParams : {values: ['-']},
    },
    {
      headerName: 'メーカーGS1事業者コード',
      field: 'makerGln', // メーカーGS1事業者コード
      editable: true,
      valueParser: agGridUtil.parseStringValue,
    },
    {
      headerName: 'メーカー名',
      field: 'makerName', // メーカー名
      editable: true,
      valueParser: agGridUtil.parseStringValue,
    },
    {
      headerName: '仕入先ID',
      field: 'orgSupplierId', // 仕入先ID
      editable: true,
      width: 220,
      cellEditor: 'agRichSelectCellEditor',
      valueFormatter: (params: ValueFormatterParams) => agGridUtil.selectValueFormatter(params.value, supplier, "orgSupplierId", "supplierName"),
      valueParser: (params: ValueParserParams) => agGridUtil.selectValueParser(params, supplier, "orgSupplierId"),
      cellEditorParams: editorDataReady ? {
        values : [null, ...supplierSelect],
        formatValue: (value: string) => agGridUtil.selectValueFormatter(value, supplier, "orgSupplierId", "supplierName"),
        searchType: "match",
        allowTyping: true,
        filterList: true,
        highlightMatch: true,
      } as IRichCellEditorParams : {values: ['-']},
    },
    {
      headerName: '発注単位',
      field: 'orderUnitType', // 発注単位
      suppressHeaderMenuButton: false,
      editable: true,
      cellEditor: 'agRichSelectCellEditor',
      // 区分値は、ドロップダウン後のリストはコード＋名称で、表記は名称のみ
      valueFormatter: (params: ValueFormatterParams) => agGridUtil.typecodeFormatter(params.value, orderUnitTypeList),
      valueParser: (params: ValueParserParams) => agGridUtil.selectTypecodeValueParser(params, orderUnitTypeList),
      cellEditorParams: editorDataReady ? {
        values : [null, ...orderUnitTypeSelect],
        formatValue: (value: string) => agGridUtil.selectValueFormatter(value, orderUnitTypeList, "typeCode", "typeName")
      } as IRichCellEditorParams : {values: ['-']},
    },
    {
      headerName: 'ケースロット数',
      field: 'caseLot', // ケースロット数
      editable: true,
      valueFormatter: agGridUtil.numberValueFormatter,
      cellClass:'number-cell',
      cellEditor:'agNumberCellEditor',
      valueParser: agGridUtil.numberValueParser,
    },
    {
      headerName: '最低発注量',
      field: 'minOrderQuantity', // 最低発注量
      editable: true,
      valueFormatter: agGridUtil.numberValueFormatter,
      cellClass:'number-cell',
      cellEditor:'agNumberCellEditor',
      valueParser: agGridUtil.numberValueParser,
    },
    {
      headerName: '削除フラグ',
      field: 'deleteFlg', // 削除フラグ
      editable: true,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
      valueFormatter: agGridUtil.formatBooleanValue,
      valueParser: agGridUtil.parseBooleanValue, 
    },
  ];

  const onFilterChanged = useCallback(() => {
    const gridApi = gridRef.current?.api;
  
    if (gridApi) {
      const allColumns = gridApi.getColumns();
      if (allColumns) {
        allColumns.forEach((column) => {
          gridApi.getColumnFilterInstance(column.getColId())
            .then((filterInstance) => {
              if (filterInstance && 'refreshFilterValues' in filterInstance) {
                (filterInstance as any).refreshFilterValues();
              }
            });
        });
      }
      gridApi.redrawRows();
    }
  }, []);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      suppressHeaderMenuButton: false,
      sortable: true,
      filter: 'agMultiColumnFilter',
      filterParams: {
        filters: [
          {
            filter: 'agTextColumnFilter',
          },
          {
            filter: 'agSetColumnFilter',
          },
        ],
      },
      menuTabs: ['filterMenuTab', 'generalMenuTab'] as ColumnMenuTab[],
      mainMenuItems: agGridUtil.customMainMenuItems,
      tooltipValueGetter: agGridUtil.tooltipValue,
      tooltipComponent: GridTooltip,
      cellClassRules: getCellClassRules(),
    }),[])

  const columnDefs = useMemo<(ColDef)[]>(updateColumnDefs, [row, editorDataReady])

  const createBlankRow = (): MstItemWithId => ({
    companyId: loginUserInfo?.companyId || null,
    orgItemCode: null,
    itemName: null,
    salesStartdate: null,
    salesEnddate: null,
    longside: null,
    shortside: null,
    height: null,
    weight: null,
    itemCode: null,
    janCase: null,
    jan: null,
    gtin13: null,
    gtin14: null,
    itf: null,
    sdp: null,
    replaceOrgItemCode: null,
    makerGln: null,
    orgSupplierId: null,
    supplierCompanyId: null,
    supplierName: null,
    deleteFlg: false,
    caseLot: null,
    minOrderQuantity: null,
    palletQuantityFace: null,
    palletQuantityTier: null,
    maxPalletQuantity: null,
    spec: null,
    orderUnitType: null,
    orderUnitTypeName: null,
    replaceItemCode: null,
    oldItemCode: null,
    makerName: null,
    insertDatetime: null,
    updateDatetime: null,
    insertUser: loginUserInfo?.userId || null,
    deleteDatetime: null,
    deleteUser: null,
    updateUser: null,
    ...agGridUtil.initRecordEditEx()
  });
  const addRowAction = (actionParams : IMenuActionParams) => {
    // 右クリックの新規行追加選択時
    // console.log("addRowAction", actionParams)
    const { node } = actionParams;
    if (!node)  return;
    const { data, rowIndex} = node;

    const gridApi = gridRef.current?.api;
    if (gridApi && data && rowIndex !== null) {
      const newRow: MstItemWithId = createBlankRow();

      const displayedRowIndex = node.rowIndex;
      if (displayedRowIndex !== null) {
        const addIndex = displayedRowIndex + 1;
        
        setRow(prevRows => {
          const newRows = [...prevRows];
          newRows.splice(addIndex, 0, newRow);
          return newRows;
        });
        setEditedRows(prev => [
          ...prev, 
          {
            ...newRow,
            isEdited: true,
            editedFields: new Set([]),
            errors: {}
          }
        ]);
        gridApi.applyTransaction({
          add: [newRow],
          addIndex
        });
        gridApi.redrawRows();
      }
    }    
  }
  const deleteRowAction = (actionParams : IMenuActionParams) => {
    // 右クリックの行削除選択時
    // console.log("deleteRowAction", actionParams)
    const { node } = actionParams;
    if (!node)  return;
    const { data } = node;    
    setRow(prevRows => prevRows.filter(rowDelete => rowDelete.id !== data.id));
    setEditedRows(prev => prev.filter(rowDelete => rowDelete.id !== data.id));

  }  
  const getContextMenuItems = useCallback((params: GetContextMenuItemsParams): (string | MenuItemDef)[] => 
    // 右クリックメニュー
    agGridUtil.getContextMenuItems(params,
      addRowAction,
      deleteRowAction
    )
  , []);

  const handleImportExcel = async (data: any[]) => {
    // console.log("handleImportExcel",  data)
    const dateFields: (keyof MstItemWithId)[] = ['salesStartdate', 'salesEnddate'];
    const flgFields: (keyof MstItemWithId)[] = ['deleteFlg'];
    const intFields: (keyof MstItemWithId)[] = ['caseLot','minOrderQuantity','maxPalletQuantity','palletQuantityFace','palletQuantityTier'];
    const numericFields: (keyof MstItemWithId)[] = ['longside','shortside','maxPalletQuantity','weight','height'];
    const typeCodeFields: (keyof MstItemWithId)[] = ['orderUnitType'];
   
    let hasError = false;
    const mappedData = data.map((excelRow: any) => {
      row.find((r) => r.orgItemCode === excelRow.orgItemCode)
      const mappedRow: Partial<MstItemWithId> = {};
      Object.keys(columnMapping).forEach((key) => {
        if (key in excelRow) {
          const tableField = columnMapping[key as keyof typeof columnMapping];
          const importvalue = excelRow[key];
          let setvalue = importvalue || null;
          if (flgFields.includes(tableField as keyof MstItemWithId)) {
            setvalue = agGridUtil.parseBoolean(importvalue)
          } else if (typeCodeFields.includes(tableField as keyof MstItemWithId)) {
            const codevalue = agGridUtil.typeCodeParser(importvalue, orderUnitTypeList,)
            setvalue = agGridUtil.typeCodeParser(codevalue, orderUnitTypeList,)
            if (!codevalue && importvalue) hasError = true;
          } else if (dateFields.includes(tableField as keyof MstItemWithId)) {
            // 日付項目は型変換が必要
            setvalue = agGridUtil.parseDate(importvalue) as unknown as undefined;;
          } else if (intFields.includes(tableField as keyof MstItemWithId)) {
            setvalue = agGridUtil.parsePositiveInteger(importvalue);
          } else if (numericFields.includes(tableField as keyof MstItemWithId)) {
            setvalue = agGridUtil.parseNumeric(importvalue);
          } else {
            setvalue = importvalue ? String(importvalue).trim() : null;;
          }
          // パース出来ないときはスキップ（＝NULL）し、警告メッセージを表示
          if (!agGridUtil.isNonInputValue(importvalue) && setvalue === null) hasError = true;
          mappedRow[tableField as keyof MstItemWithId] = setvalue as unknown as undefined
        }
      });
      return mappedRow as MstItemWithId;
    });
  
    const rowMap = new Map(row.map((existingRow) => [existingRow.orgItemCode, existingRow]));
    const mappedRowMap = new Map(mappedData.map((importedRow) => [importedRow.orgItemCode, importedRow]));
    // console.log("import ",mappedRowMap, rowMap)
    const newRows: MstItemWithId[] = [];
    const editedRowsTemp: MstItemWithId[] = [];
    
    if( hasError ) {
      setResultMessage({
        status: 'warning',
        message: '取り込めない値はスキップされました',
        systemDate: null,
        pageNo: 0,
        systemInfo: null,
      })           
    }

    mappedRowMap.forEach((importedRow, rowId) => {
      const existingRow = rowMap.get(rowId);
      // console.log("importedRow", importedRow)
      if (existingRow) {
        // 既存データ
        let editCount=0
        Object.keys(importedRow).forEach((key) => {
          const typedKey = key as keyof MstItemWithId
          const importedValue = importedRow[key as keyof MstItemWithId] ?? null;
          const existingValue = existingRow[key as keyof MstItemWithId] ?? null;
          if (dateFields.includes(typedKey)) {
            if (!isEqualDate(importedValue, existingValue)){
              existingRow.editedFields.add(key);
              editCount += 1
            }

          } else if (String(importedValue) !== String(existingValue)) {
            existingRow.editedFields.add(key);
            editCount += 1
          }
        });

        if (editCount > 0) {
          const updatedRow = {
            ...existingRow,
            ...importedRow,
            isEdited: true,
            errors: {},
          };
          // 入力チェック
          rowDataValidate(updatedRow)
          editedRowsTemp.push(updatedRow);
          rowMap.set(rowId, updatedRow);
        }
      } else {
        // 新規行
        const editedFields = new Set<string>();

        Object.keys(importedRow).forEach((key) => {
          const value = importedRow[key as keyof MstItemWithId];
          if (value !== null && value !== undefined && value !== '') {
            editedFields.add(key);
          }
        });
        
        const newRow = {
          ...createBlankRow(),
          ...importedRow,
          isEdited: true, 
          errors: {},
          editedFields,
        };
        // 入力チェック
        rowDataValidate(newRow)
  
        newRows.push(newRow);
        rowMap.set(rowId, newRow);
      }
    });

    const updatedRowData = Array.from(rowMap.values());
    setRow(updatedRowData);
    setEditedRows((prevEditedRows) => {
      const filteredEditedRows = prevEditedRows.filter(
        (prevRow) => !editedRowsTemp.some((editedRow) => editedRow.orgItemCode === prevRow.orgItemCode)
      );
      const newEditedRows = newRows.filter(
        (newRow) => !prevEditedRows.some((prevRow) => prevRow.orgItemCode === newRow.orgItemCode)
      );
      return [...newEditedRows, ...filteredEditedRows, ...editedRowsTemp];
    });
  
    if (gridRef.current?.api) {
      gridRef.current.api.applyTransaction({ add: newRows, update: editedRowsTemp });
      gridRef.current.api.refreshCells({ force: true });
      gridRef.current.api.redrawRows()
    }

  };
  useEffect(() => {
    // console.debug("editedRows", editedRows)
    if (editedRows && gridRef.current?.api) {
      gridRef.current.api.applyTransaction({ update: editedRows });
      gridRef.current.api.refreshCells({ force: true });
    }
  }, [editedRows]);

  useEffect(() => {
    if (onTriggerAction) {
      updateMstItem();
    }
  }, [onTriggerAction]);

  useEffect(() => {
    if (onUploadData) {
      handleImportExcel(onUploadData);
    }
  }, [onUploadData]);

  useEffect(() => {
    if (editedRows.length > 0) {
      setHasGridEdited(true);
    } 
  }, [editedRows, setHasGridEdited]);

  const onSnackbarClose = () => {
    setSnackBarOpen(false)
  }

  useEffect(() => {
    if (list.list.length === 0) {
      const blankRow = createBlankRow();
      setRow([blankRow]);
    } else {
      setRow(list.list.map(item => ({ ...item, ...agGridUtil.initRecordEditEx()})))
    }
  }, [list.list]);

  const originalConsoleWarn = console.error;

  console.error = (...args) => {
    if (typeof args[0] === 'string' && args[0].includes('AG Grid: could not find row id')) {
      return; 
    }
    originalConsoleWarn(...args); 
  };

  return (
    <>
      <div style={{ ...containerStyle, display: 'flex', flexDirection: 'column', height: '69vh' }}>
        <div style={{ flexGrow: 1, boxSizing: 'border-box' }}>
          <div style={gridStyle} className="ag-theme-quartz">
            <AgGridReact
              rowData={row}
              ref={gridRef}
              getRowId={(params) => params.data.id}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              localeText={localeText}
              enableRangeSelection
              fillHandleDirection="xy"
              animateRows
              tooltipShowDelay={0}
              tooltipHideDelay={3000}
              onCellValueChanged={onCellValueChanged}
              rowSelection="single"
              autoSizeStrategy={autoSizeStrategy}
              rowDragManaged
              rowHeight={36}
              allowContextMenuWithControlKey
              getContextMenuItems={getContextMenuItems}
              loadingOverlayComponent={LoadingOverlay}
              overlayNoRowsTemplate={'<span aria-live="polite" aria-atomic="true";pos>条件に合致する商品が見つかりません</span>'}
              suppressLastEmptyLineOnPaste
              onPasteStart={onPasteStart}
              onPasteEnd={onPasteEnd}
              reactiveCustomComponents
              onFilterChanged={onFilterChanged}
            />
          </div>
        </div>
      </div>

      
      <AppSnackbar
        message={snackBarMessage}
        open={snackBarOpen}
        onClose={onSnackbarClose}
        severity={snackBarSeverity}
      />
    </>
  );
}

export default DataList;
