import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { AgGridReact } from 'ag-grid-react'
import { format } from 'date-fns'
import * as agGridUtil from 'utils/agGridUtil'

import type { ColDef, ColGroupDef } from 'ag-grid-community'
import type { MstCenterStockItem } from 'api-client'

interface DownloadGridProps {
  downloadData: MstCenterStockItem[]
  downloadMode: string
}

export function DownloadGrid({ downloadData, downloadMode }: DownloadGridProps) {
  const fileName = "センター在庫管理"
  const columnDefs = useMemo(
    (): (ColDef | ColGroupDef)[] => [
        { headerName: 'プロダクト統一企業ID', children: [{ headerName: 'company_id', field: 'companyId' }] },
        { headerName: '自社センター', children: [{ headerName: 'org_center_id', field: 'orgCenterId', cellClass: 'stringType' }] },
        { headerName: '社内商品コード', children: [{ headerName: 'org_item_code', field: 'orgItemCode', cellClass: 'stringType' }] },
        { headerName: '適用開始日', children: [{ headerName: 'start_date', field: 'startDate', valueFormatter: agGridUtil.dateFormatter }] },
        { headerName: '在庫日数', children: [{ headerName: 'stock_days_setting', field: 'stockDaysSetting', cellClass: 'numberType' }] },
        { headerName: '安全在庫', children: [{ headerName: 'safety_stock_setting', field: 'safetyStockSetting', cellClass: 'decimalType' }] },
        { headerName: '在庫日数ランク', children: [{ headerName: 'stock_days_rank', field: 'stockDaysRank', cellClass: 'stringType' }] },
        { headerName: '安全在庫ランク', children: [{ headerName: 'safety_stock_rank', field: 'safetyStockRank', cellClass: 'stringType' }] },
        { headerName: '最低在庫数', children: [{ headerName: 'min_stock_quantity', field: 'minStockQuantity', cellClass: 'numberType' }] },
        { headerName: '出荷停止', children: [{ headerName: 'stop_ship', field: 'stopShip', cellClass: 'booleanType' }] },
        { headerName: '入荷停止', children: [{ headerName: 'stop_arrival', field: 'stopArrival', cellClass: 'booleanType' }] },
        { headerName: '仕入先企業', children: [{ headerName: 'org_supplier_id', field: 'orgSupplierId', cellClass: 'stringType' }] },
        { headerName: '発注単位', children: [{ headerName: 'order_unit_type', field: 'orderUnitType', cellClass: 'stringType' }] },
        { headerName: 'ケースロット数', children: [{ headerName: 'case_lot', field: 'caseLot', cellClass: 'numberType' }] },
        { headerName: '最低発注量', children: [{ headerName: 'min_order_quantity', field: 'minOrderQuantity', cellClass: 'numberType' }] },
        { headerName: '削除フラグ', children: [{ headerName: 'delete_flg', field: 'deleteFlg', cellClass: 'booleanType' }] },
        { headerName: '商品コード', children: [{ headerName: 'item_code', field: 'itemCode', cellClass: 'stringType' }] },
        { headerName: '最新商品フラグ', children: [{ headerName: 'recency_flg', field: 'recencyFlg', cellClass: 'booleanType' }] },
    ],
    []
  )

  const [rowData, setRowData] = useState<MstCenterStockItem[]>([])
  const gridRef = useRef<AgGridReact>(null)

  const onModelUpdated = () => {
    const gridApi = gridRef.current?.api
    if (gridApi && rowData.length > 0) {

      const dlfileName = `${fileName}_${format(new Date(), 'yyyyMMddHHmmss')}`
      if (downloadMode === 'csv') {
        gridApi.exportDataAsCsv({ fileName:dlfileName })
      } else if (downloadMode === 'xlsx') {
        gridApi.exportDataAsExcel({ fileName:dlfileName, sheetName: 'データ' })
      }

    }
  }

  useEffect(() => {
    setRowData(downloadData)
  }, [downloadData])

  return (
    <div>
      <AgGridReact
        ref={gridRef}
        columnDefs={columnDefs}
        rowData={rowData}
        onModelUpdated={onModelUpdated}
        excelStyles={agGridUtil.excelStyles}
      />
    </div>
  )
}
