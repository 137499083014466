import React, { useEffect, useRef, useState, type Dispatch, type SetStateAction } from 'react';

import UploadIcon from '@mui/icons-material/Upload';
import { Box, Button, CircularProgress, TextField } from '@mui/material';
// import AppSnackbar from 'components/commonparts/AppSnackbar/AppSnackbar';
// import MessageInfo from 'components/commonparts/MessageInfo/MessageInfo';
import { VisuallyHiddenInput, fileInputWidth } from 'style';
import * as XLSX from 'xlsx';

import type { ResponseResult } from 'api-client';

interface FileImportDataProps {
  onImportData: (header: any[], data: any[]) => Promise<void>;
  isLoading?: boolean;
  inputWidth?: string;
  placeholder?: string;
  headerJp: string[];
  // result?: ResponseResult | undefined;
  setResultMessage: Dispatch<SetStateAction<ResponseResult | undefined>>
  shouldReset?: boolean;
  resetCallback?: () => void;
  // PK項目がファイル内に存在しない場合はエラーとする
  requiredFields: string[];
}

function FileImportData({
  onImportData,
  isLoading,
  inputWidth = fileInputWidth,
  placeholder = '',
  headerJp,
  setResultMessage,
  shouldReset,
  resetCallback,
  requiredFields,
}: FileImportDataProps) {
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (shouldReset) {
      setFile(null);
      setFileName('');
      if (fileInputRef.current) fileInputRef.current.value = ''; 
      resetCallback?.();
    }
  }, [shouldReset, resetCallback]);

 const handleParseFile = (uploadFile: File) =>
  new Promise<any[]>((resolve, reject) => {
    const fileType = uploadFile.name.split('.').pop()?.toLowerCase();

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target?.result;
      let sheetData: any[][]=[[]];
      if (fileType === 'xlsx') {
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        
      } else if (fileType === 'csv') {
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      }
      const [, headers, ...rest] = sheetData;
      const hasRequiredField = requiredFields.filter((f) => !headers.includes(f)).length === 0;

      if (!hasRequiredField) {
        setResultMessage({
          status: 'error',
          message: `アップロードファイルが不正です。${headerJp}がありません`,
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })        
        setFile(null);
        setFileName('');
        if (fileInputRef.current) fileInputRef.current.value = '';
        return
      }

      const dataRows = sheetData.slice(2);
      const filteredDataRows = dataRows.filter(row => row && row.some(cell => cell !== null && cell !== undefined && cell !== ''));
      resolve([headers, filteredDataRows]);
    };
    reader.onerror = (error) => reject(error);
    
    if (fileType === 'xlsx') {
      reader.readAsBinaryString(uploadFile);
    } else if (fileType === 'csv') {
      reader.readAsText(uploadFile);
    }
  });

  const handleFileUpload = async () => {
    setResultMessage(undefined);
    if (!file) return;

    const fileType = file.name.split('.').pop()?.toLowerCase();
  
    if (fileType !== 'xlsx') {
      setResultMessage({
        status: 'error',
        message: 'ファイル種別が不正です。拡張子がxlsxのファイルのみ処理できます。',
        systemDate: null,
        pageNo: 0,
        systemInfo: null,
      })          
      setFile(null);
      setFileName('');
      if (fileInputRef.current) fileInputRef.current.value = '';
      return
    }

    try {
      const importresult = await handleParseFile(file); 
      await onImportData(importresult[0],importresult[1]); 
    } catch (error) {
      console.error(error)
      setResultMessage({
        status: 'error',
        message: 'ファイル読み込みでエラーが発生しました',
        systemDate: null,
        pageNo: 0,
        systemInfo: null,
      })        
    }

    setFile(null);
    setFileName('');

    if (fileInputRef.current) fileInputRef.current.value = '';
  };

  return (
    <Box display="flex" alignItems="center">
      <TextField
        id="outlined-basic"
        placeholder={placeholder}
        variant="outlined"
        value={fileName}
        inputProps={{
          readOnly: true,
        }}
        sx={{ width: inputWidth }}
      />
      <Button component="label" variant="contained">
        ファイル選択
        <VisuallyHiddenInput
          ref={fileInputRef}
          type="file"
          accept=".xlsx"
          onChange={(e) => {
            const TrFiles = e.target.files;

            if (TrFiles && TrFiles[0]) {
              setFile(TrFiles[0]);
              setFileName(TrFiles[0].name);
            }
          }}
        />
      </Button>
      <Button
        variant="contained"
        startIcon={isLoading ? <CircularProgress size={24} color="primary" /> : <UploadIcon />}
        onClick={handleFileUpload}
        disabled={!file}
      >
        インポート
      </Button>
    </Box>
  );
}

export default FileImportData;
