import React, { useCallback, useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { useAuthContext } from 'AuthProvider';
import { MasterSearchFrom } from 'components/commonparts'
import { SearchCondition, DataList } from 'components/parts/MasterCalender';
import { DownloadGrid } from 'components/parts/MasterCalender/DataList/DownloadGrid';
import { useCalendar } from 'hooks/useCalendar';
import { useCenter } from 'hooks/useCenter';
import { useSearchParamStore } from 'store/searchParamStore';
import { initDataListStatus, type DataListStatus } from 'types/types';
import { getLocalSearchParam } from 'utils/getLocalSearchParam';
import { sortToRequestParam } from 'utils/sortToRequestParam';

import type { GetMstCalendarCalendarGetRequest, MstCalendar, ResponseResult } from 'api-client';

function MasterCalendar() {
  const { loginUserInfo } = useAuthContext()
  const trCompanyId = loginUserInfo?.companyId;
  const setSearchParamState = useSearchParamStore((state) => state.setSearchParamState)
  const searchParamState = useSearchParamStore((state) => state.searchParamState)

  const localSearchParam = getLocalSearchParam(searchParamState, loginUserInfo?.tenantId, loginUserInfo?.companyId)
  const localOrgCenterId = localSearchParam?.calendar?.orgCenterId || '' 

  // 自社のcompanyIdのみ検索条件をローカルストレージから復元
  const initSearchParam: GetMstCalendarCalendarGetRequest = {
    companyId: trCompanyId ?? loginUserInfo!.companyId,
    orgCenterId: localOrgCenterId,
  }

  const [hasGridEdited, setHasGridEdited] = useState<boolean>(false)
  const [downloadGridRows, setDownloadGridRows] = useState<MstCalendar[]>([])
  const [downloadMode, setDownloadMode] = useState<string>('csv')

  const [gridMessage, setGridMessage] = useState<ResponseResult | undefined>(undefined)
  const [resultMessage, setResultMessage] = useState<ResponseResult | undefined>(undefined)
  const { centerSearch, fetchCenterSearch } = useCenter()

  const { calendarSearch, fetchCalendarSearch, calendarSearchResult, isLoading } = useCalendar()
  const [searchParam, setSearchParam] = useState<GetMstCalendarCalendarGetRequest>(initSearchParam)
  const [listStatus, setListStatus] = useState<DataListStatus>(initDataListStatus)
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const [onReset, setOnReset] = useState<boolean>(false)
  const [isRegister, setRegister] = useState<boolean>(false)
  const [uploadData, setUploadData] = useState<any[]>([])
  const [searchTrigger, setSearchTrigger] = useState<boolean>(false);
  const [discardChangeDialogOpen, setDiscardChangeDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    setResultMessage(calendarSearchResult)
  }, [calendarSearchResult])

  useEffect(() => {
    setResultMessage(gridMessage)
  }, [gridMessage])

  const doSearch = useCallback(async (requestParam: GetMstCalendarCalendarGetRequest) => {
    try {
      fetchCalendarSearch({
          ...requestParam,
      });
  
        if (requestParam.companyId === loginUserInfo!.companyId && loginUserInfo?.tenantId && loginUserInfo?.companyId) {
          setSearchParamState<GetMstCalendarCalendarGetRequest>(
            loginUserInfo?.tenantId,
            loginUserInfo?.companyId,
            'Calendar',
            {
              ...requestParam,
              sort: sortToRequestParam(listStatus.sortModel),
            }
          );
        }
  
        setSearchParam(requestParam);
        setListStatus({
          ...listStatus,
        });
        setOnReset(true); 
        setHasGridEdited(false)
      } catch (error) {
        console.error("Error fetching data:", error);
      } 
    }, [
      hasGridEdited,
      setDiscardChangeDialogOpen,
      fetchCalendarSearch,
      listStatus.sortModel,
      loginUserInfo,
      setSearchParamState,
      setSearchParam,
      setListStatus,
      setOnReset,
      setHasGridEdited
  ]);

  const handleRegister = () => {
    if (!isRegister) { 
      setRegister(true);
    }
  }

  const handleUploadData = async (headers: any[], data: any[]) => {
    const mappedData = data.map((row) => {
      const obj: any = {};
      headers.forEach((header, index) => {
        obj[header] = row[index];
        // let value = row[index];

        // if (header === 'calendar_date') {
        //   if(typeof value === 'number') {
        //     value = excelSerialToDate(value)?.toString();
        //   } else {
        //     const parsedDate = normalizeDate(value);
        //     value = parsedDate ? parsedDate.toString() : null;
        //   }
        // }

        // if(header === 'org_center_id'){
        //   value = value ? String(value) : null;
        //   if (value && !centerSearch.list.some(center => center.orgCenterId === value)) {
        //     value = null;
        //   }
        // }
        
        // obj[header] = value;
      });
      return obj;
    });

    setUploadData(mappedData); 
  };

  const onReload = () => {
    // 検索処理の実行を行うトリガー
    setSearchTrigger((prev) => !prev);
  }

  useEffect(() => {
    const firstTimeRender = async () => { 
      if (isFirstRender) {
        await fetchCenterSearch({
          companyId: searchParam.companyId
        })
        
          await fetchCalendarSearch({
            ...searchParam,
          });
  
  
        setIsFirstRender(false)
      }
    }

    firstTimeRender();
  }, [isFirstRender, searchParam, listStatus.sortModel]);

  const generateDownloadData = (): MstCalendar[] => {
    if (!calendarSearch?.list || calendarSearch.list.length === 0) {
      return [{} as MstCalendar]
    }
    const downloadData: MstCalendar[] = [...calendarSearch.list]
    return downloadData
  }

  const handleFileDownload = () => {
    setDownloadGridRows(generateDownloadData())
  }


  return (
    <Box>
      <Typography component="div" variant="largeBold">
      カレンダー登録
      </Typography>
      <MasterSearchFrom
          onReload={onReload}
          result={resultMessage}
          onDoUpdate={handleRegister}
          onImportData={handleUploadData}
          onFileDownLoad={handleFileDownload}
          discardChangeDialogOpen={discardChangeDialogOpen}
          setDownloadMode={setDownloadMode}
          importRequiredFields={["org_center_id"]}
          headerJp={["自社センターID"]}
          lastUpdateDatetime={null}
          hasDataEdited={hasGridEdited}
      >
        <SearchCondition
            hasDataEdited={hasGridEdited}
            searchParam={initSearchParam}
            onSearch={doSearch}
            setDiscardChangeDialogOpen={setDiscardChangeDialogOpen}
            searchTrigger={searchTrigger}
        />
      </MasterSearchFrom>
      <DownloadGrid downloadData={downloadGridRows} downloadMode={downloadMode} />
      <DataList
          list={calendarSearch}
          onReload={onReload}
          availableItems={centerSearch.list}
          onReset={onReset}
          isLoading={isLoading}
          searchParam={searchParam}
          onSearch={doSearch}
          setOnReset={setOnReset}
          onTriggerAction={isRegister}
          onUploadData={uploadData}
          setOnTriggerAction={setRegister}
          setHasGridEdited={setHasGridEdited}
          setResultMessage={setGridMessage}
        />
      
    </Box>
          
  )
}

export default MasterCalendar;
