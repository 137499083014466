import React, { useEffect, useMemo, useRef, useState } from 'react'

import { AgGridReact } from 'ag-grid-react'
import { format } from 'date-fns'
import * as agGridUtil from 'utils/agGridUtil'

import type { ColDef, ColGroupDef } from 'ag-grid-community'
import type { MstCenter } from 'api-client'

interface DownloadGridProps {
  downloadData: MstCenter[]
  downloadMode: string
}

export function DownloadGrid({ downloadData, downloadMode }: DownloadGridProps) {
  const fileName = "自社センターマスタ"
  const columnDefs = useMemo(
    (): (ColDef | ColGroupDef)[] => [
        { headerName: '自社センターID', children: [{ headerName: 'org_center_id', field: 'orgCenterId', cellClass: 'stringType' }] },
        { headerName: 'プロダクト統一企業ID', children: [{ headerName: 'company_id', field: 'companyId' }] },
        { headerName: 'プロダクト統一センターID', children: [{ headerName: 'center_id', field: 'centerId', cellClass: 'stringType' }] },
        { headerName: 'センター名', children: [{ headerName: 'center_name', field: 'centerName', cellClass: 'stringType' }] },
        { headerName: 'センター所在地住所', children: [{ headerName: 'center_address', field: 'centerAddress', cellClass: 'stringType' }] },
        { headerName: '前日在庫引当フラグ', children: [{ headerName: 'ship_previous_stock_flg', field: 'shipPreviousStockFlg', cellClass: 'booleanType' }] },
        { headerName: '当日出荷数実績遅延フラグ', children: [{ headerName: 'today_ship_rec_delay_flg', field: 'todayShipRecDelayFlg', cellClass: 'booleanType'}] },
        { headerName: '出荷可能フラグ（月曜日）', children: [{ headerName: 'enable_shipday_mon', field: 'enableShipdayMon', cellClass: 'booleanType' }] },
        { headerName: '出荷可能フラグ（火曜日）', children: [{ headerName: 'enable_shipday_tue', field: 'enableShipdayTue', cellClass: 'booleanType' }] },
        { headerName: '出荷可能フラグ（水曜日）', children: [{ headerName: 'enable_shipday_wed', field: 'enableShipdayWed', cellClass: 'booleanType' }] },
        { headerName: '出荷可能フラグ（木曜日）', children: [{ headerName: 'enable_shipday_thu', field: 'enableShipdayThu', cellClass: 'booleanType' }] },
        { headerName: '出荷可能フラグ（金曜日）', children: [{ headerName: 'enable_shipday_fri', field: 'enableShipdayFri', cellClass: 'booleanType' }] },
        { headerName: '出荷可能フラグ（土曜日）', children: [{ headerName: 'enable_shipday_sat', field: 'enableShipdaySat', cellClass: 'booleanType' }] },
        { headerName: '出荷可能フラグ（日曜日）', children: [{ headerName: 'enable_shipday_sun', field: 'enableShipdaySun', cellClass: 'booleanType' }] },
        { headerName: '納品（受入）可能フラグ（月曜日）', children: [{ headerName: 'enable_arrival_mon', field: 'enableArrivalMon', cellClass: 'booleanType' }] },
        { headerName: '納品（受入）可能フラグ（火曜日）', children: [{ headerName: 'enable_arrival_tue', field: 'enableArrivalTue', cellClass: 'booleanType' }] },
        { headerName: '納品（受入）可能フラグ（水曜日）', children: [{ headerName: 'enable_arrival_wed', field: 'enableArrivalWed', cellClass: 'booleanType' }] },
        { headerName: '納品（受入）可能フラグ（木曜日）', children: [{ headerName: 'enable_arrival_thu', field: 'enableArrivalThu', cellClass: 'booleanType' }] },
        { headerName: '納品（受入）可能フラグ（金曜日）', children: [{ headerName: 'enable_arrival_fri', field: 'enableArrivalFri', cellClass: 'booleanType' }] },
        { headerName: '納品（受入）可能フラグ（土曜日）', children: [{ headerName: 'enable_arrival_sat', field: 'enableArrivalSat', cellClass: 'booleanType' }] },
        { headerName: '納品（受入）可能フラグ（日曜日）', children: [{ headerName: 'enable_arrival_sun', field: 'enableArrivalSun', cellClass: 'booleanType' }] },
        { headerName: '発注業務可能フラグ（月曜日）', children: [{ headerName: 'enable_orderday_mon', field: 'enableOrderdayMon', cellClass: 'booleanType' }] },
        { headerName: '発注業務可能フラグ（火曜日）', children: [{ headerName: 'enable_orderday_tue', field: 'enableOrderdayTue', cellClass: 'booleanType' }] },
        { headerName: '発注業務可能フラグ（水曜日）', children: [{ headerName: 'enable_orderday_wed', field: 'enableOrderdayWed', cellClass: 'booleanType' }] },
        { headerName: '発注業務可能フラグ（木曜日）', children: [{ headerName: 'enable_orderday_thu', field: 'enableOrderdayThu', cellClass: 'booleanType' }] },
        { headerName: '発注業務可能フラグ（金曜日）', children: [{ headerName: 'enable_orderday_fri', field: 'enableOrderdayFri', cellClass: 'booleanType' }] },
        { headerName: '発注業務可能フラグ（土曜日）', children: [{ headerName: 'enable_orderday_sat', field: 'enableOrderdaySat', cellClass: 'booleanType' }] },
        { headerName: '発注業務可能フラグ（日曜日）', children: [{ headerName: 'enable_orderday_sun', field: 'enableOrderdaySun', cellClass: 'booleanType' }] },
        { headerName: 'センターグループ名', children: [{ headerName: 'center_group_name', field: 'centerGroupName',cellClass: 'stringType' }] },
        { headerName: 'GS1事業者コード', children: [{ headerName: 'center_gln', field: 'centerGln', cellClass: 'stringType' }] },
        { headerName: '削除フラグ', children: [{ headerName: 'delete_flg', field: 'deleteFlg', cellClass: 'booleanType' }] },
    ],
    []
  )
  
  const [rowData, setRowData] = useState<MstCenter[]>([])
  const gridRef = useRef<AgGridReact>(null)

  const onModelUpdated = () => {
    const gridApi = gridRef.current?.api
    if (gridApi && rowData.length > 0) {
      const dlfileName = `${fileName}_${format(new Date(), 'yyyyMMddHHmmss')}`
      if (downloadMode === 'csv') {
        gridApi.exportDataAsCsv({ fileName:dlfileName })
      } else if (downloadMode === 'xlsx') {
        gridApi.exportDataAsExcel({ fileName:dlfileName, sheetName: 'データ' })
      }
    }
  }

  useEffect(() => {
    setRowData(downloadData)
  }, [downloadData])

  return (
    <div>
      <AgGridReact
        ref={gridRef}
        columnDefs={columnDefs}
        rowData={rowData}
        onModelUpdated={onModelUpdated}
        excelStyles={agGridUtil.excelStyles}
      />
    </div>
  )
}
