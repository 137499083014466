import type { Dispatch, FormEvent, SetStateAction } from 'react'
import React, { useEffect, useState } from 'react'

import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, CircularProgress, FormControl, Grid, InputAdornment, Modal, TextField, IconButton } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { DataList, SearchCondition } from 'components/parts/ItemSearch'
import { useItem } from 'hooks/useItems'
import _ from 'lodash'
import { useSearchParamStore } from 'store/searchParamStore'
import { DialogWrapper } from 'templates'
import { initDataListStatus, type DataListStatus } from 'types/types'
import { getLocalSearchParam } from 'utils/getLocalSearchParam'
import { sortToRequestParam, sortToSortModel } from 'utils/sortToRequestParam'

import type { GetMstItemsItemGetRequest, MstItem } from 'api-client'

interface ItemSearchProps {
  trCompanyId: number | undefined
  itemCode?: string | null | undefined
  setItemCode?: Dispatch<SetStateAction<string | null | undefined>>
  orgItemCode?: string | null | undefined
  setOrgItemCode?: Dispatch<SetStateAction<string | null | undefined>>
  setItemInfo: Dispatch<SetStateAction<MstItem | undefined>>
  isRequred: boolean
  setIsValid: Dispatch<SetStateAction<boolean>>
}

function ItemSearch({ trCompanyId, itemCode, setItemCode, setOrgItemCode, orgItemCode, setItemInfo, isRequred, setIsValid }: ItemSearchProps) {
  const { loginUserInfo } = useAuthContext()

  const setSearchParamState = useSearchParamStore((state) => state.setSearchParamState)
  const searchParamState = useSearchParamStore((state) => state.searchParamState)

  const localSearchParam = getLocalSearchParam(searchParamState, loginUserInfo?.tenantId, loginUserInfo?.companyId)

  const localItemName = localSearchParam?.item.itemName || ''
  const localBarcode = localSearchParam?.item.barcode || ''
  const localOrgSupplierId = localSearchParam?.item.orgSupplierId || ''
  const localSort = localSearchParam?.item.sort || []
  const localPageNo = localSearchParam?.item.pageNo || 0
  const localLimit = localSearchParam?.item.limit || 100

  // 自社のcompanyIdのみ検索条件をローカルストレージから復元
  const initSearchParam: GetMstItemsItemGetRequest = {
    companyId: trCompanyId ?? loginUserInfo!.companyId,
    itemCode,
    itemName: localItemName,
    orgItemCode,
    barcode: localBarcode,
    orgSupplierId: localOrgSupplierId,
    sort: localSort,
    pageNo: localPageNo,
    limit: localLimit,
  }

  const [textItemName, setTextItemName] = useState<string | null | undefined>('')
  // ダイアログ開閉に関するstate
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const { result, items, fetchItems, isLoading, findItem, fetchFindItem, findItemresult, isFindItemLoading } = useItem()

  const [searchParam, setSearchParam] = useState<GetMstItemsItemGetRequest>(initSearchParam)
  const [listStatus, setListStatus] = useState<DataListStatus>(initDataListStatus)
  const [isFirstRender, setIsFirstRender] = useState(true)

  const onSearch = (requestParam: GetMstItemsItemGetRequest, e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    // ガード句
    if (!(e.nativeEvent instanceof SubmitEvent)) return
    const { submitter } = e.nativeEvent

    // idがitemの場合のみ検索実行
    if (submitter?.id === 'item') {
      // 検索API実行
      fetchItems({
        ...requestParam,
        limit: listStatus.paginationModel.pageSize,
        pageNo: 0,
        sort: sortToRequestParam(listStatus.sortModel),
      })

      // companyIdが自社のcompanyIdのみ検索条件をローカルストレージに保存する
      if (requestParam.companyId === loginUserInfo!.companyId && loginUserInfo?.tenantId && loginUserInfo?.companyId) {
        setSearchParamState<GetMstItemsItemGetRequest>(loginUserInfo?.tenantId, loginUserInfo?.companyId, 'Item', {
          ...requestParam,
          sort: sortToRequestParam(listStatus.sortModel),
        })
      }

      setSearchParam(requestParam)
      // ページ番号は１に戻して、ソート順のみ維持
      setListStatus({
        ...listStatus,
        paginationModel: { page: 0, pageSize: listStatus.paginationModel.pageSize },
      })
    }
  }

  const onListStatusChange = (status: DataListStatus) => {
    const trPageNo = status.paginationModel.page + 1

    fetchItems({
      ...searchParam,
      itemCode: undefined,
      orgItemCode: undefined,
      limit: status.paginationModel.pageSize,
      pageNo: trPageNo,
      sort: sortToRequestParam(status.sortModel),
    })

    // companyIdが自社のcompanyIdのみ検索条件をローカルストレージに保存する
    if (searchParam.companyId === loginUserInfo!.companyId && loginUserInfo?.tenantId && loginUserInfo?.companyId) {
      setSearchParamState<GetMstItemsItemGetRequest>(loginUserInfo?.tenantId, loginUserInfo?.companyId, 'Item', {
        ...searchParam,
        limit: status.paginationModel.pageSize,
        pageNo: trPageNo,
        sort: sortToRequestParam(status.sortModel),
      })
    }

    setListStatus({ ...listStatus, sortModel: status.sortModel, paginationModel: status.paginationModel })
  }

  const onRowSelect = (trItem: MstItem) => {
    if (setItemCode && setItemInfo) {
      setItemCode(trItem.itemCode)
      setItemInfo(trItem)
      setTextItemName(trItem.itemName)
    } else if (setOrgItemCode && setItemInfo) {
      setOrgItemCode(trItem.orgItemCode)
      setItemInfo(trItem)
      setTextItemName(trItem.itemName)
    }
  handleClose()
  }

  const handleClear = () => {
    setTextItemName('')
    if (setItemCode) setItemCode(undefined)
    if (setOrgItemCode) setOrgItemCode(undefined)
    setItemInfo(undefined)
  }

  // クリアボタン処理
  useEffect(() => {
    // itemCodeが空の場合は、入力値をクリアにする
    if (itemCode === '' || orgItemCode === '') {
      setTextItemName('')
    }
  }, [itemCode, orgItemCode])

  // trCompanyIdが監視し、変更されれば商品をクリアにする
  useEffect(() => {
    if (textItemName) {
      if (itemCode && setItemCode) {
        setItemCode('')
        setTextItemName('')
        setItemInfo(undefined)
      } else if (orgItemCode && setOrgItemCode) {
        setOrgItemCode('')
        setTextItemName('')
        setItemInfo(undefined)
      }
    }
  }, [trCompanyId])

  // 初回表示時にitemCodeが指定されている場合は商品詳細情報、商品名を通達する
  useEffect(() => {
    if ((itemCode || orgItemCode) && isFirstRender) {
      fetchFindItem({
        ...searchParam,
        itemCode,
        orgItemCode,
        limit: listStatus.paginationModel.pageSize,
        pageNo: 0,
        sort: sortToRequestParam(listStatus.sortModel),
      })

      if (findItemresult && isFirstRender) {
        let foundItem = null;
  
        if (itemCode) {
          foundItem = _.find(findItem.list, { itemCode });
        } else if (orgItemCode) {
          foundItem = _.find(findItem.list, { orgItemCode });
        }
  
        if (foundItem) {
          setItemInfo(foundItem);
          setTextItemName(foundItem.itemName || '');
          setIsFirstRender(false);
        }
      }
    }
  }, [findItemresult])

  useEffect(() => {
    if (localItemName || localBarcode || localOrgSupplierId) {
      fetchItems({
        ...searchParam,
        itemCode: undefined,
        orgItemCode: undefined,
        sort: sortToRequestParam(listStatus.sortModel),
        limit: initSearchParam.limit,
        pageNo: initSearchParam.pageNo,
      })

      setListStatus({
        ...listStatus,
        sortModel: sortToSortModel(initSearchParam.sort),
        paginationModel: {
          pageSize: initSearchParam.limit ?? 100,
          page: initSearchParam.pageNo ?? 0,
        },
      })
    }
  }, [])

  // 必須チェック処理
  useEffect(() => {
    // 必須チェックを上位コンポーネントに通知
    if (textItemName) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }, [textItemName])

  let endAdornment = null;

  if (isFindItemLoading) {
    endAdornment = (
      <InputAdornment position="start">
        <CircularProgress color="inherit" size={20} />
      </InputAdornment>
    );
  } else if (textItemName) {
    endAdornment = (
      <InputAdornment position="start">
        <IconButton onClick={handleClear} sx={{ padding: 0, minWidth: 'unset' }}>
          <ClearIcon sx={{ fontSize: 20, color: '#999' }} />
        </IconButton>
      </InputAdornment>
    );
  }


  return (
    <FormControl>
      <Box>
        <TextField
          label="商品"
          id="text-item-code"
          value={textItemName}
          inputProps={{
            readOnly: true,
          }}
          // 必須入力チェック関するprops
          error={isRequred && !textItemName}
          helperText={isRequred && !textItemName && '必須'}
          required={isRequred}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            // ローディング処理
            endAdornment
          }}
        />
        {/* テキストフィールドとボタンの位置を合わせるためsx調整を行う */}
        <Button variant="contained" onClick={handleOpen} sx={{ marginTop: 3, marginRight: 0.5 }}>
          商品検索
        </Button>
        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <DialogWrapper>
            <SearchCondition searchParam={initSearchParam} onSearch={onSearch} result={result} />
            {/* NOTE:ダイアログ内でDatagridを用いる際にソートアイコンが非表示にならないため開閉ごとに表示を切り替える */}
            {open ? (
              <DataList
                list={items}
                isLoading={isLoading}
                listStatus={listStatus}
                onListStatusChange={onListStatusChange}
                onRowSelect={onRowSelect}
              />
            ) : (
              <div />
            )}
            <Grid direction="row" container justifyContent="end">
              <Grid item>
                <Button variant="contained" color="secondary" onClick={handleClose}>
                  閉じる
                </Button>
              </Grid>
            </Grid>
          </DialogWrapper>
        </Modal>
      </Box>
    </FormControl>
  )
}

export default ItemSearch
