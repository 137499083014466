import { useState } from 'react'

import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'
import _ from 'lodash'
import { useCurrentStatusStore } from 'store/currentStatusStore'

import type {
  GetMstCenterStockItemCenterStockItemGetRequest,
  ResponseResult,
  ResponseUpdate,
  MasterCenterStockItemUpdateMstCenterStockItemUpdatePostRequest,
  CenterStockItem,
  MstCenterStockItem,
} from 'api-client'
import type { ErrorResponse } from 'types/types'

export interface MstCenterStockItemSeq extends MstCenterStockItem{
  id: string
}

export interface MstCenterStockItemsSeq extends CenterStockItem{
  list: MstCenterStockItemSeq[]
}

export const useCenterStockItem = () => {
  const [centerstockitemSearch, setCenterStockItemSearch] = useState<CenterStockItem>({
    datacount: 0,
    maxcount: 0,
    lastUpdateDatetime: null,
    list: [],
  })
  const [centerstockitemSearchIsLoading, setCenterStockItemSearchIsLoading] = useState(false)
  const [centerstockitemUpdateIsLoading, setCenterStockItemUpdateIsLoading] = useState(false)
  const [centerstockitemSearchResult, setCenterStockItemSearchResult] = useState<ResponseResult | undefined>(undefined)
  const [centerstockitemUpdateResult, setCenterStockItemUpdateResult] = useState<ResponseUpdate | undefined>(undefined)
  const { setCurrentStatuses } = useCurrentStatusStore()
  const [result, setResult] = useState<ResponseResult>()
  const [isUpdating, setIsUpdating] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const fetchCenterStockItemSearch = (searchParam: GetMstCenterStockItemCenterStockItemGetRequest) => {
    setCenterStockItemSearchIsLoading(true)
    setCenterStockItemSearchResult(undefined)
    setIsLoading(true)
    setResult(undefined)
    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.getMstCenterStockItemCenterStockItemGet(searchParam)
      })
      .then((response) => {
        setCenterStockItemSearch({
          ...response.data,
          list: _.map(response.data.list, (value) => ({
            ...value,
            id: Math.random().toString(32).substring(2),
          }))
        })
        setCenterStockItemSearchResult(response.result)
        setResult(response.result)
        setCurrentStatuses(response.result.systemInfo?.sysCurrentStatus)
      })
      .catch(({ response }: ErrorResponse) => {
        setCenterStockItemSearchResult({
          status: 'error',
          message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })
      })
      .finally(() => {
        setCenterStockItemSearchIsLoading(false)
        setIsLoading(false)
      })
  }


  const fetchCenterStockItemUpdate = async (searchParam: MasterCenterStockItemUpdateMstCenterStockItemUpdatePostRequest): Promise<ResponseUpdate> => {
    setIsUpdating(true)
    setCenterStockItemSearchResult(undefined)
    setResult(undefined)
    try {
      const token = await auth.currentUser?.getIdToken(true);
      if (!token) throw new Error('Token not found');
      
      const conf = new Configuration({
        basePath: process.env.REACT_APP_API_BASE_PATH,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const api = new DefaultApi(conf);
      const response = await api.masterCenterStockItemUpdateMstCenterStockItemUpdatePost(searchParam);
      setResult(response.result)
      setCenterStockItemUpdateResult(response);

      if (response.result.status !== 'success') {
        const error = new Error(response.result.message || '入力エラーがあります');
        Object.assign(error, {
            ...response
        });
        throw error;
      }
      return response;
    } catch (error) {
      if (error && typeof error === 'object' && 'result' in error) {
          const apiResponse = error as ResponseUpdate; 
          await new Promise<void>(resolve => {
            setCenterStockItemUpdateResult(apiResponse);
            resolve();
          });
          return await Promise.reject(apiResponse);
      }
      const { response } = error as any;
      const errorResponse = {
          errors: [],
          result: {
              status: 'error',
              message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
              systemDate: null,
              pageNo: 0,
              systemInfo: null,
          },
          updateCount: null,
      };
      await new Promise<void>(resolve => {
        setCenterStockItemUpdateResult(errorResponse);
          setResult(errorResponse.result)
          resolve();
      });
      return await Promise.reject(errorResponse);      
      // const { response } = error as any;
      // const errorResult: ResponseUpdate = {
      //     errors: [],
      //     result: {
      //         status: 'error',
      //         message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
      //         systemDate: null,
      //         pageNo: 0,
      //         systemInfo: null,
      //     },
      //     updateCount: null,
      // };
      // setCenterStockItemUpdateResult(errorResult);
      // setResult(errorResult.result)
      // throw error; 
    } finally {
      setCenterStockItemUpdateIsLoading(false);
      setIsUpdating(false);
    }
  };

  return {
  	result,
  	isLoading,
  	isUpdating,
    centerstockitemSearch,
    fetchCenterStockItemSearch,
    centerstockitemSearchResult,
    centerstockitemSearchIsLoading,

    fetchCenterStockItemUpdate,
    centerstockitemUpdateResult,
    centerstockitemUpdateIsLoading,
  }
}
