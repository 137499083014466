/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MstCalendar
 */
export interface MstCalendar {
    /**
     * 
     * @type {number}
     * @memberof MstCalendar
     */
    companyId: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstCalendar
     */
    orgCenterId: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCalendar
     */
    calendarDate: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCalendar
     */
    enableShip: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCalendar
     */
    enableArrival: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCalendar
     */
    enableOrder: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCalendar
     */
    deleteFlg: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCalendar
     */
    insertDatetime: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCalendar
     */
    insertUser: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCalendar
     */
    updateDatetime: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCalendar
     */
    updateUser: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCalendar
     */
    deleteDatetime: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCalendar
     */
    deleteUser: string | null;
}

/**
 * Check if a given object implements the MstCalendar interface.
 */
export function instanceOfMstCalendar(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "orgCenterId" in value;
    isInstance = isInstance && "calendarDate" in value;
    isInstance = isInstance && "enableShip" in value;
    isInstance = isInstance && "enableArrival" in value;
    isInstance = isInstance && "enableOrder" in value;
    isInstance = isInstance && "deleteFlg" in value;
    isInstance = isInstance && "insertDatetime" in value;
    isInstance = isInstance && "insertUser" in value;
    isInstance = isInstance && "updateDatetime" in value;
    isInstance = isInstance && "updateUser" in value;
    isInstance = isInstance && "deleteDatetime" in value;
    isInstance = isInstance && "deleteUser" in value;

    return isInstance;
}

export function MstCalendarFromJSON(json: any): MstCalendar {
    return MstCalendarFromJSONTyped(json, false);
}

export function MstCalendarFromJSONTyped(json: any, ignoreDiscriminator: boolean): MstCalendar {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['company_id'],
        'orgCenterId': json['org_center_id'],
        'calendarDate': (json['calendar_date'] === null ? null : new Date(json['calendar_date'])),
        'enableShip': json['enable_ship'],
        'enableArrival': json['enable_arrival'],
        'enableOrder': json['enable_order'],
        'deleteFlg': json['delete_flg'],
        'insertDatetime': (json['insert_datetime'] === null ? null : new Date(json['insert_datetime'])),
        'insertUser': json['insert_user'],
        'updateDatetime': (json['update_datetime'] === null ? null : new Date(json['update_datetime'])),
        'updateUser': json['update_user'],
        'deleteDatetime': (json['delete_datetime'] === null ? null : new Date(json['delete_datetime'])),
        'deleteUser': json['delete_user'],
    };
}

export function MstCalendarToJSON(value?: MstCalendar | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'org_center_id': value.orgCenterId,
        'calendar_date': (value.calendarDate === null ? null : value.calendarDate.toISOString().substring(0,10)),
        'enable_ship': value.enableShip,
        'enable_arrival': value.enableArrival,
        'enable_order': value.enableOrder,
        'delete_flg': value.deleteFlg,
        'insert_datetime': (value.insertDatetime === null ? null : value.insertDatetime.toISOString()),
        'insert_user': value.insertUser,
        'update_datetime': (value.updateDatetime === null ? null : value.updateDatetime.toISOString()),
        'update_user': value.updateUser,
        'delete_datetime': (value.deleteDatetime === null ? null : value.deleteDatetime.toISOString()),
        'delete_user': value.deleteUser,
    };
}

