import React, { useCallback, useEffect, useMemo, useRef, useState, type Dispatch, type SetStateAction } from 'react';

import { useAuthContext } from 'AuthProvider';
import { type CellValueChangedEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react';
import AppSnackbar from 'components/commonparts/AppSnackbar/AppSnackbar';
import GridTooltip from 'components/commonparts/GridTooltip/GridTooltip';
import {LoadingOverlay} from 'components/commonparts/index';
import { useCenter } from 'hooks/useCenter';
import { AG_GRID_LOCALE } from 'utils/agGridLocale';
import * as agGridUtil from 'utils/agGridUtil';
import { validateRequired } from 'utils/validation';

import type {
    ColDef,
    ColGroupDef,
    ColumnMenuTab,
    GetContextMenuItemsParams,
    IMenuActionParams,
    MenuItemDef,
    SizeColumnsToContentStrategy,
    SizeColumnsToFitGridStrategy,
    SizeColumnsToFitProvidedWidthStrategy,
  } from 'ag-grid-enterprise'
  import 'ag-grid-enterprise'
  import 'ag-grid-community/styles/ag-grid.css'
  import 'ag-grid-community/styles/ag-theme-quartz.css'
import type { Center, MstCenter, GetMstCentersCenterGetRequest, ResponseResult } from 'api-client';
import 'utils/mst.scss'

const columnMapping: Record<string, string> = {
    "org_center_id": "orgCenterId",
    "center_name": "centerName",
    "center_address": "centerAddress",
    "ship_previous_stock_flg": "shipPreviousStockFlg",
    "today_ship_rec_delay_flg": "todayShipRecDelayFlg",
    "enable_shipday_mon" : "enableShipdayMon",
    "enable_shipday_tue" : "enableShipdayTue",
    "enable_shipday_wed" : "enableShipdayWed",
    "enable_shipday_thu" : "enableShipdayThu",
    "enable_shipday_fri" : "enableShipdayFri",
    "enable_shipday_sat" : "enableShipdaySat",
    "enable_shipday_sun" : "enableShipdaySun",
    "enable_arrival_mon" : "enableArrivalMon",
    "enable_arrival_tue" : "enableArrivalTue",
    "enable_arrival_wed" : "enableArrivalWed",
    "enable_arrival_thu" : "enableArrivalThu",
    "enable_arrival_fri" : "enableArrivalFri",
    "enable_arrival_sat" : "enableArrivalSat",
    "enable_arrival_sun" : "enableArrivalSun",
    "enable_orderday_mon" : "enableOrderdayMon",
    "enable_orderday_tue" : "enableOrderdayTue",
    "enable_orderday_wed" : "enableOrderdayWed",
    "enable_orderday_thu" : "enableOrderdayThu",
    "enable_orderday_fri" : "enableOrderdayFri",
    "enable_orderday_sat" : "enableOrderdaySat",
    "enable_orderday_sun" : "enableOrderdaySun",
    "center_group_name" : "centerGroupName",
    "center_gln" : "centerGln",
    "delete_flg" : "deleteFlg"
};

interface DataListProps {
  list: Center;
  isLoading: boolean;
  onReload: () => void;
  onUploadData: any[];
  onReset: boolean;
  searchParam: GetMstCentersCenterGetRequest
  onSearch: (requestParam: GetMstCentersCenterGetRequest) => void;
  setOnReset: (value: boolean) => void;
  onTriggerAction: boolean;
  setOnTriggerAction : (value: boolean) => void,
  setHasGridEdited: (value: boolean) => void,
  setResultMessage: Dispatch<SetStateAction<ResponseResult | undefined>>
}

interface MstCenterWithId extends MstCenter ,agGridUtil.RecordEditEx {}

function DataList({ 
  list, 
  isLoading, 
  onReset, 
  searchParam, 
  onSearch, 
  setOnReset,
  onReload, 
  onTriggerAction, 
  setOnTriggerAction, 
  onUploadData,
  setHasGridEdited,
  setResultMessage
}: DataListProps) {
  const { loginUserInfo } = useAuthContext()
  const {fetchCenterUpdate, isUpdating,  result} = useCenter()
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarSeverity, setSnackBarSeverity] = useState<'error' | 'warning' | 'info' | 'success'>('success')
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [editedRows, setEditedRows] = useState<MstCenterWithId[]>([]);
  const gridRef = useRef<AgGridReact<MstCenterWithId>>(null)
  const updatedRowsRef = useRef<any[]>([]);
  const localeText = useMemo<{
    [key: string]: string
  }>(() => AG_GRID_LOCALE, [])

  const autoSizeStrategy = useMemo<
  | SizeColumnsToFitGridStrategy
  | SizeColumnsToFitProvidedWidthStrategy
  | SizeColumnsToContentStrategy
    >(() => ({ type: "fitCellContents" }), []);
  const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 300px)' }), [])
  const gridStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 250px)' }), [])
  const [row, setRow] = useState<MstCenterWithId[]>(list.list.map(item => ({ ...item, ...agGridUtil.initRecordEditEx()})))  
  const pendingChanges = useRef<{ node: any; updatedRow: MstCenterWithId }[]>([]);
  const isPasting = useRef(false)

  useEffect(() => {
    setResultMessage(result)
  }, [result])

  useEffect(() => {
    if (onReset) {
      setEditedRows([]); 
      setOnReset(false);
    }
  }, [onReset, setOnReset]);
  const onPasteStart = useCallback(() => {
    isPasting.current = true;
    pendingChanges.current = []; 
  }, []);
  const colDataValidate = (pRow : MstCenterWithId, field: string | undefined, newValue:any):boolean => {
    if (!field) return false;
    let hasErrors = false
    const trRow = pRow
    const checkAndSetError = (f: string, error: string | null) :boolean => {
      if (error) {
        trRow.errors[f] = [error];
        trRow.editedFields.add(f);
        hasErrors = true;
      } else if (trRow.errors && trRow.errors[f]) {
        delete trRow.errors[f]
      }
      return hasErrors
    };

    checkAndSetError(field, "");
    switch (field){
      case "orgCenterId":{
        const err = checkAndSetError(field, validateRequired(newValue));
        if (!err){
          const gridApi = gridRef.current?.api;
          gridApi?.forEachNode((rowNode) => {
            if (rowNode?.data && rowNode.data?.orgCenterId === newValue && rowNode.data?.id !== trRow.id) {
              checkAndSetError(field, '入力値はすでに登録されています')
            }
          })
        }
        break;
      }
      case "centerName":{
        checkAndSetError(field, validateRequired(newValue));
        break;
      }
      default:
        return false;
    }
    return hasErrors

  };
  
  // 行に含まれる項目に入力チェックを実施
  const rowDataValidate = (pRow:MstCenterWithId):boolean => {
    if (!pRow) return false;
    const trRow = pRow
    let hasErrors = false
    trRow.errors = {};
    Object.keys(columnMapping).forEach((key) => {
      const fieldValue = trRow[columnMapping[key] as keyof typeof trRow];
      colDataValidate(trRow, columnMapping[key], fieldValue)
    });

    if (trRow.errors && Object.keys(trRow.errors).length > 0) {
      hasErrors = true;
    }
    return hasErrors
  } 
  const onCellValueChanged = useCallback((event: CellValueChangedEvent<MstCenterWithId>) => {
    // エラー表示をリセット
    setResultMessage(undefined)
    const { data, colDef, newValue, oldValue, node } = event;
    const { field } = colDef;

    const updatedRow = data  
    const haserror = colDataValidate(updatedRow, field, newValue)
    
    if (newValue !== oldValue){
      updatedRow.isEdited = true;
      updatedRow.editedFields.add(field as string);
      // console.log("onCellValueChanged change")
    }
    // 値の変更時に、APIのエラーを解消
    switch (field){
      case "orgCenterId":
        colDataValidate(updatedRow, "orgCenterId", updatedRow.orgCenterId);
        break;
      default:
    }
    if (isPasting.current) {
      let pendingRow = pendingChanges.current.find((change) => change.updatedRow.id === data.id);
  
      if (!pendingRow) {
        pendingRow = {
          node,
          updatedRow: { ...updatedRow },
        };
        pendingChanges.current.push(pendingRow);
      } else {
        const trRow = pendingRow.updatedRow as MstCenterWithId
        trRow[field as keyof MstCenter] = newValue;
        trRow.isEdited = true;
        trRow.editedFields.add(field as string);
        colDataValidate(trRow, field, newValue)
      }
  
      node.setData(pendingRow.updatedRow);
    } else {

      setEditedRows((prevEditedRows) => {
        const rowIndex = prevEditedRows.findIndex((rowa) => rowa.id === updatedRow.id);
        if (rowIndex !== -1) {
          const newEditedRows = [...prevEditedRows];
          newEditedRows[rowIndex] = updatedRow;
          return newEditedRows;
        }
        return [...prevEditedRows, updatedRow];
      });
  
      node.setData(updatedRow);
    }

  }, [row]);
  

  const onPasteEnd = useCallback(() => {
    isPasting.current = false; 
  
    setEditedRows((prevEditedRows) => {
      const newEditedRows = [...prevEditedRows];
      pendingChanges.current.forEach(({ node, updatedRow }) => {
        const rowIndex = newEditedRows.findIndex((rowb) => rowb.id === updatedRow.id);
        if (rowIndex !== -1) {
          newEditedRows[rowIndex] = updatedRow;
        } else {
          newEditedRows.push(updatedRow);
        }
        node.setData(updatedRow);
      });
      return newEditedRows;
    });
  
    pendingChanges.current = [];
  }, []);

 const updateMstCenter = useCallback( async () => {
      setResultMessage(undefined)
      const baseData = editedRows as unknown as Array<MstCenterWithId>
      let hasErrors = false;
      
      const updatedRows = baseData.map((item) => {
        // 変更時にエラーチェックが行われているが、登録前に再度チェックを実施する
        const updatedRow = { ...item };
        hasErrors = rowDataValidate(updatedRow) || hasErrors
        const gridApi = gridRef.current?.api;
        const rowNode = gridApi?.getRowNode(item.id ?? '');
        if (rowNode) {
          rowNode.setData(updatedRow);
        }
        gridApi?.refreshCells({ force: true });
        return updatedRow
      });

      if (hasErrors) {
        // console.log("hasErrors", updatedRows)
        setResultMessage({
          status: 'error',
          message: '入力エラーがあります',
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })           
        setOnTriggerAction(false);
        return;
      }
  
      updatedRowsRef.current = updatedRows;
      
      const gridApi = gridRef.current?.api;
      if (gridApi) {
        gridApi.applyTransaction({ update: updatedRows });
        gridApi.redrawRows();
      }

      if (baseData.length === 0 ) {
        setResultMessage({
          status: 'warning',
          message: '更新対象のデータがありません',
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })           
        setOnTriggerAction(false);
        return;
      }
  
      const datalist = baseData.map(({ isEdited, orgCenterId, centerName, editedFields, errors, ...rest }) => ({
        ...rest,
        orgCenterId: orgCenterId ??  '',
        centerName: centerName ??  ''
      }));
      
      try {
        await fetchCenterUpdate({
          centerUpdate: { body: datalist }
        })

        setSnackBarMessage('正常に更新されました');
        setSnackBarSeverity('success'); 
        setEditedRows([]);     
        onReload()
      } catch (err: any) {
        if (err?.errors && err.result?.status === "error") {
          if (gridApi) {
            err.errors.forEach((errrow: any) => {

              const {rowdata}=errrow
              const orgCenterId = (rowdata as { org_center_id: string | null }).org_center_id;

              const editedRowIndex = editedRows.find((rowdatas) => rowdatas.orgCenterId === orgCenterId); 
              // console.log("***error", orgCenterId, editedRowIndex, editedRows)
              if (editedRowIndex) {
                // UpdateAPIのErrorは、社内商品コードをエラー表記して、サーバーから取得したエラーを表示する
                const updatedRow = {
                  ...editedRowIndex,
                  errors: {
                    orgCenterId: [errrow.errorMessage], 
                  },
                };
                            
                setEditedRows((prev) =>
                  prev.map((editedRow) =>
                    editedRow.orgCenterId === orgCenterId ? updatedRow : editedRow
                  )
                );
                const rowNode = gridApi.getRowNode(updatedRow.id || '');
                if (rowNode) {
                  rowNode.setData(updatedRow);
                }
              }
            });
            gridApi.redrawRows();
          }
          setSnackBarMessage("");
          setSnackBarSeverity("error");
          setSnackBarOpen(true);
          return;
        }

      } finally {
        setSnackBarOpen(true);
        setOnTriggerAction(false)
      }
    }, [fetchCenterUpdate, onSearch, editedRows, searchParam]);


  // useEffect(() => {
  //   if (updatedRowsRef.current.length > 0) {
  //     const firstRowErrors = updatedRowsRef.current[0].errors;
  //     if (Object.keys(firstRowErrors).length > 0) {
  //       const [firstField, firstMessages] = Object.entries(firstRowErrors)[0] as [string, string[]];
  //       const errorMessage = `${firstMessages[0]}`;
  //       setFirstRowErrorMessage(errorMessage);
  //     } else {
  //       setFirstRowErrorMessage(null);
  //     }
  //   }
  // }, [updatedRowsRef.current]);

  const getCellClassRules = () => ({
    'error-cell': (params: any) => {
      const rowData = params.data as MstCenterWithId;
      const { field } = params.colDef;
      const hasError = Array.isArray(rowData.errors[field]) && rowData.errors[field]?.length > 0;
      return hasError || false; 
    },
    'current-updated-cell': (params: any) => {
      const rowData = params.data as MstCenterWithId;
      const { field } = params.colDef;
      return rowData.isEdited && (!rowData.errors?.[field] || rowData.errors[field].length === 0) && rowData.editedFields?.has(field);
    },
    'editable-cell': (params: any) => {
      const { editable } = params.colDef;
      let iseditable = false
      if (typeof editable === 'function'){
        iseditable = editable(params)
      } else {
        iseditable = editable
      }
      return iseditable
    }
  });



  useEffect(() => {
    const gridApi = gridRef.current?.api;
    if (!gridApi) return;
  
    if (isLoading || isUpdating ) {
      gridApi.showLoadingOverlay();
    } else {
      gridApi.hideOverlay();
    }
  }, [isLoading, isUpdating, gridRef.current]);
  /**
   * カラム定義
   * @returns
   */
  const updateColumnDefs = (): (ColDef | ColGroupDef )[] => 
  [
    {
      headerName: '基本情報',
      children: [
        { 
          headerName: '自社センターID',
          field: 'orgCenterId',
          editable: (params) => params.data && params.data.insertDatetime === null,
          cellClassRules: {
            ...getCellClassRules(),
            'editable-cell': (params) => params.data && params.data.insertDatetime === null, 
          },
          rowDrag: true,
        }, 
        { 
          headerName: 'プロダクト統一センターID',
          field: 'centerId',
        }, 
        { 
          headerName: 'センター名',
          field: 'centerName',
          width: 350,
          editable: true,
          cellClassRules: getCellClassRules(),

        }, 
        { 
          headerName: 'センター所在地住所',
          field: 'centerAddress',
          editable: true,
          cellClassRules: getCellClassRules(),
        }
      ],
      
    },
    {
      headerName: '発注設定',
      children: [
        { 
          headerName: '前日在庫引当フラグ',
          field: 'shipPreviousStockFlg',
          editable: true,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        }, 
        { 
          headerName: '当日出荷数実績遅延フラグ',
          field: 'todayShipRecDelayFlg',
          editable: true,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        }
      ],
    },
    {
      headerName: '出荷可能フラグ',
      children: [
        { 
          headerName: '月',
          field: 'enableShipdayMon',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        }, 
        { 
          headerName: '火',
          field: 'enableShipdayTue',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: '水',
          field: 'enableShipdayWed',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: '木',
          field: 'enableShipdayThu',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: '金',
          field: 'enableShipdayFri',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: '土',
          field: 'enableShipdaySat',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: '日',
          field: 'enableShipdaySun',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        }
      ],
    },
    {
      headerName: '納品（受入）可能フラグ',
      children: [
        { 
          headerName: '月',
          field: 'enableArrivalMon',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        }, 
        { 
          headerName: '火',
          field: 'enableArrivalTue',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: '水',
          field: 'enableArrivalWed',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: '木',
          field: 'enableArrivalThu',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: '金',
          field: 'enableArrivalFri',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: '土',
          field: 'enableArrivalSat',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: '日',
          field: 'enableArrivalSun',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        }
      ],
    },
    {
      headerName: '発注業務可能フラグ',
      children: [
        { 
          headerName: '月',
          width: 70,
          field: 'enableOrderdayMon',
          editable: true,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        }, 
        { 
          headerName: '火',
          field: 'enableOrderdayTue',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: '水',
          field: 'enableOrderdayWed',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: '木',
          field: 'enableOrderdayThu',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: '金',
          field: 'enableOrderdayFri',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: '土',
          field: 'enableOrderdaySat',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: '日',
          field: 'enableOrderdaySun',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: 'センターグループ名',
          field: 'centerGroupName',
          editable: true,
        },
        { 
          headerName: 'GS1事業者コード',
          field: 'centerGln',
          editable: true,
        },
        {
          headerName: '削除フラグ',
          field: 'deleteFlg', // 削除フラグ
          editable: true,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
      ],
    },
  ];
  const onFilterChanged = useCallback(() => {
    const gridApi = gridRef.current?.api;
  
    if (gridApi) {
      const allColumns = gridApi.getColumns();
      if (allColumns) {
        allColumns.forEach((column) => {
          gridApi.getColumnFilterInstance(column.getColId())
            .then((filterInstance) => {
              if (filterInstance && 'refreshFilterValues' in filterInstance) {
                (filterInstance as any).refreshFilterValues();
              }
            });
        });
      }
      gridApi.redrawRows();
    }
  }, []);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      suppressHeaderMenuButton: false,
      sortable: true,
      filter: 'agMultiColumnFilter',
      filterParams: {
        filters: [
          {
            filter: 'agTextColumnFilter',
          },
          {
            filter: 'agSetColumnFilter',
          },
        ],
      },
      menuTabs: ['filterMenuTab', 'generalMenuTab'] as ColumnMenuTab[],
      mainMenuItems: agGridUtil.customMainMenuItems,
      tooltipValueGetter: agGridUtil.tooltipValue,
      tooltipComponent: GridTooltip,
      cellClassRules: getCellClassRules(),
    }),[])

  const columnDefs = useMemo<(ColDef)[]>(updateColumnDefs, [row])
  
  const createBlankRow = (): MstCenterWithId => ({
    companyId: loginUserInfo?.companyId || null,
    orgCenterId: null,
    centerId: null,
    centerName: null,
    centerAddress: null,
    shipPreviousStockFlg: false,
    todayShipRecDelayFlg: false,
    enableShipdayMon: true,
    enableShipdayTue: true,
    enableShipdayWed: true,
    enableShipdayThu: true,
    enableShipdayFri: true,
    enableShipdaySat: true,
    enableShipdaySun: false,
    enableArrivalMon: true,
    enableArrivalTue: true,
    enableArrivalWed: true,
    enableArrivalThu: true,
    enableArrivalFri: true,
    enableArrivalSat: true,
    enableArrivalSun: false,
    enableOrderdayMon: true,
    enableOrderdayTue: true,
    enableOrderdayWed: true,
    enableOrderdayThu: true,
    enableOrderdayFri: true,
    enableOrderdaySat: false,
    enableOrderdaySun: false,
    centerGroupName: null,
    centerGln: null,
    deleteFlg: false,
    insertDatetime: null,
    updateDatetime: null,
    insertUser: loginUserInfo?.userId || null,
    deleteDatetime: null,
    deleteUser: null,
    updateUser: null,
      ...agGridUtil.initRecordEditEx()
    });

    const addRowAction = (actionParams : IMenuActionParams) => {
      // 右クリックの新規行追加選択時
      // console.log("addRowAction", actionParams)
      const { node } = actionParams;
      if (!node)  return;
      const { data, rowIndex} = node;
  
      const gridApi = gridRef.current?.api;
      if (gridApi && data && rowIndex !== null) {
        const newRow: MstCenterWithId = createBlankRow();
  
        const displayedRowIndex = node.rowIndex;
        if (displayedRowIndex !== null) {
          const addIndex = displayedRowIndex + 1;
          
          setRow(prevRows => {
            const newRows = [...prevRows];
            newRows.splice(addIndex, 0, newRow);
            return newRows;
          });
          setEditedRows(prev => [
            ...prev, 
            {
              ...newRow,
              isEdited: true,
              editedFields: new Set([]),
              errors: {}
            }
          ]);
          gridApi.applyTransaction({
            add: [newRow],
            addIndex
          });
          gridApi.redrawRows();
        }
      }    
    }
    const deleteRowAction = (actionParams : IMenuActionParams) => {
      // 右クリックの行削除選択時
      // console.log("deleteRowAction", actionParams)
      const { node } = actionParams;
      if (!node)  return;
      const { data } = node;    
      setRow(prevRows => prevRows.filter(rowDelete => rowDelete.id !== data.id));
      setEditedRows(prev => prev.filter(rowDelete => rowDelete.id !== data.id));
  
    }  
    const getContextMenuItems = useCallback((params: GetContextMenuItemsParams): (string | MenuItemDef)[] => 
      // 右クリックメニュー
      agGridUtil.getContextMenuItems(params,
        addRowAction,
        deleteRowAction
      )
    , []);
  const handleImportExcel = async (data: any[]) => {

    const mappedData = data.map((excelRow: any) => {
      const mappedRow: Partial<MstCenterWithId> = {};
      let hasError = false;
      Object.keys(columnMapping).forEach((key) => {
        if (key in excelRow) {
          const tableField = columnMapping[key as keyof typeof columnMapping];
          const importvalue = excelRow[key];
          let setvalue = importvalue || null;

          if (['deleteFlg', 'shipPreviousStockFlg', 'todayShipRecDelayFlg', 'enableShipdayMon', 'enableShipdayTue', 
            'enableShipdayWed', 'enableShipdayThu', 'enableShipdayFri', 'enableShipdaySat', 'enableShipdaySun', 
            'enableArrivalMon', 'enableArrivalTue', 'enableArrivalWed', 'enableArrivalThu', 'enableArrivalFri', 
            'enableArrivalSat', 'enableArrivalSun', 'enableOrderdayMon', 'enableOrderdayTue', 'enableOrderdayWed', 
            'enableOrderdayThu', 'enableOrderdayFri', 'enableOrderdaySat', 'enableOrderdaySun'].includes(tableField)) {
              setvalue = agGridUtil.parseBoolean(importvalue)
          } else {
            setvalue = importvalue ? String(importvalue).trim() : null;;
          }
          // パース出来ないときはスキップ（＝NULL）し、警告メッセージを表示
          if (!agGridUtil.isNonInputValue(importvalue) && setvalue === null) hasError = true;
          mappedRow[tableField as keyof MstCenterWithId] = setvalue as unknown as undefined
        }
      });
      return mappedRow as MstCenterWithId;
    });
  
    const rowMap = new Map(row.map((existingRow) => [existingRow.orgCenterId, existingRow]));

    const mappedRowMap = new Map(mappedData.map((importedRow) => [importedRow.orgCenterId, importedRow]));
    const newRows: MstCenterWithId[] = [];
    const editedRowsTemp: MstCenterWithId[] = [];
  
    mappedRowMap.forEach((importedRow, rowId) => {
      const existingRow = rowMap.get(rowId);
  
      if (existingRow) {
        // 既存データ
        let editCount=0
        Object.keys(importedRow).forEach((key) => {
          const importedValue = importedRow[key as keyof MstCenterWithId] ?? null;
          const existingValue = existingRow[key as keyof MstCenterWithId] ?? null;
          if (String(importedValue) !== String(existingValue)) {
            existingRow.editedFields.add(key);
            editCount += 1
          }
        });

        if (editCount > 0) {
          const updatedRow = {
            ...existingRow,
            ...importedRow,
            isEdited: true,
            errors: {},
          };
          // 入力チェック
          rowDataValidate(updatedRow)
          editedRowsTemp.push(updatedRow);
          rowMap.set(rowId, updatedRow);
        }
      } else {
        const editedFields = new Set<string>();

        Object.keys(importedRow).forEach((key) => {
          const value = importedRow[key as keyof MstCenterWithId];
          if (value !== null && value !== undefined && value !== '') {
            editedFields.add(key);
          }
        });
  
        const newRow = {
          ...createBlankRow(),
          ...importedRow,
          isEdited: true, 
          errors: {},
          editedFields,
        };
        // 入力チェック
        rowDataValidate(newRow)
  
        newRows.push(newRow);
        rowMap.set(rowId, newRow);
      }
    });

    const updatedRowData = Array.from(rowMap.values());
    setRow(updatedRowData);
    setEditedRows((prevEditedRows) => {
      const filteredEditedRows = prevEditedRows.filter(
        (prevRow) => !editedRowsTemp.some((editedRow) => editedRow.orgCenterId === prevRow.orgCenterId)
      );
      const newEditedRows = newRows.filter(
        (newRow) => !prevEditedRows.some((prevRow) => prevRow.orgCenterId === newRow.orgCenterId)
      );
      return [...newEditedRows, ...filteredEditedRows, ...editedRowsTemp];
    });
  
    if (gridRef.current?.api) {
      gridRef.current.api.applyTransaction({ add: newRows, update: editedRowsTemp });
      gridRef.current.api.refreshCells();
    }
  };
  useEffect(() => {
    if (editedRows && gridRef.current?.api) {
      gridRef.current.api.applyTransaction({ update: editedRows });
      gridRef.current.api.refreshCells();
    }
  }, [editedRows]);

  useEffect(() => {
    if (onTriggerAction) {
      updateMstCenter();
    }
  }, [onTriggerAction]);

  useEffect(() => {
    if (onUploadData) {
      handleImportExcel(onUploadData);
    }
  }, [onUploadData]);

  useEffect(() => {
    if (editedRows.length > 0) {
      setHasGridEdited(true);
    }
  }, [editedRows, setHasGridEdited]);

  const onSnackbarClose = () => {
    setSnackBarOpen(false)
  }

  useEffect(() => {
    if (list.list.length === 0) {
      const blankRow = createBlankRow();
      setRow([blankRow]);
    } else {
      setRow(list.list.map(item => ({ ...item, ...agGridUtil.initRecordEditEx()})))
    }
  }, [list.list]);

  const originalConsoleWarn = console.error;

  console.error = (...args) => {
    if (typeof args[0] === 'string' && args[0].includes('AG Grid: could not find row id')) {
      return; 
    }
    originalConsoleWarn(...args); 
  };

  return (
    <> 
      <div style={{ ...containerStyle, display: 'flex', flexDirection: 'column', height: '69vh' }}>
        <div style={{ flexGrow: 1, boxSizing: 'border-box' }}>
          <div style={gridStyle} className="ag-theme-quartz">
            <AgGridReact
              key={`${row[0]?.companyId}`}
              rowData={row}
              ref={gridRef}
              getRowId={(params) => params.data.id}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              localeText={localeText}
              enableRangeSelection
              fillHandleDirection="xy"
              animateRows
              tooltipShowDelay={0}
              tooltipHideDelay={3000}
              onCellValueChanged={onCellValueChanged}
              rowSelection="single"
              autoSizeStrategy={autoSizeStrategy}
              rowDragManaged
              rowHeight={36}
              allowContextMenuWithControlKey
              getContextMenuItems={getContextMenuItems}
              loadingOverlayComponent={LoadingOverlay}
              overlayNoRowsTemplate={'<span aria-live="polite" aria-atomic="true";pos>条件に合致する商品が見つかりません</span>'}
              suppressLastEmptyLineOnPaste
              onPasteStart={onPasteStart}
              onPasteEnd={onPasteEnd}
              reactiveCustomComponents
              onFilterChanged={onFilterChanged}
            />
          </div>
        </div>
      </div>

      
      <AppSnackbar
        message={snackBarMessage}
        open={snackBarOpen}
        onClose={onSnackbarClose}
        severity={snackBarSeverity}
      />
    </>
  );
}

export default DataList;
