
import { useCallback, useEffect, useState, type default as React } from 'react';

import { Box, Typography } from '@mui/material';
import { useAuthContext } from 'AuthProvider';
import { MasterSearchFrom } from 'components/commonparts'
import { DataList } from 'components/parts/MasterSupplier';
import { DownloadGrid } from 'components/parts/MasterSupplier/DataList/DownloadGrid';
import { useSupplier } from 'hooks/useSupplier';
// import _ from 'lodash';
import { useSearchParamStore } from 'store/searchParamStore';
import { initDataListStatus, type DataListStatus } from 'types/types';
import { convertExcelTimeToHHMM } from 'utils/convertFromLocalDate';
import { getLocalSearchParam } from 'utils/getLocalSearchParam';
import { sortToRequestParam, sortToSortModel } from 'utils/sortToRequestParam';

import type { GetMstSuppliersSupplierGetRequest, MstSupplier, ResponseResult } from 'api-client';

function MasterSupplier() {
  const { loginUserInfo } = useAuthContext()
  const trCompanyId = loginUserInfo?.companyId;
  const setSearchParamState = useSearchParamStore((state) => state.setSearchParamState)
  const searchParamState = useSearchParamStore((state) => state.searchParamState)

  const localSearchParam = getLocalSearchParam(searchParamState, loginUserInfo?.tenantId, loginUserInfo?.companyId)

  const localOrgSupplierId = localSearchParam?.item.orgSupplierId || ''

  // 自社のcompanyIdのみ検索条件をローカルストレージから復元
  const initSearchParam: GetMstSuppliersSupplierGetRequest = {
    companyId: trCompanyId ?? loginUserInfo!.companyId,
  }

  const [hasGridEdited, setHasGridEdited] = useState<boolean>(false)
  const [downloadGridRows, setDownloadGridRows] = useState<MstSupplier[]>([])
  const [downloadMode, setDownloadMode] = useState<string>('csv')

  const [resultMessage, setResultMessage] = useState<ResponseResult | undefined>(undefined)
  const [gridMessage, setGridMessage] = useState<ResponseResult | undefined>(undefined)

  const { supplierSearchResult, supplierSearch ,fetchSupplierSearch, supplierSearchIsLoading } = useSupplier()
  const [searchParam, setSearchParam] = useState<GetMstSuppliersSupplierGetRequest>(initSearchParam)
  const [listStatus, setListStatus] = useState<DataListStatus>(initDataListStatus)
  const [searchTrigger, setSearchTrigger] = useState<boolean>(false);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const [onReset, setOnReset] = useState<boolean>(false)
  const [isRegister, setRegister] = useState<boolean>(false)
  const [uploadData, setUploadData] = useState<any[]>([])
  const [discardChangeDialogOpen, setDiscardChangeDialogOpen] = useState<boolean>(false)
  
  useEffect(() => {
    setResultMessage(supplierSearchResult)
  }, [supplierSearchResult])
  useEffect(() => {
    setResultMessage(gridMessage)
  }, [gridMessage])

  const doSearch = useCallback(async (requestParam: GetMstSuppliersSupplierGetRequest) => {
    if (hasGridEdited) {
      setDiscardChangeDialogOpen(true)
      return
    }
    setSearchTrigger((prev) => !prev);
    try {
      fetchSupplierSearch({
          ...requestParam,
        });
        // fetchSupplier({
        //   companyId: requestParam.companyId, 
        // });
        if (requestParam.companyId === loginUserInfo!.companyId && loginUserInfo?.tenantId && loginUserInfo?.companyId) {
          setSearchParamState<GetMstSuppliersSupplierGetRequest>(
            loginUserInfo?.tenantId,
            loginUserInfo?.companyId,
            'Supplier',
            {
              ...requestParam,
              sort: sortToRequestParam(listStatus.sortModel),
            }
          );
        }
  
        setSearchParam(requestParam);
        setListStatus({
          ...listStatus,
        });
        setOnReset(true); 
        setHasGridEdited(false)
      } catch (error) {
        console.error("Error fetching data:", error);
      } 
  }, [supplierSearch]);

  const handleRegister = () => {
    if (!isRegister) { 
      setRegister(true);
    }
  }

  const handleUploadData = async (headers: any[], data: any[]) => {
    const mappedData = data.map((row) => {
      const obj: any = {};
      headers.forEach((header, index) => {
        obj[header] = row[index];
        // let value = row[index];

      //   if (
      //     header === 'org_supplier_id' ||
      //     header === 'supplier_company_id' || 
      //     header === 'supplier_name' || 
      //     header === 'supplier_hojin_no' || 
      //     header === 'supplier_address' || 
      //     header === 'supplier_gln'
      //   ) {
      //     value = value ? String(value).trim(): null ;
      //   } 

      //   if (
      //     header === 'min_order_quantity_once' ||
      //     header === 'order_leadtime'
      //   ) {
      //     if (typeof value !== 'number') {
      //       value = null;
      //     } else if (!Number.isInteger(value)) {
      //       value = Math.floor(value);
      //     }
      //     if (value < 0) {
      //       value = null;
      //     }
      //   } 

        // if ( header === 'order_limit_time' ) {
        //   if(typeof value === 'number') {
        //     value = convertExcelTimeToHHMM(value)?.toString();
        //   } else if (typeof value === 'string') {
        //     value = value.trim();
        //     // if (!/^\d{2}:\d{2}$/.test(value)) {
        //     //   value = null;
        //     // }
        //   } 
        //   else {
        //     value = null;
        //   }
        // }

        // obj[header] = value;
      });
      return obj;
    });
    setUploadData(mappedData); 
  };

  const onReload = () => {
    fetchSupplierSearch({
      ...searchParam,
    })
    setHasGridEdited(false)
    setOnReset(true)
  }

  useEffect(() => {

    const firstTimeRender = async () => { 
      if (isFirstRender) {
  
        const itemsPromise = fetchSupplierSearch({
          ...searchParam,
        });
  
        await Promise.all([itemsPromise]);
        setIsFirstRender(false)
  
      }
    }
    firstTimeRender()
  }, [])

  useEffect(() => {
    if (localOrgSupplierId) {
      fetchSupplierSearch({
        ...searchParam,
      })

      setListStatus({
        ...listStatus,
      })
    }
  }, [])

  const generateDownloadData = (): MstSupplier[] => {
    if (!supplierSearch?.list || supplierSearch.list.length === 0) {
      return [{} as MstSupplier]
    }
    const downloadData: MstSupplier[] = [...supplierSearch.list]
    return downloadData
  }

  const handleFileDownload = () => {
    setDownloadGridRows(generateDownloadData())
  }

  return (
    <Box>
      <Typography component="div" variant="largeBold">
        仕入先管理
      </Typography>
      <MasterSearchFrom
          result={resultMessage}
          onDoUpdate={handleRegister}
          onReload={onReload}
          onImportData={handleUploadData}
          onFileDownLoad={handleFileDownload}
          discardChangeDialogOpen={discardChangeDialogOpen}
          setDownloadMode={setDownloadMode}
          hasDataEdited={hasGridEdited}
          importRequiredFields={["org_supplier_id"]}
          headerJp={["仕入先企業ID"]}
          lastUpdateDatetime={null}
      />
      <DownloadGrid downloadData={downloadGridRows} downloadMode={downloadMode} />
      <DataList
          list={supplierSearch}
          onReload={onReload}
          onReset={onReset}
          isLoading={supplierSearchIsLoading}
          searchParam={initSearchParam}
          onSearch={doSearch}
          setOnReset={setOnReset}
          onTriggerAction={isRegister}
          onUploadData={uploadData}
          setOnTriggerAction={setRegister}
          setHasGridEdited={setHasGridEdited}
          setResultMessage={setGridMessage}
        />
      
    </Box>
          
  )
}

export default MasterSupplier;
