import React, { type Dispatch, type SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useAuthContext } from 'AuthProvider';
import { type CellValueChangedEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react';
import AppSnackbar from 'components/commonparts/AppSnackbar/AppSnackbar';
import GridTooltip from 'components/commonparts/GridTooltip/GridTooltip';
import {LoadingOverlay} from 'components/commonparts/index';
import { useSupplier } from 'hooks/useSupplier';
import { AG_GRID_LOCALE } from 'utils/agGridLocale';
import * as agGridUtil from 'utils/agGridUtil';
import { validateRequired } from 'utils/validation';

import { TimeCellRenderer, isValidTime } from './customFieldTime';

import type {
    ColDef,
    ColGroupDef,
    ColumnMenuTab,
    GetContextMenuItemsParams,
    IMenuActionParams,
    MenuItemDef,
    SizeColumnsToContentStrategy,
    SizeColumnsToFitGridStrategy,
    SizeColumnsToFitProvidedWidthStrategy,
  } from 'ag-grid-enterprise'
  import 'ag-grid-enterprise'
  import 'ag-grid-community/styles/ag-grid.css'
  import 'ag-grid-community/styles/ag-theme-quartz.css'
import type { Supplier, MstSupplier, GetMstSuppliersSupplierGetRequest, ResponseResult } from 'api-client';
import 'utils/mst.scss'

const columnMapping: Record<string, string> = {
    "org_supplier_id": "orgSupplierId",
    "supplier_name": "supplierName",
    "supplier_hojin_no" : "supplierHojinNo",
    "supplier_address" : "supplierAddress",
    "supplier_gln" : "supplierGln",
    "min_order_quantity_once" : "minOrderQuantityOnce",
    "order_leadtime" : "orderLeadtime",
    "order_limit_time" : "orderLimitTime",
    "enable_shipday_mon" : "enableShipdayMon",
    "enable_shipday_tue" : "enableShipdayTue",
    "enable_shipday_wed" : "enableShipdayWed",
    "enable_shipday_thu" : "enableShipdayThu",
    "enable_shipday_fri" : "enableShipdayFri",
    "enable_shipday_sat" : "enableShipdaySat",
    "enable_shipday_sun" : "enableShipdaySun",
    "enable_orderday_mon" : "enableOrderdayMon",
    "enable_orderday_tue" : "enableOrderdayTue",
    "enable_orderday_wed" : "enableOrderdayWed",
    "enable_orderday_thu" : "enableOrderdayThu",
    "enable_orderday_fri" : "enableOrderdayFri",
    "enable_orderday_sat" : "enableOrderdaySat",
    "enable_orderday_sun" : "enableOrderdaySun",
    "delete_flg" : "deleteFlg"
};

interface DataListProps {
  list: Supplier;
  isLoading: boolean;
  onReload: () => void;
  onUploadData: any[];
  onReset: boolean;
  searchParam: GetMstSuppliersSupplierGetRequest
  onSearch: (requestParam: GetMstSuppliersSupplierGetRequest) => void;
  setOnReset: (value: boolean) => void;
  onTriggerAction: boolean;
  setOnTriggerAction : (value: boolean) => void,
  setHasGridEdited: (value: boolean) => void,
  setResultMessage: Dispatch<SetStateAction<ResponseResult | undefined>>
}

interface MstSupplierWithId extends MstSupplier, agGridUtil.RecordEditEx {}

function DataList({ 
  list, 
  isLoading, 
  onReset, 
  searchParam, 
  onSearch, 
  setOnReset,
  onReload, 
  onTriggerAction, 
  setOnTriggerAction, 
  onUploadData,
  setHasGridEdited,
  setResultMessage,
}: DataListProps) {
  const { loginUserInfo } = useAuthContext()
  const {result,isUpdating, fetchSupplierUpdate, supplierUpdateResult, supplierSearchResult} = useSupplier()
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarSeverity, setSnackBarSeverity] = useState<'error' | 'warning' | 'info' | 'success'>('success')
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [editedRows, setEditedRows] = useState<MstSupplierWithId[]>([]);
  const gridRef = useRef<AgGridReact<MstSupplierWithId>>(null)
  const updatedRowsRef = useRef<any[]>([]);
  const localeText = useMemo<{
    [key: string]: string
  }>(() => AG_GRID_LOCALE, [])

  const autoSizeStrategy = useMemo<
  | SizeColumnsToFitGridStrategy
  | SizeColumnsToFitProvidedWidthStrategy
  | SizeColumnsToContentStrategy
    >(() => ({ type: "fitCellContents" }), []);
  const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 300px)' }), [])
  const gridStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 250px)' }), [])
  const [row, setRow] = useState<MstSupplierWithId[]>(list.list.map(item => ({ ...item, ...agGridUtil.initRecordEditEx()})))  
  const [editorDataReady, setEditorDataReady] = useState(false);
  const pendingChanges = useRef<{ node: any; updatedRow: MstSupplierWithId }[]>([]);
  const isPasting = useRef(false)
  useEffect(() => {
    setResultMessage(result)
  }, [result])
  useEffect(() => {
    setEditorDataReady(false)
  }, [row]);

  useEffect(() => {
    if (onReset) {
      setEditedRows([]); 
      setOnReset(false);
    }
  }, [onReset, setOnReset]);
  
  const onPasteStart = useCallback(() => {
    isPasting.current = true;
    pendingChanges.current = []; 
  }, []);

  const colDataValidate = (pRow : MstSupplierWithId, field: string | undefined, newValue:any):boolean => {
    if (!field) return false;
    let hasErrors = false
    const trRow = pRow
    const checkAndSetError = (f: string, error: string | null) :boolean => {
      if (error) {
        trRow.errors[f] = [error];
        trRow.editedFields.add(f);
        hasErrors = true;
      } else if (trRow.errors && trRow.errors[f]) {
        delete trRow.errors[f]
      }
      return hasErrors
    };

    checkAndSetError(field, "");
    switch (field){
      case "supplierName":
      case "orgSupplierId":
        checkAndSetError(field, validateRequired(newValue));
        break;
      default:
        return false;
    }
    return hasErrors

  };

    // 行に含まれる項目に入力チェックを実施
  const rowDataValidate = (pRow:MstSupplierWithId):boolean => {
    if (!pRow) return false;
    const trRow = pRow
    let hasErrors = false
    trRow.errors = {};
    Object.keys(columnMapping).forEach((key) => {
      const fieldValue = trRow[columnMapping[key] as keyof typeof trRow];
      colDataValidate(trRow, columnMapping[key], fieldValue)
    });

    if (trRow.errors && Object.keys(trRow.errors).length > 0) {
      hasErrors = true;
    }
    return hasErrors
  }  
  
  const onCellValueChanged = useCallback((event: CellValueChangedEvent<MstSupplierWithId>) => {
    setResultMessage(undefined)
    const { data, colDef, newValue, oldValue, node } = event;
    const { field } = colDef;

    const updatedRow = data  
    const haserror = colDataValidate(updatedRow, field, newValue)
    
    if (newValue !== oldValue){
      updatedRow.isEdited = true;
      updatedRow.editedFields.add(field as string);
      // console.log("onCellValueChanged change")
    }
    // 値の変更時に、APIのエラーを解消
    switch (field){
      case "orgSupplierId":
      case "supplierName":
        colDataValidate(updatedRow, "orgSupplierId", updatedRow.orgSupplierId);
        break;
      default:
    }
    if (isPasting.current) {
      let pendingRow = pendingChanges.current.find((change) => change.updatedRow.id === data.id);
  
      if (!pendingRow) {
        pendingRow = {
          node,
          updatedRow: { ...updatedRow },
        };
        pendingChanges.current.push(pendingRow);
      } else {
        const trRow = pendingRow.updatedRow as MstSupplierWithId
        trRow[field as keyof MstSupplier] = newValue;
        trRow.isEdited = true;
        trRow.editedFields.add(field as string);
        colDataValidate(trRow, field, newValue)
      }
  
      node.setData(pendingRow.updatedRow);
    } else {

      setEditedRows((prevEditedRows) => {
        const rowIndex = prevEditedRows.findIndex((rowa) => rowa.id === updatedRow.id);
        if (rowIndex !== -1) {
          const newEditedRows = [...prevEditedRows];
          newEditedRows[rowIndex] = updatedRow;
          return newEditedRows;
        }
        return [...prevEditedRows, updatedRow];
      });
  
      node.setData(updatedRow);
    }

  }, [row]);

  const onPasteEnd = useCallback(() => {
    isPasting.current = false; 
  
    setEditedRows((prevEditedRows) => {
      const newEditedRows = [...prevEditedRows];
      pendingChanges.current.forEach(({ node, updatedRow }) => {
        const rowIndex = newEditedRows.findIndex((rowb) => rowb.id === updatedRow.id);
        if (rowIndex !== -1) {
          newEditedRows[rowIndex] = updatedRow;
        } else {
          newEditedRows.push(updatedRow);
        }
        node.setData(updatedRow);
      });
      return newEditedRows;
    });
  
    pendingChanges.current = [];
  }, []);

  const updateMstCenter = useCallback( async () => {
      setResultMessage(undefined)
      const baseData = editedRows as unknown as Array<MstSupplierWithId>
      let hasErrors = false;
      
      const updatedRows = baseData.map((item) => {
        // 変更時にエラーチェックが行われているが、登録前に再度チェックを実施する
        const updatedRow = { ...item };
        hasErrors = rowDataValidate(updatedRow) || hasErrors
        const gridApi = gridRef.current?.api;
        const rowNode = gridApi?.getRowNode(item.id ?? '');
        if (rowNode) {
          rowNode.setData(updatedRow);
        }
        gridApi?.refreshCells({ force: true });
        return updatedRow
      });

      if (hasErrors) {
        setResultMessage({
          status: 'error',
          message: '入力エラーがあります',
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })           
        setOnTriggerAction(false);
        return;
      }
  
      updatedRowsRef.current = updatedRows;
      
      const gridApi = gridRef.current?.api;
      if (gridApi) {
        gridApi.applyTransaction({ update: updatedRows });
        gridApi.redrawRows();
      }

      if (baseData.length === 0 ) {
        setResultMessage({
          status: 'warning',
          message: '更新対象のデータがありません',
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })           
        setOnTriggerAction(false);
        return;
      }
  
      const datalist = baseData.map(({ isEdited, orgSupplierId, supplierName, editedFields, errors, ...rest }) => ({
        ...rest,
        orgSupplierId: orgSupplierId ??  '',
        supplierName: supplierName ?? ''
      }));
      
      try {
        await fetchSupplierUpdate({
          mstSupplierUpate: { body: datalist }
        })
        setSnackBarMessage('正常に更新されました');
        setSnackBarSeverity('success'); 
        setEditedRows([]);     
        onReload()
      } catch (err: any) {
        if (err?.errors && err.result?.status === "error") {
          if (gridApi) {
            err.errors.forEach((errrow: any) => {

              const {rowdata}=errrow
              const orgSupplierId = (rowdata as { org_supplier_id : string | null }).org_supplier_id;
              const editedRowIndex = editedRows.find((rowdatas) => rowdatas.orgSupplierId === orgSupplierId); 
              if (editedRowIndex) {
                // UpdateAPIのErrorは、PKをエラー表記して、サーバーから取得したエラーを表示する
                const updatedRow = {
                  ...editedRowIndex,
                  errors: {
                    orgSupplierId: [errrow.errorMessage], 
                  },
                };
                            
                setEditedRows((prev) =>
                  prev.map((editedRow) =>
                    editedRow.orgSupplierId === orgSupplierId ? updatedRow : editedRow
                  )
                );
                const rowNode = gridApi.getRowNode(updatedRow.id || '');
                if (rowNode) {
                  rowNode.setData(updatedRow);
                }
              }
            });
            gridApi.redrawRows();
          }
          setSnackBarMessage("");
          setSnackBarSeverity("error");
          setSnackBarOpen(true);
          return;
        }

      } finally {
        setSnackBarOpen(true);
        setOnTriggerAction(false)
      }
    }, [fetchSupplierUpdate, onSearch, editedRows, searchParam]);

  const getCellClassRules = () => ({
    'error-cell': (params: any) => {
      const rowData = params.data as MstSupplierWithId;
      const { field } = params.colDef;
      const hasError = Array.isArray(rowData.errors[field]) && rowData.errors[field]?.length > 0;
      return hasError || false; 
    },
    'current-updated-cell': (params: any) => {
      const rowData = params.data as MstSupplierWithId;
      const { field } = params.colDef;
      return rowData.isEdited && (!rowData.errors?.[field] || rowData.errors[field].length === 0) && rowData.editedFields?.has(field);
    },
    'editable-cell': (params: any) => {
      const { editable } = params.colDef;
      let iseditable = false
      if (typeof editable === 'function'){
        iseditable = editable(params)
      } else {
        iseditable = editable
      }
      return iseditable
    }
  });

  useEffect(() => {
    const gridApi = gridRef.current?.api;
    if (!gridApi) return;
  
    if (isLoading || isUpdating ) {
      gridApi.showLoadingOverlay();
    } else {
      gridApi.hideOverlay();
    }
  }, [isLoading, isUpdating, gridRef.current]);

  /**
   * カラム定義
   * @returns
   */
  const updateColumnDefs = (): (ColDef | ColGroupDef )[] => 
  [
    {
      headerName: '基本情報',
      children: [
        { 
          headerName: '仕入先企業ID',
          field: 'orgSupplierId',
          editable: (params) => params.data && params.data.insertDatetime === null,
          cellClassRules: {
            ...getCellClassRules(),
            'editable-cell': (params) => params.data && params.data.insertDatetime === null, 
          },
          rowDrag: true,
        },
        { 
          headerName: '仕入先プロダクト統一企業ID',
          field: 'supplierCompanyId',
        }, 
        { 
          headerName: '仕入先企業名',
          field: 'supplierName',
          editable: true,
          cellClassRules: getCellClassRules(),

        }, 
        { 
          headerName: '会社法人等番号（登記所）',
          field: 'supplierHojinNo',
          editable: true,
          cellClassRules: getCellClassRules(),
        },
        
        { 
          headerName: '本社住所',
          field: 'supplierAddress',
          editable: true,
          cellClassRules: getCellClassRules(),

        }, 
        { 
          headerName: 'GS1事業者コード',
          field: 'supplierGln',
          editable: true,
          cellClassRules: getCellClassRules(),
        }
      ],
      
    },
    {
      headerName: '発注条件',
        children: [
          { 
            headerName: '1回あたり最低発注数',
            field: 'minOrderQuantityOnce',
            editable: true,
            cellClass:'number-cell',
            cellEditor:'agNumberCellEditor',
            cellEditorParams: {
              step: 1,
              min: 0, 
            },
            valueParser: agGridUtil.numberValueParser,
            valueFormatter: agGridUtil.numberValueFormatter,
          },
          { 
            headerName: '発注リードタイム',
            field: 'orderLeadtime',
            editable: true,
            cellClass:'number-cell',
            cellEditor:'agNumberCellEditor',
            cellEditorParams: {
              step: 1,
              min: 0, 
            },
            valueParser: agGridUtil.numberValueParser,
            valueFormatter: agGridUtil.numberValueFormatter,
          },
          { 
            headerName: '締め時間',
            field: 'orderLimitTime',
            editable: true,
            cellClass:'number-cell',
            cellEditor:'agTextCellEditor',
            cellRenderer: TimeCellRenderer,
            valueGetter: (params) => {
              const { orderLimitTime } = params.data;
              return orderLimitTime || null;  
            },
            valueSetter: (params) => {
              const { newValue } = params;
              const updatedData = { ...params.data };
          
              if (typeof newValue === 'string' && /^\d{2}:\d{2}$/.test(newValue)) {
                updatedData.orderLimitTime = newValue; 
              }
              else if (typeof newValue === 'string' && /^\d{4}$/.test(newValue)) {
                const hours = newValue.substring(0, 2);
                const minutes = newValue.substring(2, 4);
          
                if (isValidTime(hours, minutes)) {
                  updatedData.orderLimitTime = `${hours}:${minutes}`;  
                } else {
                  updatedData.orderLimitTime = null;
                }
              }
              else if (newValue === "" || newValue === null) {
                updatedData.orderLimitTime = null;
              }
              else {
                
                updatedData.orderLimitTime = null;
              }
          
              if (params.node) {
                params.node.setData(updatedData);
              }
          
              return true; 
            },
            valueParser: (params) => {
              const { newValue } = params;
              if (typeof newValue === 'string') {
                return newValue.trim(); 
              }
              return newValue;  
            }
          }
        ]
    },
    {
      headerName: '出荷可能フラグ',
      children: [
        { 
          headerName: '月',
          field: 'enableShipdayMon',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        }, 
        { 
          headerName: '火',
          field: 'enableShipdayTue',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: '水',
          field: 'enableShipdayWed',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: '木',
          field: 'enableShipdayThu',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: '金',
          field: 'enableShipdayFri',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: '土',
          field: 'enableShipdaySat',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: '日',
          field: 'enableShipdaySun',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        }
      ],
    },
    {
      headerName: '受注受付可能フラグ',
      children: [
        { 
          headerName: '月',
          field: 'enableOrderdayMon',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        }, 
        { 
          headerName: '火',
          field: 'enableOrderdayTue',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',

          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: '水',
          field: 'enableOrderdayWed',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: '木',
          field: 'enableOrderdayThu',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: '金',
          field: 'enableOrderdayFri',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: '土',
          field: 'enableOrderdaySat',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
        { 
          headerName: '日',
          field: 'enableOrderdaySun',
          editable: true,
          width: 70,
          cellRenderer: 'agCheckboxCellRenderer',
          cellEditor: 'agCheckboxCellEditor',
          valueFormatter: agGridUtil.formatBooleanValue,
          valueParser: agGridUtil.parseBooleanValue, 
        },
      ],
    },
    {
      headerName: '削除フラグ',
      field: 'deleteFlg', // 削除フラグ
      editable: true,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
      valueFormatter: agGridUtil.formatBooleanValue,
      valueParser: agGridUtil.parseBooleanValue, 
    },
  ];
  const onFilterChanged = useCallback(() => {
    const gridApi = gridRef.current?.api;
  
    if (gridApi) {
      const allColumns = gridApi.getColumns();
      if (allColumns) {
        allColumns.forEach((column) => {
          gridApi.getColumnFilterInstance(column.getColId())
            .then((filterInstance) => {
              if (filterInstance && 'refreshFilterValues' in filterInstance) {
                (filterInstance as any).refreshFilterValues();
              }
            });
        });
      }
      gridApi.redrawRows();
    }
  }, []);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      suppressHeaderMenuButton: false,
      sortable: true,
      filter: 'agMultiColumnFilter',
      filterParams: {
        filters: [
          {
            filter: 'agTextColumnFilter',
          },
          {
            filter: 'agSetColumnFilter',
          },
        ],
      },
      menuTabs: ['filterMenuTab', 'generalMenuTab'] as ColumnMenuTab[],
      mainMenuItems: agGridUtil.customMainMenuItems,
      tooltipValueGetter: agGridUtil.tooltipValue,
      tooltipComponent: GridTooltip,
      cellClassRules: getCellClassRules(),
    }),[])

  const columnDefs = useMemo<(ColDef)[]>(updateColumnDefs, [row, editorDataReady])

  const createBlankRow = (): MstSupplierWithId => ({
    companyId: loginUserInfo?.companyId || null,
    orgSupplierId: null,
    supplierCompanyId: null,
    supplierName: null,
    supplierHojinNo: null,
    supplierAddress: null,
    supplierGln: null,
    minOrderQuantityOnce: null,
    orderLeadtime: null,
    orderLimitTime: null,
    enableShipdayMon: true,
    enableShipdayTue: true,
    enableShipdayWed: true,
    enableShipdayThu: true,
    enableShipdayFri: true,
    enableShipdaySat: false,
    enableShipdaySun: false,
    enableOrderdayMon: true,
    enableOrderdayTue: true,
    enableOrderdayWed: true,
    enableOrderdayThu: true,
    enableOrderdayFri: true,
    enableOrderdaySat: false,
    enableOrderdaySun: false,
    deleteFlg: false,
    insertDatetime: null,
    updateDatetime: null,
    insertUser: loginUserInfo?.userId || null,
    deleteDatetime: null,
    deleteUser: null,
    updateUser: null,
    ...agGridUtil.initRecordEditEx()
  });

  const addRowAction = (actionParams : IMenuActionParams) => {
    // 右クリックの新規行追加選択時
    // console.log("addRowAction", actionParams)
    const { node } = actionParams;
    if (!node)  return;
    const { data, rowIndex} = node;

    const gridApi = gridRef.current?.api;
    if (gridApi && data && rowIndex !== null) {
      const newRow: MstSupplierWithId = createBlankRow();

      const displayedRowIndex = node.rowIndex;
      if (displayedRowIndex !== null) {
        const addIndex = displayedRowIndex + 1;
        
        setRow(prevRows => {
          const newRows = [...prevRows];
          newRows.splice(addIndex, 0, newRow);
          return newRows;
        });
        setEditedRows(prev => [
          ...prev, 
          {
            ...newRow,
            isEdited: true,
            editedFields: new Set([]),
            errors: {}
          }
        ]);
        gridApi.applyTransaction({
          add: [newRow],
          addIndex
        });
        gridApi.redrawRows();
      }
    }    
  }
  const deleteRowAction = (actionParams : IMenuActionParams) => {
    // 右クリックの行削除選択時
    // console.log("deleteRowAction", actionParams)
    const { node } = actionParams;
    if (!node)  return;
    const { data } = node;    
    setRow(prevRows => prevRows.filter(rowDelete => rowDelete.id !== data.id));
    setEditedRows(prev => prev.filter(rowDelete => rowDelete.id !== data.id));

  }  
  const getContextMenuItems = useCallback((params: GetContextMenuItemsParams): (string | MenuItemDef)[] => 
    // 右クリックメニュー
    agGridUtil.getContextMenuItems(params,
      addRowAction,
      deleteRowAction
    )
  , []);
  const handleImportExcel = async (data: any[]) => {
    // console.log("handleImportExcel",  data)
    let hasError = false;
    const mappedData = data.map((excelRow: any) => {
      const mappedRow: Partial<MstSupplierWithId> = {
      };
      Object.keys(columnMapping).forEach((key) => {
        if (key in excelRow) {
          const tableField = columnMapping[key as keyof typeof columnMapping];
          const importvalue = excelRow[key];
          let setvalue = importvalue || null;
          if (['deleteFlg', 'enableShipdayMon', 'enableShipdayTue', 'enableShipdayWed', 'enableShipdayThu', 
            'enableShipdayFri', 'enableShipdaySat', 'enableShipdaySun', 'enableOrderdayMon', 'enableOrderdayTue', 
            'enableOrderdayWed', 'enableOrderdayThu', 'enableOrderdayFri', 'enableOrderdaySat', 'enableOrderdaySun'].includes(tableField)) {
            setvalue = agGridUtil.parseBoolean(importvalue)

          } else if (['minOrderQuantityOnce', 'orderLeadtime'].includes(tableField)) {
            setvalue = agGridUtil.parsePositiveInteger(importvalue);
          
          } else if (['orderLimitTime'].includes(tableField)) {
            setvalue = agGridUtil.parseTime(importvalue);
          } else {
            setvalue = importvalue ? String(importvalue).trim() : null;;
          }
          // パース出来ないときはスキップ（＝NULL）し、警告メッセージを表示
          if (!agGridUtil.isNonInputValue(importvalue) && setvalue === null) hasError = true;
          mappedRow[tableField as keyof MstSupplierWithId] = setvalue as unknown as undefined
        }
      });
      return mappedRow as MstSupplierWithId;
    });
  
    const rowMap = new Map(row.map((existingRow) => [existingRow.orgSupplierId, existingRow]));

    const mappedRowMap = new Map(mappedData.map((importedRow) => [importedRow.orgSupplierId, importedRow]));
    const newRows: MstSupplierWithId[] = [];
    const editedRowsTemp: MstSupplierWithId[] = [];
    if( hasError ) {
      setResultMessage({
        status: 'warning',
        message: '取り込めない値はスキップされました',
        systemDate: null,
        pageNo: 0,
        systemInfo: null,
      })           
    }
  
    mappedRowMap.forEach((importedRow, rowId) => {
      const existingRow = rowMap.get(rowId);
  
      if (existingRow) {
        // 既存データ
        let editCount=0
        Object.keys(importedRow).forEach((key) => {
          const importedValue = importedRow[key as keyof MstSupplierWithId] ?? null;
          const existingValue = existingRow[key as keyof MstSupplierWithId] ?? null;
          if (String(importedValue) !== String(existingValue)) {
            existingRow.editedFields.add(key);
            editCount += 1
          }
        });

        if (editCount > 0) {
          const updatedRow = {
            ...existingRow,
            ...importedRow,
            isEdited: true,
            errors: {},
          };
          // 入力チェック
          rowDataValidate(updatedRow)
          editedRowsTemp.push(updatedRow);
          rowMap.set(rowId, updatedRow);
        }

        // const isRowChanged = Object.keys(importedRow).some((key) => {
        //   const importedValue = importedRow[key as keyof MstSupplierWithId] ?? null;
        //   const existingValue = existingRow[key as keyof MstSupplierWithId] ?? null;
        //   return String(importedValue) !== String(existingValue);
        // });
  
        // if (isRowChanged) {
        //   const editedFields = new Set<string>(
        //     (existingRow as MstSupplierWithId & { editedFields?: Set<string> }).editedFields 
        //       ? Array.from((existingRow as MstSupplierWithId & { editedFields?: Set<string> }).editedFields!)
        //       : []
        //   );
  
        //   Object.keys(importedRow).forEach((key) => {
        //     const importedValue = importedRow[key as keyof MstSupplierWithId] ?? null;
        //     const existingValue = existingRow[key as keyof MstSupplierWithId] ?? null;
        //     if (String(importedValue) !== String(existingValue)) {
        //       editedFields.add(key);
        //     }
        //   });
  
        //   const updatedRow = {
        //     ...existingRow,
        //     ...importedRow,
        //     isEdited: true,
        //     errors: {},
        //     editedFields,
        //   };
        //   rowDataValidate(updatedRow)
        //   if (editedFields.size === 1 && editedFields.has('id')) {
        //     updatedRow.isEdited = false;
        //     updatedRow.editedFields.delete('id');
        //   } else if (editedFields.has('id')) {
        //     updatedRow.editedFields.delete('id');
        //     editedRowsTemp.push(updatedRow);
        //   } else {
        //     editedRowsTemp.push(updatedRow);
        //   }
  
        //   rowMap.set(rowId, updatedRow);
        // }
      } else {
        const editedFields = new Set<string>();

        Object.keys(importedRow).forEach((key) => {
          const value = importedRow[key as keyof MstSupplierWithId];
          if (value !== null && value !== undefined && value !== '') {
            editedFields.add(key);
          }
        });
  
        const newRow = {
          ...createBlankRow(),
          ...importedRow,
          isEdited: true, 
          errors: {},
          editedFields,
        };
        rowDataValidate(newRow)
        newRows.push(newRow);
        rowMap.set(rowId, newRow);
      }
    });
    const updatedRowData = Array.from(rowMap.values());
    setRow(updatedRowData);
    setEditedRows((prevEditedRows) => {
      const filteredEditedRows = prevEditedRows.filter(
        (prevRow) => !editedRowsTemp.some((editedRow) => editedRow.orgSupplierId === prevRow.orgSupplierId)
      );
      const newEditedRows = newRows.filter(
        (newRow) => !prevEditedRows.some((prevRow) => prevRow.orgSupplierId === newRow.orgSupplierId)
      );
      return [...newEditedRows, ...filteredEditedRows, ...editedRowsTemp];
    });
  
    if (gridRef.current?.api) {
      gridRef.current.api.applyTransaction({ add: newRows, update: editedRowsTemp });
      gridRef.current.api.refreshCells();
    }
  };
  useEffect(() => {
    if (editedRows && gridRef.current?.api) {
      gridRef.current.api.applyTransaction({ update: editedRows });
      gridRef.current.api.refreshCells();
    }
  }, [editedRows]);

  useEffect(() => {
    if (onTriggerAction) {
      updateMstCenter();
    }
  }, [onTriggerAction]);

  useEffect(() => {
    if (onUploadData) {
      handleImportExcel(onUploadData);
    }
  }, [onUploadData]);

  useEffect(() => {
    if (editedRows.length > 0) {
      setHasGridEdited(true);
    }
  }, [editedRows, setHasGridEdited]);

  const onSnackbarClose = () => {
    setSnackBarOpen(false)
  }
  useEffect(() => {
    if (list.list.length === 0) {
      const blankRow = createBlankRow();
      setRow([blankRow]);
    } else {
      setRow(list.list.map(item => ({ ...item, ...agGridUtil.initRecordEditEx()})))
    }
  }, [list.list]);

  const originalConsoleWarn = console.error;

  console.error = (...args) => {
    if (typeof args[0] === 'string' && args[0].includes('AG Grid: could not find row id')) {
      return; 
    }
    originalConsoleWarn(...args); 
  };

  return (
    <>
      <div style={{ ...containerStyle, display: 'flex', flexDirection: 'column', height: '69vh' }}>
        <div style={{ flexGrow: 1, boxSizing: 'border-box' }}>
          <div style={gridStyle} className="ag-theme-quartz">
            <AgGridReact
              rowData={row}
              ref={gridRef}
              getRowId={(params) => params.data.id}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              localeText={localeText}
              enableRangeSelection
              fillHandleDirection="xy"
              animateRows
              tooltipShowDelay={0}
              tooltipHideDelay={3000}
              onCellValueChanged={onCellValueChanged}
              rowSelection="single"
              autoSizeStrategy={autoSizeStrategy}
              rowDragManaged
              rowHeight={36}
              allowContextMenuWithControlKey
              getContextMenuItems={getContextMenuItems}
              loadingOverlayComponent={LoadingOverlay}
              overlayNoRowsTemplate={'<span aria-live="polite" aria-atomic="true";pos>条件に合致する商品が見つかりません</span>'}
              suppressLastEmptyLineOnPaste
              onPasteStart={onPasteStart}
              onPasteEnd={onPasteEnd}
              reactiveCustomComponents
              onFilterChanged={onFilterChanged}
            />
          </div>
        </div>
      </div>

      
      <AppSnackbar
        message={snackBarMessage}
        open={snackBarOpen}
        onClose={onSnackbarClose}
        severity={snackBarSeverity}
      />
    </>
  );
}

export default DataList;
