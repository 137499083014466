import React, { useEffect, useMemo, useRef, useState } from 'react'

import { AgGridReact } from 'ag-grid-react'
import { format } from 'date-fns'
import * as agGridUtil from 'utils/agGridUtil'

import type { ColDef, ColGroupDef } from 'ag-grid-community'
import type { MstCalendar } from 'api-client'

interface DownloadGridProps {
  downloadData: MstCalendar[]
  downloadMode: string
}

export function DownloadGrid({ downloadData, downloadMode }: DownloadGridProps) {
  const fileName = "カレンダー登録_"
  const columnDefs = useMemo(
    (): (ColDef | ColGroupDef)[] => [
        { headerName: 'プロダクト統一企業ID', children: [{ headerName: 'company_id', field: 'companyId' }] },
        { headerName: '自社センターID', children: [{ headerName: 'org_center_id', field: 'orgCenterId', cellClass: 'stringType' }] },
        { headerName: '日付', children: [{ headerName: 'calendar_date', field: 'calendarDate' }] },
        { headerName: '出荷可能フラグ', children: [{ headerName: 'enable_ship', field: 'enableShip', cellClass: 'booleanType' }] },
        { headerName: '受入可能フラグ', children: [{ headerName: 'enable_arrival', field: 'enableArrival', cellClass: 'booleanType' }] },
        { headerName: '発注業務可能フラグ', children: [{ headerName: 'enable_order', field: 'enableOrder', cellClass: 'booleanType' }] },
        { headerName: '削除フラグ', children: [{ headerName: 'delete_flg', field: 'deleteFlg', cellClass: 'booleanType' }] },
    ],
    []
  )

  const [rowData, setRowData] = useState<MstCalendar[]>([])
  const gridRef = useRef<AgGridReact>(null)

  const onModelUpdated = () => {
    const gridApi = gridRef.current?.api
    if (gridApi && rowData.length > 0) {
      const dlfileName = `${fileName}_${format(new Date(), 'yyyyMMddHHmmss')}`
      if (downloadMode === 'csv') {
        gridApi.exportDataAsCsv({ fileName:dlfileName })
      } else if (downloadMode === 'xlsx') {
        gridApi.exportDataAsExcel({ fileName:dlfileName, sheetName: 'データ' })
      }
    }
  }

  useEffect(() => {
    setRowData(downloadData)
  }, [downloadData])

  return (
    <div>
      <AgGridReact
        ref={gridRef}
        columnDefs={columnDefs}
        rowData={rowData}
        onModelUpdated={onModelUpdated}
        excelStyles={agGridUtil.excelStyles}
      />
    </div>
  )
}
