import { format } from 'date-fns'
import { excelSerialToDate, convertExcelTimeToHHMM } from 'utils/convertFromLocalDate';

import type { ExcelDataType, 
    ValueFormatterParams, 
    ValueParserParams, 
    // ValueSetterParams,
    GetMainMenuItemsParams,
    // IRichCellEditorParams,
    MenuItemDef,
    ColumnState,
    GetContextMenuItemsParams,
    IMenuActionParams,
    // ValueFormatterFunc
 } from 'ag-grid-community'

//  import {  } from 'ag-grid-community';

// to use myTheme in an application, pass it to the theme grid option
// const myTheme = themeQuartz
// 	.withParams({
//         browserColorScheme: "light",
//         headerFontSize: 14,
//         spacing: 4
//     });

// データ編集時に、APIのインターフェイスに追加する編集管理項目
 export interface RecordEditEx{ 
    id: string | null;
    isEdited: boolean; 
    errors: Record<string, string[]>; 
    editedFields: Set<string> 
}
export const initRecordEditEx = (): RecordEditEx => ({
    id : Math.random().toString(32).substring(2),
    isEdited: false,
    errors: {},
    editedFields: new Set<string>(), 
});
    
export function isNonInputValue(value: any): boolean {
    return value === null || value === undefined || value === '' || value === '-';
}
export function parseNumeric(value: any): number | null {
    // 数字型に変換。変換できないときはNULL
    // return typeof value === 'number' && !Number.isNaN(value);
    // console.debug("**********parseNumeric", value, Number(value))
    if (isNonInputValue(value)) return null;
    const numericValue = Number(value);
    if (Number.isNaN(numericValue)) return null; 
    
    return numericValue
}
export function parsePositiveInteger(value: any): number | null {
    // 正の整数に変換。変換できないときはNULL
    const numericValue = parseNumeric(value);
    if (numericValue === null) return null;
    if (numericValue < 0) return null;
    return Math.floor(numericValue);
}


export function parseDate(value: any): Date | null| undefined {
    if (isNonInputValue(value)) return null;
    const parsedDate = typeof value === 'number'? excelSerialToDate(value) : new Date(value);
    // const parsedDate = normalizeDate(value);
    // value = parsedDate ? parsedDate.toString() : null;
    if (!parsedDate) return null;
    return Number.isNaN(parsedDate.getTime()) ? null : parsedDate;
}
    
export function parseDateValue(params: ValueParserParams): Date | null | undefined {
    return parseDate(params.newValue)
    // const date = new Date(params.newValue);
    // return Number.isNaN(date.getTime()) ? null : date;
}

export const dateFormatter = (params: ValueFormatterParams<Date | null>) => {
    const date = parseDate(params.value)
    return date ? format(date, 'yyyy/MM/dd') : '';
}

export function parseTime(value: any): string | null| undefined {
    if (isNonInputValue(value)) return null;
    let parsedTime = null;
    if(typeof value === 'number') {
        parsedTime = convertExcelTimeToHHMM(value)?.toString();
    } else if (typeof value === 'string') {
        if (!/^\d{2}:\d{2}$/.test(value.trim())) {
            parsedTime = null;
        }
    } 
    else {
        parsedTime = null;
    }
    return parsedTime
}

export function parseStringValue(params: ValueParserParams): string | null {
    const { newValue } = params;
    if (isNonInputValue(newValue)) return null;
    return newValue.toString();
}

export function numberValueFormatter(params: ValueFormatterParams): string {
    // ここで値を変換してしまうと、表示される値と内部の値がずれてしまう。パースはここでは行わない

    // const { value, node, colDef, data } = params;
    // const numericValue = parseNumeric(value); 
    // if (!isNonInputValue(numericValue) && numericValue == null){
    //     data.errors[colDef.field!] = ["数値を入力してください"]
    // } 
    // return numericValue === null ? '-' : Math.floor(numericValue).toString(); 
    // return numericValue === null ? '-' : numericValue.toString(); 
    return isNonInputValue(params.value) ? '-' : params.value.toString();
}

export function numberValueParser(params: ValueParserParams): number | null {
    // 正の整数
    const { newValue, node, colDef } = params;
    const field = colDef.field!;
    const parsedValue = parsePositiveInteger(newValue);
    if (node) node.setDataValue(field, parsedValue);
    return parsedValue;
}

// export function numberValueSetter(params: ValueSetterParams): boolean {
//     const { newValue } = params;
//     return parseNumeric(newValue) === null ? false : true;
// }

export function formatDecimalValue(params: ValueFormatterParams, decimalPlaces = 2): string {
    if (isNonInputValue(params.value)) return '-'

    const numericValue = parseNumeric(params.value); 
    return numericValue === null ? params.value : numericValue.toFixed(decimalPlaces);
}

export function parseDecimalValue(params: ValueParserParams, decimalPlaces = 2): number | null {
    const { newValue, node, colDef } = params;
    const field = colDef.field!;
    
    const numericValue = parseNumeric(newValue);
    if (numericValue === null) {
        if (node) node.setDataValue(field, null);
        return null; 
    }
    const parsedValue = parseFloat(numericValue.toFixed(decimalPlaces));
    if (node) node.setDataValue(field, parsedValue);
    return parsedValue;
}

// export function setDecimalValue(params: ValueSetterParams): boolean {
//     const { newValue} = params;
//     return parseNumeric(newValue) != null;
// }

const TRUE_VALUES = ["T", "t", "TRUE", "true", 1, "1", "１", true];

export function formatBooleanValue(params: ValueFormatterParams): string {
    const value = params.value ?? false; 
    return value ? 'True' : 'False'; 
}

export function parseBoolean(value: any): boolean  {
    let parsedValue = false;
    if (typeof value === 'boolean') {
        parsedValue = Boolean(value);
    } else if (isNonInputValue(value)) {
        parsedValue = false;
    } else {
        parsedValue = TRUE_VALUES.includes(String(value).toLowerCase());
    }
    return parsedValue
}
export function parseBooleanValue(params: ValueParserParams): boolean  {
    const { newValue, node, colDef } = params;
    const field = colDef.field!;
    const parsedValue = parseBoolean(newValue)

    if (node) {
        node.setDataValue(field, parsedValue);
    }
    return parsedValue;
}

// export function setBooleanValue(params: ValueSetterParams): boolean {
//     // console.debug("**********setBooleanValue", params)
//     const { newValue } = params;

//     if (typeof newValue === 'boolean') {
//         return true; 
//     }

//     if (typeof newValue === 'string') {
//         const lowerValue = newValue.toLowerCase();
//         if (TRUE_VALUES.includes(lowerValue)) {
//             return true; 
//         }
//     }

//     return false;
// }

export const booleanFormatter = (params: ValueFormatterParams<boolean | null>) => {
    if (params.value === true) {
    return 'true'; 
    }
    if (params.value === false) {
    return 'false';
    }
    return ''; 
};


export const selectValueFormatter= (value:string, datalist: any[], keyField:string, nameField:string) => {
    if (isNonInputValue(value)) return "-"

    // console.debug("**********SelectValueFormatter", value)
    const vp = datalist.find((s) => s[keyField] === value)
    return vp ? `[${vp[keyField]}]${vp[nameField]}` : value;
}
export function selectValueParser(params: ValueParserParams, datalist: any[], keyField:string): any | null {
    const {newValue} = params
    if (isNonInputValue(newValue)) return null

    // console.debug("**********valueParser", newValue)
    let vp = datalist.find((s) => s[keyField] === newValue)
    
    if (!vp && newValue.startsWith('[') && newValue.indexOf(']')>1) {
        const codematch = newValue.match(/\[(.+)\]/)
        const code = codematch ? codematch[1] : ""
        // console.debug("**********valueParser code", code)
        vp = datalist.find((s) => s[keyField] === code)
    }
    return vp? vp[keyField] : null
}

export const typecodeFormatter= (value:string, datalist: any[]) => {
    if (isNonInputValue(value)) return "-"

    // console.debug("**********typecodeFormatter", value, datalist)
    const vp = datalist.find((s) => s.typeCode === value)
    return vp ? `${vp.typeName}` : value;
}
export function typeCodeParser(value:string, datalist:  any[]){
    if (isNonInputValue(value)) return null

    // console.debug("**********selectTypecodeValueParser", value)
    let vp = datalist.find((s) => s.typeCode === value)
    // typecodeの場合は、区分値も受け入れ可能
    if (!vp){
        vp = datalist.find((s) => s.typeName === value)
    }
    if (!vp && value.startsWith('[') && value.indexOf(']')>1) {
        const codematch = value.match(/\[(.+)\]/)
        const code = codematch ? codematch[1] : ""
        // console.debug("**********selectTypecodeValueParser code", code)
        vp = datalist.find((s) => s.typeCode === code)
    }
    return vp? vp.typeCode : null
}
export function selectTypecodeValueParser(params: ValueParserParams, datalist:  any[]): any | null {
    const {newValue} = params
    return typeCodeParser(newValue, datalist)
}


export const excelStyles = [
    {
        id: 'numberType',
        numberFormat: {
        format: '#,##0' 
        }
    },
    {
        id: 'decimalType',
        numberFormat: {
        format: '#,##0.000' 
        }
    },    
    {
        id: 'booleanType',
        dataType: 'Boolean' as ExcelDataType,  
    },
    {
        id: 'stringType',
        dataType: 'String' as ExcelDataType,
    },
    {
        id: 'dateType',
        dataType: 'DateTime' as ExcelDataType,
        numberFormat: {
            format: 'yyy-mm-ddThh:mm:ss'
        }
    },
    // {
    //     id: 'dateTimeType',
    //     dataType: 'DateTime' as ExcelDataType,
    //     numberFormat: {
    //         format: 'yyy-mm-ddThh:mm:ss'
    //     }
    // },    
    // {
    //     id: 'timeType',
    //     dataType: 'DateTime' as ExcelDataType,
    //     numberFormat: {
    //     format: 'hh:mm'
    //     }
    // },
]

// export const onModelUpdated = () => {
//     const gridApi = gridRef.current?.api
//     if (gridApi && rowData.length > 0) {
//         const dlfileName = `${fileName}_${format(new Date(), 'yyyyMMddHHmmss')}`
//         if (downloadMode === 'csv') {
//         gridApi.exportDataAsCsv({ fileName:dlfileName })
//         } else if (downloadMode === 'xlsx') {
//         gridApi.exportDataAsExcel({ fileName:dlfileName, sheetName: 'データ' })
//         }
//     }
// }

    

export const customMainMenuItems = (params: GetMainMenuItemsParams): (MenuItemDef | string)[] => {
    const menuItems: (MenuItemDef | string)[] = params.defaultItems.slice();

    menuItems.splice(1, 0, {
        name: '列非表示',
        subMenu: [
            {
            name: '左側の隠れた列を表示',
            action: () => {
                const allColumns = params.api.getColumns();
                if (!allColumns) return;
                const currentIndex = allColumns.findIndex(col => col.getColId() === params.column.getColId());
                for (let i = currentIndex - 1; i >= 0; i -= 1) {
                if (!allColumns[i].isVisible()) {
                    params.api.setColumnVisible(allColumns[i].getColId(), true);
                    break;
                }
                }
            }
            },
            {
            name: '右側の隠れた列を表示',
            action: () => {
                const allColumns = params.api.getColumns();
                if (!allColumns) return;
                const currentIndex = allColumns.findIndex(col => col.getColId() === params.column.getColId());
                for (let i = currentIndex + 1; i < allColumns.length; i += 1) { 
                if (!allColumns[i].isVisible()) {
                    params.api.setColumnVisible(allColumns[i].getColId(), true);
                    break;
                }
                }
            }
            },
            {
            name: 'この列を非表示',
            action: () => {
                params.api.setColumnVisible(params.column.getColId(), false);
            }
            }
        ]
        } as MenuItemDef);

        menuItems.splice(2, 0, {
        name: '表示設定の保存',
        action: () => {
            const columnState: ColumnState[] = params.api.getColumnState();
            localStorage.setItem('gridColumnState', JSON.stringify(columnState));
        }
        } as MenuItemDef);

    const pinSubMenuIndex = menuItems.findIndex(item => item === 'pinSubMenu');
        if (pinSubMenuIndex >= 0) {
            menuItems[pinSubMenuIndex] = {
            name: '列固定',
            subMenu: [
                { 
                name: 'ピンなし', 
                action: () => params.api.setColumnPinned(params.column.getColId(), null) 
                },
                { 
                name: '左にピン', 
                action: () => params.api.setColumnPinned(params.column.getColId(), 'left') 
                },
                { 
                name: '右にピン留め', 
                action: () => params.api.setColumnPinned(params.column.getColId(), 'right') 
                }
            ]
            } as MenuItemDef;
        }

    const autoSizeIndex = menuItems.findIndex(item => item === 'autoSizeThis');
    if (autoSizeIndex >= 0) {
        menuItems.splice(autoSizeIndex, 1);
    }

    const autoSizeAllIndex = menuItems.findIndex(item => item === 'autoSizeAll');
    if (autoSizeAllIndex >= 0) {
        menuItems.splice(autoSizeAllIndex, 1);
    }

    const resetColumnIndex = menuItems.findIndex(item => item === 'resetColumns');
    if (resetColumnIndex >= 0) {
        menuItems[resetColumnIndex] = {
        name: '列をリセット',
        action: () => {
            params.api.resetColumnState();
            localStorage.removeItem('gridColumnState');
            }
        };
    }
    return menuItems;
};

// todo：右クリックメニューも共通に出したい
export const getContextMenuItems = (params: GetContextMenuItemsParams
    , addRowAction:(actionParams : IMenuActionParams) => void
    , deleteRowAction:(actionParams : IMenuActionParams) => void): (string | MenuItemDef)[] => {
    const { node } = params;
    if (!node) {
        return [
        {
            name: "コピーする", 
            action: () => params.api.copySelectedRangeToClipboard(),
        },
        "separator",
        {
            name: "貼り付ける", 
            action: () => params.api.pasteFromClipboard(),
        },
        ];
    }
    const {data, rowIndex} = node;
    const isNewRow = data?.insertDatetime === null

    const result: (string | MenuItemDef)[] = [
        {
        name: "新規行を追加する",
        action: addRowAction
        },
        {
        name: "行を削除",
        action: deleteRowAction,
        disabled : !isNewRow
        },      
    //   ...(isNewRow
    //     ? [
    //         {
    //           name: "行を削除する",
    //           action: () => deleteRowAction
    //         },
    //       ]
    //     : []),
        "separator",
        {
        name: "コピーする", 
        action: () => params.api.copySelectedRangeToClipboard(),
        },
        {
        name: "行コピーする", 
        action: () => params.api.copySelectedRowsToClipboard(),
        },
        "copyWithHeaders",
        "separator",
        {
        name: "貼り付ける", 
        action: () => params.api.pasteFromClipboard(),
        },
    ];

    return result;
};

export const tooltipValue = (params: any) => {
    const { value, data, colDef } = params;
    const { field } = colDef;

    if (data.errors && Array.isArray(data.errors[field]) && data.errors[field].length > 0) {
        return `${data.errors[field].join(', ')}`;
    }
    return value ? `${value}` : 'No Value';
};
